import React, {Component} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {searchResult} from '../reducers';
import {connect} from 'react-redux';
import {getSearchResult, createTrip, editTrip} from '../action';
import PageSubMenu1 from './PageSubMenu1';
import PageHeaderData from './PageHeaderData';
import moment from 'moment';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {push} from 'react-router-redux';
import {getSearchResultRefined} from '../action';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PageHeader from './PageHeader';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import BoatIcon from '@mui/icons-material/DirectionsBoat';
import Rating from '@mui/material/Rating';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HotelIcon from '@mui/icons-material/Hotel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ToysIcon from '@mui/icons-material/Toys';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import BrushIcon from '@mui/icons-material/Brush';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardMedia from '@mui/material/CardMedia';
import default1 from '../img/default.jpg';
import '../styles/selectbelowmenu.css';
import Grid from '@mui/material/Grid';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import '../styles/selectbelowmenu.css'



function compareActivities(activity1, activity2, destination1, destination2, duration1, duration2){
  if(destination1 && destination2){
    if(destination1 != destination2)
      return false;
  }//end of check for destination
  if(activity1 && activity2){
    if(activity1.length != activity2.length)
      return false;
    for(let i =0; i<activity1.length; i++){
      if (activity1[i] != activity2[i])
        return false;
    }
  }//end of activity compare
  if(duration1 && duration2){
    if(duration1 != duration2){
      return false
    }
  }//end of check for duration
  return true;
}

//convert destination to string to look for img file
function stringToDestination(input){
  var firstPart = input.substr(0, input.indexOf(','));
  console.log(firstPart);
  if(!firstPart){
    input = input.split(' ').join('');
    return input.toLowerCase();
  }
  var secondPart = firstPart.toLowerCase();
  console.log(secondPart);
  var thirdPart = secondPart.split(' ').join('');
  console.log(thirdPart);
  thirdPart = thirdPart.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  thirdPart = thirdPart.split('.').join("");
  return thirdPart;
}

function getIconForActivity(activity){
  var icon = null;
  switch(activity){
    case "Snorkel":
      icon = <PoolIcon/>;
      break;
    case "Flight":
      icon = <FlightIcon/>;
      break;
    case "Museum":
      icon = <MuseumIcon/>;
      break;
    case "Walking":
    case "Hiking":
      icon = <WalkIcon/>;
      break;
    case "Driving":
      icon = <DriveIcon/>;
      break;
    case "Bicycling":
    case "Biking":
    case "Hydrobiking":
      icon = <BicycleIcon/>;
      break;
    case "Restaurant":
      icon = <RestaurantIcon/>;
      break;
    case "Boating":
      icon = <BoatIcon/>;
      break;
    case "Swimming":
        icon = <PoolIcon/>;
        break;
    case "Iceskating":
    case "Skiing":
        icon = <AcUnitIcon/>;
        break;
    case "Hotel":
        icon = <HotelIcon/>;
        break;
    case "Concert":
        icon = <MusicNoteIcon/>;
        break;
    case "Thrillrides":
        icon = <ToysIcon/>;
        break;
    case "Golf":
    case "Minigolf":
        icon = <GolfCourseIcon/>;
        break;
    case "Artgalleries":
        icon = <BrushIcon/>;
        break;
    default:
        icon = <BeachIcon/> ;
  }
  return icon;
}

class CompareTrips extends Component {
  constructor(props){
    super(props);
    this.handleCloneTrip = this.handleCloneTrip.bind(this);
    this.state = {

      searchCriteria: props.searchResult,
      compareTrips1: null,

    };
    console.log("traveler id "+this.state.travelerid);
    console.log(this.state.searchCriteria);


    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/comparetrips',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripid1": this.state.searchCriteria.tripid1,
                                   "tripid2": this.state.searchCriteria.tripid2,
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            if(!data || data.length == 0){
              this.setState({
                noData: true
              })
              console.log("No trips to compare");
              return;
            }

            //create an array of objects
            //reach row is an activity with activity name, present in trip1, present in trip2
            //row split by day
            console.log(data);
            var date1 = null;
            var dateUnique = null;
            var locationBasedArray = [];
            var uniqueLocations = [];
            var dayCounter = 0;
            var dayLabel = null;
            var finalObj = {};
            var date2 = null;
            var trip2date1 = null;
            var trip2DateUnique = null;
            var trip1Id = null;
            var trip2Id = null;
            var concatedArray = [];
            data.forEach(dataItem1 => {
              console.log(dataItem1);
              if(!uniqueLocations.includes(dataItem1.tolocation)){
                if(trip1Id == null || (trip2Id == null && dataItem1.tripsId != trip1Id)){
                  if(trip1Id == null){
                    trip1Id = dataItem1.tripsId;
                    dataItem1.trip1 = true;
                    dataItem1.trip2 = false;
                  }
                  else if (trip2Id == null){
                    trip2Id = dataItem1.tripsId;
                    dataItem1.trip1 = false;
                    dataItem1.trip2 = true;
                  }
                }//new trip
                else{
                  if(dataItem1.tripsId == trip1Id){
                    dataItem1.trip1 = true;
                    dataItem1.trip2 = false;
                  } else if (dataItem1.tripsId == trip2Id){
                    dataItem1.trip1 = false;
                    dataItem1.trip2 = true;
                  }
                }//same trip
                uniqueLocations.push(dataItem1.tolocation);
                locationBasedArray.push(dataItem1);
              } // check for unique locations
              else{
                console.log("Inside non unique location");
                for (var i in locationBasedArray){
                  console.log(locationBasedArray[i].tolocation);
                  console.log(dataItem1.tolocation);
                  if(locationBasedArray[i].tolocation == dataItem1.tolocation){
                    console.log("location matches");
                    console.log(dataItem1.tripsId);
                    console.log(trip2Id);
                    if(dataItem1.tripsId == trip1Id){
                      dataItem1.trip1 = true;
                      locationBasedArray[i].trip1 = true;
                      break;
                    } else if(dataItem1.tripsId == trip2Id){
                      console.log("Inside if condition of setting trip2 as true");
                      dataItem1.trip2 = true;
                      locationBasedArray[i].trip2 = true;
                      break;
                    }//end if trip1 or trip2
                    else if(trip2Id == null && dataItem1.tripsId != trip1Id){
                        trip2Id = dataItem1.tripsId;
                        dataItem1.trip1 = false;
                        dataItem1.trip2 = true;
                        locationBasedArray[i].trip2 = true;
                        break;
                    }

                  }//matching location
                  console.log(locationBasedArray[i]);
                }//for loop
              }//else of unique locations
              })//for each data row
              /*if(dataitem1.endTime){
                dataitem1.endTime = moment(dataitem1.endTime).format("YYYY-MM-DD hh:mm A");
              }
              date1 = dataitem1.endTime.slice(0,10)
              if ((dateUnique == null && trip1Id == null) ||
                  (trip2DateUnique == null && trip2Id == null && dataitem1.tripsId != trip1Id)){
                dateUnique = date1;
                if(trip1Id == null){
                  trip1Id = dataitem1.tripsId;
                  dataitem1.trip1 = true;
                  dataitem1.trip2 = false;
                }
                else if (trip2Id == null){
                  trip2Id = dataitem1.tripsId;
                  dataitem1.trip1 = false;
                  dataitem1.trip2 = true;
                }
                date2 = dataitem1.endTime.slice(11, 19);
                dataitem1.endTime = date2;

                dateBasedArray.push(dataitem1);

                dayCounter = 1;
                dayLabel = "Day "+dayCounter;
              }
              else if(dateUnique == date1){
                date2 = dataitem1.endTime.slice(11, 19);
                dataitem1.endTime = date2;
                if(trip2Id == null){
                  dataitem1.trip1 = true;
                  dataitem1.trip2 = false;
                }
                else{
                  dataitem1.trip1 = false;
                  dataitem1.trip2 = true;
                }

                dateBasedArray.push(dataitem1);

              }//date unique is equal to date
              else if (dateUnique != date1){
                console.log(finalObj[dayLabel]);
                if(finalObj[dayLabel]){
                  concatedArray = dateBasedArray.concat(finalObj[dayLabel])
                }
                else {
                  concatedArray = dateBasedArray
                }
                finalObj[dayLabel] = concatedArray;
                dateBasedArray = [];
                dateUnique = date1;
                date2 = dataitem1.endTime.slice(11, 19);
                dataitem1.endTime = date2;
                if(trip2Id == null){
                  dataitem1.trip1 = true;
                  dataitem1.trip2 = false;
                }
                else{
                  dataitem1.trip1 = false;
                  dataitem1.trip2 = true;
                }

                dateBasedArray.push(dataitem1);

                dayCounter = dayCounter + 1;
                dayLabel = "Day "+dayCounter;
              }//new day
            })
            finalObj[dayLabel] = dateBasedArray;
            console.log(finalObj);*/
            //item.activities = finalObj;
            this.setState({
              compareTrips1: locationBasedArray,
              loading: false
            });
            console.log(this.state.compareTrips1);
            (this.state.compareTrips1)?this.state.compareTrips1.map((activityItem4) => {

                console.log(activityItem4.name);
                console.log(activityItem4.id);
                console.log(activityItem4.trip1);
                console.log(activityItem4.trip2);
              })
            :console.log("inside empty compare trips");//end of compare trips map


      })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    if(nextProps.searchResult.travelerid){
      return {travelerid: nextProps.searchResult.travelerid};
    }
    else {
      return null;
    }
  }

  handleloginsuccess(travelerid){
      this.state.travelerid = travelerid
  }

  handleCloneTrip(activityItem){
    console.log("Handle clone trip "+activityItem.tripId);
    console.log("traveler id "+this.state.travelerid);
    console.log("destination id "+this.state.fromdestinationid);
    if(!this.state.travelerid){
      this.setState({open: true});
      return;
    }
    console.log(activityItem);

    //pass trip Id, fromdestination id, traveler id

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/clonetrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
                                   "tripid": activityItem.tripId,
                                   "fromdestinationid": this.state.fromdestinationid
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/createtrip'));
            dispatch(editTrip(data.tripid, data.tripname, this.state.travelerid, this.state.fromdestinationid,
              data.todestinationid, new Date(data.starttime), new Date(data.endtime), activityItem.destinationName));
          })
          .catch(error => {console.log(error); return error})


  }


  render(){

    return (
           <div>
             <PageHeader topName="Sign In" dispatch={this.props.dispatch}>
             </PageHeader>
             <div className="searchcriteriamaterialgrid3">
               <TableContainer component={Paper}>
                 <Table aria-label="simple table">
                   <colgroup>
                      <col style={{width: '60%'}} />
                      <col style={{width: '20%'}} />
                      <col style={{width: '20%'}} />
                   </colgroup>

                   <TableBody>
                   <TableRow>
                     <TableCell>Activity</TableCell>
                     <TableCell align="right">Trip1</TableCell>
                     <TableCell align="right">Trip2</TableCell>
                   </TableRow>
                     {(this.state.compareTrips1)?this.state.compareTrips1.map((activityItem4) => (



                           <TableRow key={activityItem4.id} >
                             <TableCell >
                               {activityItem4.tripactivitytypename} - {activityItem4.tolocation}
                             </TableCell>
                             <TableCell align="right">{activityItem4.trip1?<CheckCircleIcon/>:<CancelIcon/>}</TableCell>
                             <TableCell align="right">{activityItem4.trip2?<CheckCircleIcon/>:<CancelIcon/>}</TableCell>
                           </TableRow>
                         )


                     ):<TableRow>
                         <TableCell component="th" scope="row">
                           No Activity to compare
                         </TableCell>
                       </TableRow>
                     }
                   </TableBody>
                 </Table>
               </TableContainer>
             </div>
           </div>
         )

  }
}
const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);

  return state;
}





export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(CompareTrips);
