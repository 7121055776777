import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import {
  SEARCH_RESULT,
  SEARCH_CRITERIA,
  SIGN_IN,
  MY_TRIPS,
  PASS_TRAVELER_ID,
  SEARCH_RESULT_REFINED,
  SEARCH_RESULT_TRIP,
  CREATE_MY_TRIP,
  CREATE_TRIP_DETAIL,
  EDIT_MY_TRIP,
  UPLOAD_PHOTOS,
  GET_REVIEWS,
  POPULAR_TRIPS,
  COMPARE_TRIPS,
  GET_PROFILE
} from './action'

const initialState = {
  travelerid: null
}

function searchResult(state = initialState, action){
  if(action.type === SEARCH_RESULT){
    console.log("Inside search result reducer");
    console.log(action.searchCriteria);

    return action.searchCriteria;

    }
  else if(action.type === SIGN_IN){
    console.log("inside sign in button click");
    console.log(action.signin);
    state={travelerid: action.travelerid, displayName: action.displayName}
    return action.signin;
  }
  else if(action.type === PASS_TRAVELER_ID){
    console.log("pass traveler id");
    console.log(action.travelerid);
    state = {travelerid: action.travelerid, displayName: action.displayName};
    return state;
  }
  else if(action.type === SEARCH_RESULT_REFINED){
    console.log("search criteria refined");
    console.log(action.searchCriteria);
    return action.searchCriteria;
  }
  else if(action.type === SEARCH_RESULT_TRIP){
    console.log("search criteria trip");
    console.log(action.searchCriteria);
    console.log(action.destinationto);
    state = {searchCriteria: action.searchCriteria, destinationto: action.destinationto,
      duration: action.duration, tripTypeId: action.tripTypeId};
    return state;
  }
  else if(action.type === EDIT_MY_TRIP){
    console.log("edit my trip");
    console.log(action.tripid);
    console.log(action.tripname);
    console.log(action.travelerid);
    console.log(action.fromdestinationid);
    console.log(action.todestinationid);
    console.log(action.fromdate);
    console.log(action.todate);
    console.log(action.todestinationname);
    state = {tripid: action.tripid, tripname: action.tripname, travelerid: action.travelerid, fromdestinationid: action.fromdestinationid,
      todestinationid: action.todestinationid, fromdate: action.fromdate, todate: action.todate, todestinationname: action.todestinationname };
    return state;
  }
  else if(action.type === CREATE_MY_TRIP){
    console.log("create my trip");
    console.log(action.signin);
    return state;
  }
  else if(action.type === CREATE_TRIP_DETAIL){
    console.log("create trip detail");
    console.log(action.tripid);
    console.log(action.travelerid);
    state={tripid: action.tripid, travelerid:action.travelerid};
    return state;
  }
  else if(action.type === UPLOAD_PHOTOS){
    console.log("upload photos");
    state = {tripid: action.tripid, tripactivityid: action.tripactivityid, travelerid: action.travelerid};
    return state;
  }
  else if(action.type === GET_REVIEWS){
    console.log("Get Reviews");
    state = {tripid: action.tripid, travelerid: action.travelerid,
              tripactivitytypenames: action.tripactivitytypenames,
              destinationTo: action.destinationTo};
    return state;
  }
  else if(action.type === POPULAR_TRIPS){
    console.log("popular trips");
    state = {travelerid: action.travelerid, displayName:action.displayName,
              activitytypenames: action.activitytypenames,
              destinationto: action.destinationto,
              duration: action.duration,
              tripTypeId: action.tripTypeId,
              profileName: action.profileName};
    return state;
  }
  else if (action.type === COMPARE_TRIPS){
    console.log("compare trips");
    state = {tripid1: action.tripid1, tripid2: action.tripid2};
    return state;
  }
  else if (action.type === GET_PROFILE){
    console.log("get profile")
    state = {travelerid: action.travelerid};
    return state;
  }
  else{
    console.log(state)
    return state
  }
}



const tripsApp = (history) => combineReducers({
  router: connectRouter(history),
  searchResult
})

export default tripsApp;
