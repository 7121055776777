import React, {Component} from 'react';
import {connect} from 'react-redux';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import firebaseui from "firebaseui"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import PageHeader from './PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import '../styles/firebaseui-styling.global.css';
import {replace, push} from 'react-router-redux';
import {passTravelerId, getProfile} from '../action';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
//import { getAuth, connectAuthEmulator } from "firebase/auth";


var firebaseConfig = {
  apiKey: "AIzaSyBGysjJjwqtqgn9A-tOIh3M2HSf6MDkzwg",
  authDomain: "trips-64206.firebaseapp.com",
  databaseURL: "https://trips-64206.firebaseio.com",
  projectId: "trips-64206",
  storageBucket: "trips-64206.appspot.com",
  messagingSenderId: "343327137283",
  appId: "1:343327137283:web:de0b857b7b47a650a13836",
  measurementId: "G-DNDLFBVZM3"
}

console.log(firebase.apps);

if (!firebase.apps.length) {
  console.log("Inside initialize app")
  firebase.initializeApp(firebaseConfig)
}

const uiConfig =  {
  signInFlow: 'popup',
  signInSuccessUrl: 'https://www.roamworth.com/',
  signInOptions:[
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes:[
        'email'
      ],
    },
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
    },
    //firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  /*callbacks: {
        // signin success processing.
        signInSuccessWithAuthResult: (authResult) => {
          console.log("inside callback");
          var user = authResult.user;
          console.log(user);
          fetch('https://'+process.env.REACT_APP_HOSTNAME+':8080/api/traveler/checktravelerexists',
                  { method:'POST',
                    headers:{
                     "Content-Type": "application/json"
                   },
                   body:JSON.stringify({"name": user.displayName
                 })
                })
          .then(res => res.json())
          .then(traveler => {
                                console.log(traveler);
                                console.log(traveler[0].id);
                                this.setState({travelerid: traveler[0].id,
                                                displayName1: traveler[0].profilename?
                                                traveler[0].profilename:
                                                traveler[0].name});

                                //this.props.travelerid = travelerid[0].id;
                                const { dispatch } = this.props;
                                dispatch(push('/'));
                                dispatch(passTravelerId(traveler[0].id, traveler[0].profilename));
                              })

          return true;

        }
      }*/
};



class SignIn extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.state = {
      email: '',
      password: '',
      emailError: false,
      emailHelperText: '',
      passwordError: false,
      passwordHelperText: '',
    }
    console.log("constructor of sign in")
    //const auth = getAuth();
      //ui.start('#firebaseui-auth-container', uiConfig);
      //const auth = getAuth();
      //connectAuthEmulator(auth, "http://localhost:9099");
  }

  handleEmailChange(event){
    this.setState({
      email: event.target.value,
      emailError: false,
      emailHelperText: '',
    })
  }

  handlePasswordChange(event){
    this.setState({
      password: event.target.value,
      passwordError: false,
      passwordHelperText: '',
    })
  }

  handleSubmit(event){
    console.log("Inside submit");
    console.log(this.state.email);
    event.preventDefault();
    const data = new FormData(event.target);
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
    .then((user) => {
      console.log(user);
    })
    .catch((error) =>{
      console.log(error);
      if(error.message.localeCompare("The email address is already in use by another account.") == 0){
        console.log("Match if of email already exists");
        this.setState({
          emailError: true,
          emailHelperText: "The email address is already in use by another account."
        })
      }
      else if(error.message.localeCompare("Password should be at least 6 characters") == 0){
        console.log("password Issue");
        this.setState({
          passwordError: true,
          passwordHelperText: "Password should be at least 6 characters"
        })
      }
    })
  }

  render(){



      //var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());


    /*firebase.auth().signInWithPopup().then(function(result) {
    // This gives you a Google Access Token. You can use it to access the Google API.
    var token = result.credential.accessToken;
    // The signed-in user info.
    var user = result.user;
    // ...
    }).catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
    });*/
    console.log("render of sign in")
    return  <div>
                <PageHeader dispatch={this.props.dispatch}>
                </PageHeader>
                <div className="searchcriteriamaterialgrid">
                  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="searchcriteriamaterialgrid3">
                    <Grid container justifyContent="center" style={{maxWidth:"1200px"}} spacing={2}>
                      <Grid item xs={12} sm={6} justify="center">
                        <FormControl fullWidth>
                          <TextField required id="email" label="email" onChange={this.handleEmailChange}
                              error={this.state.emailError}
                              helperText={this.state.emailHelperText}/>
                          <TextField required id="password" label="password" type="password"
                            onChange={this.handlePasswordChange}
                            error={this.state.passwordError}
                            helperText={this.state.passwordHelperText}/>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </div>
                    <Grid container justifyContent="center">
                      <Grid item justify="center" rowSpacing={3}>
                          <button className="button">Sign Up</button>
                      </Grid>
                    </Grid>
                </form>
                <br/>
                <br/>
                <br/>
                <br/>
                <Grid container justifyContent="center">
                  <Grid item justify="center" rowSpacing={3}>
                    <Typography variant="body2">
                      By signing up, I agree to the <Link href="termsandconditions.html" target="_blank" underline="always">terms and conditions</Link>
                    </Typography>
                  </Grid>
                </Grid>
            </div>

  }
}
export default connect(

)(SignIn);
