import React, {Component} from "react";
import PageHeader from './PageHeader';
import {connect} from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import TextField from '@mui/material/TextField';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import {push} from 'react-router-redux';
import {getSearchResultRefined} from '../action';
import '../styles/selectbelowmenu.css';
import '../styles/selectbelowmenu.css';

class Reviews extends Component {
  constructor(props){
    super(props);
    this.handleRatingChange = this.handleRatingChange.bind(this);
    this.handleChangeReview = this.handleChangeReview.bind(this);
    this.goBack = this.goBack.bind(this);
    this.state = {
      tripId: props.searchResult.tripid,
      travelerid: props.searchResult.travelerid,
      tripactivitytypenames: props.searchResult.tripactivitytypenames,
      destinationTo: props.searchResult.destinationTo,
      reviewList: [],
      travelerName: "",
      newRating: null,
      ratingComment: null
    }
    console.log(this.state.tripId);
    //get reviews for trip
    fetch(process.env.REACT_APP_HOSTNAME+'/api/ratings/getreviewsfortrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripId": this.state.tripId,
            })//body
          })
          .then(res => res.json())
          .then(data =>{
            console.log(data);
            this.setState({
              reviewList: data
            })
          })
    //get traveler name
    fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/gettravelername',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
            })//body
          })
          .then(res => res.json())
          .then(data =>{
            console.log(data);
            this.setState({
              travelerName: data[0].name
            })
          })
  }//constructor

  saveReview(){
    console.log(this.state.tripId);
    console.log(this.state.newRating);
    console.log(this.state.ratingComment);
    console.log(this.state.travelerid);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/ratings/savereviewfortrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripid": this.state.tripId,
                                   "rating": this.state.newRating,
                                   "comment": this.state.ratingComment,
                                   "travelerid": this.state.travelerid
              })//body
          })
          .then(res => res.json())
          .then(data =>{
            console.log(data);
            this.setState({
              reviewList: data
            });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             event: 'pageview',
             pageName: 'reviews'
            });
          })//end of then
  }//end of save review

  handleRatingChange(event){
    console.log(event.target.value);
    this.setState({
      newRating: event.target.value
    })
  }

  handleChangeReview(event){
    console.log(event.target.value);
    this.setState({
      ratingComment: event.target.value
    })
  }

  goBack(){
    console.log("go back");
    var searchCriteria = {};
    searchCriteria.tripactivitytypenames = this.state.tripactivitytypenames;
    searchCriteria.destinationto = this.state.destinationTo;

    const { dispatch } = this.props;
    dispatch(push('/tripswithoutgrouping'));
    dispatch(getSearchResultRefined(searchCriteria));
  }

  render(){
    return <div>
      <PageHeader topName="Sign In" dispatch={this.props.dispatch}>
      </PageHeader>
      <div className="addcardposition">
        <IconButton id="closeReview" aria-label="delete" style={{float: "right"}}>
          <CloseIcon onClick={() => this.goBack()}/>
        </IconButton>
      </div>
      <div className="selectplacement">
        <Card key={this.state.travelerid} variant="outlined" style={{maxWidth:"1200px"}}>
          <CardContent>
          <Typography variant="body1" component="h2">
            {this.state.travelerName}
          </Typography>
            <Rating name="rating1" size="small" value={this.state.newRating} onChange={this.handleRatingChange} />
            <br></br>
            <TextField aria-label="minimum height"
            variant="outlined"
            id="this.state.ratingComment"
            height="100px"
            fullWidth
            multiline
            rows={3}
            placeholder="Review Comments"
            onChange={this.handleChangeReview}/>
          </CardContent>
          <CardActions style={{paddingTop: "0px", paddingBottom: "1px"}}>
            <Button id="saveReview" size="small" style={{color: "rgba(0,150,166,1.0)"}} onClick={() => this.saveReview()}>
              Save
            </Button>
          </CardActions>
        </Card>
        <Typography variant="body1" component="h2">
          Reviews
        </Typography>
        {(this.state.reviewList)?this.state.reviewList.map(review =>
          <Card key={review.id} variant="outlined" style={{maxWidth:"1200px"}}>
              <CardContent>
                <Typography variant="body1" component="h2">
                  {review.name}
                </Typography>
                <Rating name="read-only" size="small" value={review.rating} readOnly />
                <Typography variant="body1" component="h2">
                  {review.ratingDate}
                </Typography>
                <Typography variant="body1" component="h2">
                  {review.comment}
                </Typography>
              </CardContent>
          </Card>
      ):""}
      </div>
    </div>
  }
}
const mapStateToProps = state => {
  console.log(state);
  return state;
}

const mapDispatchToProps = dispatch => {
  console.log("inside dispatch of search result");
}

export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(Reviews);
