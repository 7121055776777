import React, {Component} from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {connect} from 'react-redux';

/*const test1 = [
  {value:1, label: 'test1'},
  {value:2, label: 'test2'}

]*/

class SelectMyTrip extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedTrip: [],
      clearable: true,
      searchable: true,
      tripList: props.tripList?props.tripList:[],
      travelerid: props.travelerid
    }


    console.log(props.travelerid);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getmytrips',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
             body:JSON.stringify({"travelerId": props.travelerid,
           })
          })
    .then(res => res.json())
    .then(data => {

      this.setState({
        tripList: data
      })
      console.log(this.state.tripList);
    })

  }


 handleChange = selectedOption => {
   this.setState({ selectedOption: selectedOption.target.value });
    console.log(`Option selected:`, selectedOption.target.value);
    this.props.onChange(selectedOption.target, this.state.tripList);
 }



  render(){
    /*let options1 = this.state.destinationFrom.map(function (city) {
      return { value: city.id, label: city.name };
   })*/


   {/*<Select className="selectbox"
       name="destinationFromSelect"
       value={this.state.selectedOption}
       onChange={this.handleChange}
       clearable={this.state.clearable}
       searchable={this.state.searchable}
       options={options1}
     />
     */}



    return (
            <TextField
              id="selectbox1"
              select
              label="Trip List"
              style = {{width: 100}}
              value={this.state.selectedOption? this.state.selectedOption: ""}
              onChange={this.handleChange}
              >
              {this.state.tripList.map(option2 => (
                <MenuItem key={option2.id} value={option2.id}>
                  {option2.name}
                  </MenuItem>
                ))}

            </TextField>

          );
  }
}


const mapStateToProps = state => {
  console.log(state);
  //console.log(state.searchResult.travelerid);
  return state;
  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
}
export default connect(mapStateToProps)(SelectMyTrip)
