import React, {Component} from 'react';
import {connect} from 'react-redux';
import PageHeader from './PageHeader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';



class Profile extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProfileNameChange = this.handleProfileNameChange.bind(this);
    this.handleDobChange = this.handleDobChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      id: null,
      name: null,
      email: null,
      profileName: null,
      dateOfBirth: null,
      picture: null,
      emailError: false,
      emailHelperText: '',
      open: false
    }
    console.log(this.state.travelerid);
    console.log(props);
    if(props){
      console.log(props.searchResult);
      if(props.searchResult){
        this.setState({
          travelerid: props.searchResult.travelerid
        })

        if(props.searchResult.travelerid){
          fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/getprofile',
                  { method:'POST',
                    headers:{
                     "Content-Type": "application/json"
                   },
                   body:JSON.stringify({"travelerid": props.searchResult.travelerid,
                 })
                })
          .then(res => res.json())
          .then(data => {
            console.log(data[0])
            this.setState({
              id: data[0].id,
              name: data[0].name,
              email: data[0].email,
              profileName: data[0].profilename,
              dataOfBirth: data[0].dob,
            })//end of set state
          })//end of then
        }//end if traveler id
      }//end if props.searchresult
    }//end if props
  }//end of constructor

  handleProfileNameChange(event){
    console.log(event.target.value)
    this.setState({
      profileName: event.target.value,
      profileNameError: false,
      profileNameHelperText: '',
    })
  }

  handleDobChange(event){
    console.log(event.target.value)
    this.setState({
      dateOfBirth: event.target.value,
      dateOfBirthError: false,
      dateOfBirthHelperText: '',
    })
  }

 handleClose(event){
   console.log("Inside handle close snack bar")
   this.setState({
     open: false
   })
 }

  handleSubmit(event){
    console.log("Inside submit");
    console.log(event);
    event.preventDefault();
    console.log(this.state.travelerid);
    console.log(this.state.profileName);
    console.log(this.state.dateOfBirth);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/update',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({
                                    travelerid: this.state.travelerid,
                                    profilename: this.state.profileName,
                                    dob: this.state.dateOfBirth,
              })
          })
          .then(res => res.json())
          .then(data => {
              console.log(data);
              this.setState({
                open: true
              })
            })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    if(nextProps.searchResult){
      console.log(nextProps.searchResult.travelerid);
      console.log(prevState.travelerid);
      var retData = [];
      if(nextProps.searchResult.travelerid && !prevState.travelerid){
          return {travelerid: nextProps.searchResult.travelerid,
                  };
      }
    }
    else {
      return null;
    }
  }

  render(){

    return  <div>
                <PageHeader dispatch={this.props.dispatch}>
                </PageHeader>
                <div className="searchcriteriamaterialgrid">


                <form onSubmit={this.handleSubmit}>
                  <div className="searchcriteriamaterialgrid3">

                      <FormControl fullWidth>
                        <TextField id="name" label="Name" value={this.state.name} disabled={true} InputLabelProps={{
                          shrink: true,
                        }}/>
                        <TextField id="email" label="email" value={this.state.email} disabled={true} InputLabelProps={{
                          shrink: true,
                        }}/>
                        <TextField id="ProfileName" label="Nick Name" value ={this.state.profileName} onChange={this.handleProfileNameChange}/>
                        <TextField id="dataOfBirth" label="Date of Birth" value={this.state.dateOfBirth} onChange={this.handleDobChange}/>

                      </FormControl>

                  </div>
                  <Grid container justify="center" style={{maxWidth:"1200px"}}>
                    <button className="button" >Save</button>
                  
                  </Grid>
                  <Snackbar
                    open={this.state.open}
                    autoHideDuration={6000}
                    onClose={this.handleClose}
                    message="Profile Saved"
                    action={<IconButton
                              size="small"
                              aria-label="close"
                              color="inherit"
                              onClick={this.handleClose}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>}
                  />
                </form>
              </div>
            </div>

  }
}
const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);

  return state;
}
export default connect(mapStateToProps)(Profile);
