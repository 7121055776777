import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import RestaurantIconImg from '../icons/baseline_restaurant_black_18dp.png';



class RestaurantIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={RestaurantIconImg} />
          </div>
  }
}

export default connect()(RestaurantIcon);
