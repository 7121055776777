import React, {Component} from "react";
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import '../styles/selectbelowmenu.css'
import PageHeader from './PageHeader';
import PageMenu from './PageMenu';
import SelectMyTrip from './SelectMyTrip';
import {connect} from 'react-redux';
import {withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Marker} from 'react-google-maps';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DateFnsUtils from "@date-io/date-fns";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
//import * as fs from 'fs';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import {push} from 'react-router-redux';


class UploadPics extends Component {
  constructor(props){
    super(props);
    console.log(props);

    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.getPhotoKeys = this.getPhotoKeys.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.state = {
      travelerid: props.searchResult.travelerid,
      tripId:props.searchResult.tripid,
      tripActivityId:props.searchResult.tripactivityid,
      s3client: null,
      photoKeys: []
    }
    var test1 = []
    AWS.config.credentials = new AWS.Credentials
    ({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
    this.state.s3client = new AWS.S3({region: "us-east-2"});
    const prefixVal = 'photos_'+this.state.tripId+'/'+this.state.tripActivityId+'/';
    console.log(prefixVal);
    /*this.state.s3client.listObjects({Bucket: 'itifindphotos', Prefix: prefixVal}, function(err, data){
      if(err){
        console.log("Error", err);
      }
      if(data){
        console.log("Success", data);
        const keyList = data.Contents;
        test1.concat(keyList);
      }
    })*/
    var keys = this.getPhotoKeys(prefixVal);
    /*console.log(keys);
    this.setState({
      photoKeys: keys
    })*/

    //get traveler id
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    console.log(this.state.tripActivityId);
    console.log(this.state.photoKeys);

  }

  async getPhotoKeys(prefixVal){
    var keys = [];
    var data = await this.state.s3client.listObjects({Bucket: 'itifindphotos', Prefix: prefixVal}).promise();
    if(data.Contents){
      data.Contents.forEach( async (elem) => {
        var data2 = await this.state.s3client.getObject({Bucket: 'itifindphotos',
                                      Key: elem.Key}).promise();
        if(data2.Body){
          keys.push(data2.Body);
          this.setState({
            photoKeys: keys
          })
        }
      });
    console.log(this.state.photoKeys);
    }
    return keys;
  }

  handleFileSelected(event){
    console.log ("inside event");
    console.log(event.target.value);
    console.log(event.target.files);
    console.log(event.target.files[0]);
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    console.log(this.state.tripActivityId);
    if(!this.state.travelerid){
      //
      return;
    }
    if(!this.state.tripId){
      return;
    }
    if(!this.state.tripActivityId){
      return;
    }

    Object.entries(event.target.files).map(([key, file1]) => {
    //var file1 = event.target.files[0];
    console.log(file1.name);
    var reader = new FileReader();
    var result = null;
    reader.addEventListener('load', (event1) => {
      console.log("file read");
      result = event1.target.result;

      var buf = new Buffer (result, 'binary');

      var keys1 = this.state.photoKeys;
      keys1.push(buf);
      this.setState({
        photoKeys: keys1
      })

      const keyVal = 'photos_'+'/'+this.state.tripId+'/'+this.state.tripActivityId+'/'+file1.name;
      console.log(keyVal)
      //console.log(buf);
      const tempParams1 = {Bucket: 'itifindphotos',
                          Key: keyVal,
                          Body: buf};
      this.state.s3client.upload(tempParams1, function(err, data){
        if(err){
          console.log(err);
        }
        else{
          console.log("Success");
          
        }//end else
      })//end upload


    })
    reader.readAsDataURL(file1);
    })






    //this.state.s3client.upload()
  }

  handleBack(event){
    console.log("inside back button");
    const { dispatch } = this.props;
    dispatch(push('/mytrips'));
  }

  render(){
    return <div>
              <PageHeader topName="Sign In" className="menubartop" dispatch={this.props.dispatch}>
              </PageHeader>
              <div className="cardposition">
                <input
                   accept="image/*"
                   style={{ display: 'none' }}
                   id="raised-button-file"
                   multiple
                   type="file"
                   onChange={this.handleFileSelected}
                  />
                  <label htmlFor="raised-button-file">
                   <Button variant="outlined" size="small" component="span" style={{color: "rgba(0,150,166,1.0)"}}>
                     Add Photos
                   </Button>
                  </label>
                  <Button variant="outlined" size="small" component="span" style={{color: "rgba(0,150,166,1.0)"}} onClick={this.handleBack}>
                    Back
                  </Button>
                </div>
                <div className="cardposition">
                  <ImageList cellHeight={160} cols={3}>
                    {this.state.photoKeys.map((tile) => (
                      <ImageListItem key={tile} >
                        <img src={tile} />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </div>
           </div>
  }
}

const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);
  return state;
  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
}


export default connect(mapStateToProps)(UploadPics)
