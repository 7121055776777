import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PoolIconImg from '../icons/baseline_pool_black_18dp.png';



class PoolIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={PoolIconImg} />
          </div>
  }
}

export default connect()(PoolIcon);
