import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import HotelIconImg from '../icons/baseline_local_hotel_black_18dp.png';



class HotelIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={HotelIconImg} />
          </div>
  }
}

export default connect()(HotelIcon);
