import React, {Component} from "react";
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import {GetPopularTripsRefined, SearchResultRefined} from '../action';


class PageSubMenu2 extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.state = {

      selectedValue: parseInt(props.menuSelectedValue)
    }
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      destinationTo: props.destinationto,
      travelerId: props.searchResult?props.searchResult.travelerid:null,
      searchCriteria: props.searchResult,
      activityTypeNames : props.activitytypenames
    }
  }

  handleChange(event, newValue){
    event.preventDefault();
    console.log('handle change page menu');
    console.log(newValue);
    console.log(event);
    console.log(this.state.activityTypeNames);
    console.log(this.state.destinationTo);
    /*if(newValue === 1){
      this.setState({
        selectedValue: 1
      })
      const { dispatch } = this.props;
      dispatch(push('/populartrips'));
      dispatch(getPopularTripsRefined(searchCriteria));
    }
    else if(newValue === 0){
      this.setState({
        selectedValue: 0
      })
      const { dispatch } = this.props;
      //dispatch(push('/'));
      dispatch(push('/tripswithoutgrouping'));
      dispatch(getSearchResultRefined(searchCriteria));
    }*/

  }
  render(){
    /*return <div className="menubar">
              <div className="menutext1">{this.props.menuItem1}</div>
              <div className="menutext2">{this.props.menuItem2}</div>
              <div className="menutext3">{this.props.menuItem3}</div>
           </div>
*/
      return <div className="menubar">
              <AppBar style={{background: '#00bbd3'}}>
                <Tabs value={this.state.selectedValue?this.state.selectedValue:0} onChange={this.handleChange}>
                  <Tab label="Latest"  />
                </Tabs>
              </AppBar>
            </div>

  }
}

const mapStateToProps = state => {
  console.log(state);
  return state;
}
export default connect(
  mapStateToProps
)(PageSubMenu2)
