import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

export default function SimpleMediaQuery(args) {
  console.log(args);
  var inp1 = args.inp;
  console.log(inp1);
  const matches = useMediaQuery('(min-width:600px)');

  console.log(matches);

  return   <ImageList cols={matches? 3 : 2} gap={matches?8:3}>
          {inp1.map((tile) => (
          <ImageListItem id={tile.title} key={tile.title} onClick={() => args.parent.handleTileClick(tile)}>
            <img src={tile.img}/>
            <ImageListItemBar
              title={tile.title}
              subtitle={tile.activityTypes}
            />
          </ImageListItem>
           ))}
    </ImageList>;
}
