import React, {Component} from "react";
import '../styles/WhiteHeader.css';
import '../styles/TopBar.css';
import { makeStyles } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import * as firebaseui from "firebaseui"
import {replace, push} from 'react-router-redux';
import {signin} from '../action';
import {connect} from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {passTravelerId, getProfile} from '../action';
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import { PropTypes } from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';


class PageHeader extends Component {

  constructor(props){
    super(props);
    console.log(props);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleBugClick = this.handleBugClick.bind(this);
    this.handleBugReportSubmit = this.handleBugReportSubmit.bind(this);
    this.handleCloseBugReport = this.handleCloseBugReport.bind(this);
    this.handlePageNameChange = this.handlePageNameChange.bind(this);
    this.handleCommentsChange = this.handleCommentsChange.bind(this);
    this.state = {
      topName: null,
      signedIn: false,
      currUser: null,
      anchorEl: false,
      travelerid: this.props.travelerid,
      displayName1: this.props.displayName,
      displayName: this.props.displayName,
      comments: "",
      pageName: null,
      bugReportOpen: false
    }

    if(!this.state.travelerid){
      if(this.props.searchResult){
        this.setState({
          travelerid: this.props.searchResult.travelerid
        })
      }
    }
    if(!this.state.displayName1){
      if(this.props.searchResult){
        this.setState({
          displayName1: this.props.searchResult.displayName,
          displayName: this.props.searchResult.displayName
        })
      }
    }
    console.log("constructor of page header");

  //  if(!this.state.travelerid && !this.state.displayName1){
      firebase.auth().onAuthStateChanged(
          (user) => {this.setState({signedIn: !!user});
                     this.setState({currUser: user});
                     /*if(user && !this.state.displayName1){
                       this.setState({displayName1: user.displayName});
                     }*/
                     console.log("Inside auth state changed");
                      console.log(user);
                      console.log("on auth state change ");
                      console.log(!!user);
                      console.log("on auth current user "+firebase.auth().currentUser)
                      console.log(this.props.travelerid);
                      if(!this.props.travelerid && user != null){
                        console.log("inside if loop")
                        var displayName1 = user.displayName
                        /*this.setState({displayName1: displayName1})
                        if(!displayName1){
                          console.log("inside update display name");
                          displayName1 = user.email.substr(0, user.email.indexOf('@'));
                          this.setState({displayName1: displayName1});
                        }*/
                        fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/checktravelerexists',
                                { method:'POST',
                                  headers:{
                                   "Content-Type": "application/json"
                                 },
                                 body:JSON.stringify({"name": displayName1,
                                                      "email": user.email
                               })
                              })
                        .then(res => res.json())
                        .then(traveler => {
                                              console.log(traveler);
                                              console.log(traveler[0].id);
                                              this.setState({travelerid: traveler[0].id,
                                                              displayName1: traveler[0].profilename?
                                                              traveler[0].profilename:
                                                              traveler[0].name});

                                              //this.props.travelerid = travelerid[0].id;
                                              const { dispatch } = this.props;
                                              dispatch(passTravelerId(traveler[0].id, traveler[0].profilename))})
                      }
                    }
      );
  //  }



  }

  handleSignIn(event){
    console.log('sign in');
    console.log(this.props);
    const { dispatch } = this.props;
    console.log(dispatch);
    dispatch(push('/signin'));
    dispatch(signin);
  }

  handleSignOut(event){
    console.log('sign out');
    console.log(event);
    firebase.auth().signOut();
  }

  handleClick(event){
    this.setState({anchorEl: event.currentTarget});
  }

  handleClose(event){
    this.setState({anchorEl: false});
  }

  handleMenuClick(event) {
    console.log("inside handle menu click");
    console.log(this.state.travelerid);
    console.log(this.state.displayName1);
    console.log(this.props.travelerid);
    console.log(this.props.displayName);
    const { dispatch } = this.props;
    console.log(dispatch);
    dispatch(push('/'));
    dispatch(passTravelerId(this.state.travelerid, this.state.displayName1));

  }
  getProfile(event){
    console.log("inside getting profile");
    console.log(event);
    const { dispatch } = this.props;

    dispatch(getProfile(this.state.travelerid));
    dispatch(push('/getprofile'));
  }


  handleBugClick(event) {
    console.log("inside handle bug click");
    console.log(this.props)
    console.log(this.state)
    this.setState({
      bugReport: true,
      pageName: "",
      comments: "",
    })


  }
  handleBugReportSubmit(){
    console.log("handle bug report submit")
    console.log(this.state.pageName)
    console.log(this.state.comments)

    fetch(process.env.REACT_APP_HOSTNAME+'/api/ratings/createbugreport',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({pagename: this.state.pageName,
                                   comment: this.state.comments,

              })
    })
    .then(data => {
          console.log(data);
    })
    .catch(error => {console.log(error); return error})
    this.setState({
      bugReport: false,
    })
  }
  handleCloseBugReport(){
    console.log("handle bug report close");
    this.setState({
      bugReport: false,
    })
  }
  handlePageNameChange(event){
    console.log("handle page name change");
    console.log(event.target.value);
    this.setState({
      pageName: event.target.value,
    })
  }

  handleCommentsChange(event){

    this.setState({
      comments: event.target.value,
    })
  }

  render(){

  /*var firebaseConfig = {
    apiKey: "AIzaSyBGysjJjwqtqgn9A-tOIh3M2HSf6MDkzwg",
    authDomain: "trips-64206.firebaseapp.com",
    databaseURL: "https://trips-64206.firebaseio.com",
    projectId: "trips-64206",
    storageBucket: "trips-64206.appspot.com",
    messagingSenderId: "343327137283",
    appId: "1:343327137283:web:de0b857b7b47a650a13836",
    measurementId: "G-DNDLFBVZM3"
  }

  var firebaseui = require("firebaseui")
  var ui = new firebaseui.auth.AuthUI(firebase.auth());
  ui.start('#firebaseui-auth-container', {
    signInOptions:[
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID
    ]
  });*/
  console.log(this.state.currUser);
  console.log("signed in")
  console.log(this.state.signedIn);
  console.log(this.props.appendedCriteria);
  console.log(this.state.displayName1);
  if(!this.state.displayName1 && this.state.currUser){
    var displayName1 = this.state.currUser.displayName
    this.setState({displayName1: displayName1})
    if(!displayName1){
      console.log("inside update display name");
      displayName1 = this.state.currUser.email.substr(0, this.state.currUser.email.indexOf('@'));
      this.setState({displayName1: displayName1});
    }
  }
  console.log(this.state.displayName1);

  var user = firebase.auth().currentUser;
  console.log("current user");
  console.log(user);

    return (

        <div className="header1div1">
        <AppBar position="static">
          <Toolbar className="Toolbarcolor">
                  <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.handleMenuClick}>
                    <HomeIcon />
                  </IconButton>
                  <Typography variant="h6" className="headerlabel">
                    <span>RoamWorth<sup style={{"font-size":"8.0pt"}}>Beta</sup></span>
                  </Typography>

                  <IconButton edge="start" color="inherit" aria-label="menu"
                          onClick={this.handleBugClick}>
                    <BugReportIcon />
                  </IconButton>
                  <Dialog open={this.state.bugReport} onClose={this.handleCloseBugReport} aria-labelledby="form-dialog-title"
                          aria-describedby="test1" fullWidth={true}>
                        <DialogTitle id="form-dialog-title1">Share comments & bugs</DialogTitle>
                        <DialogContent>
                          <FormControl fullWidth={true} maxWidth={'md'} >
                            <InputLabel id="demo-simple-select-helper-label">Page</InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={this.state.pageName}
                              label="Activity Type"
                              onChange={this.handlePageNameChange}
                            >

                              <MenuItem key="Explore" value="Explore">Explore</MenuItem>
                              <MenuItem key="Create" value="Create">Create</MenuItem>
                              <MenuItem key="View" value="View">View</MenuItem>
                              <MenuItem key="Share" value="Share">Shared</MenuItem>
                              <MenuItem key="Checkin" value="Checkin">Check In</MenuItem>

                            </Select>
                            <TextField
                               id="outlined-multiline-static"
                               label="Comments"
                               multiline
                               fullWidth
                               rows={4}
                               onChange={this.handleCommentsChange}
                               defaultValue={this.state.comments}
                             />

                          </FormControl>
                        </DialogContent>
                        <DialogActions>
                          <Button id="closeBugReport" onClick={this.handleCloseBugReport} color="primary">
                            Cancel
                          </Button>
                          <Button id="sybmitBugReport" onClick={this.handleBugReportSubmit} color="primary">
                            Ok
                          </Button>
                        </DialogActions>
                  </Dialog>
                  {(!this.state.currUser)? <Button color="inherit" onClick={this.handleSignIn}>Signin</Button> :
                          <div>
                            <Button id="signInorOut" color="inherit" onClick={this.handleClick}>{this.state.displayName1}</Button>
                            <Menu id="simplemenu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}>
                              <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
                              <MenuItem onClick={this.getProfile}>Profile</MenuItem>
                            </Menu>
                          </div>
                      }

          </Toolbar>
        </AppBar>
      </div>
    );

  }
}

const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);
  return{
    travelerid: state.searchResult.travelerid,
    displayName: state.searchResult.displayName
  }
  //this.props.travelerid = state.travelerid;
  //return state;
}

export default connect(mapStateToProps)(PageHeader)
