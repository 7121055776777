import React, {Component} from "react";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

/*const test1 = [
  {value:1, label: 'test1'},
  {value:2, label: 'test2'}

]*/

export default class SelectDestinationTo extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedDestinationTo: [],
      clearable: true,
      searchable: true,
      destinationTo: [],
      selectedOption: props.todestinationid?props.todestinationid:""
    }
    fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
              }
          })
    .then(res => res.json())
    .then(data => {

      this.setState({
        destinationTo: data
      })
      console.log(this.state.destinationTo);
    })

  }


 handleChange = selectedOption => {
   this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption.value);
    this.props.onChange(selectedOption);
 }

 handleChangeTo = selectedOption => {
   this.setState({ selectedOption: selectedOption.target.value });
    console.log(`Option selected:`, selectedOption.target.value);
    this.props.onChange(selectedOption.target);
 }

  render(){
    /*let options1 = this.state.destinationTo.map(function (city) {
      return { value: city.id, label: city.name };
   })*/
   return (
           <TextField
             id="selectbox1"
             select
             label="Destination To"
             value={this.state.selectedOption? this.state.selectedOption: ""}
             onChange={this.handleChangeTo}
             >
             {this.state.destinationTo.map(option2 => (
               <MenuItem key={option2.id} value={option2.id}>
                 {option2.name}
                 </MenuItem>
               ))}

           </TextField>

         );
  /*  return (
            <Select className="selectbox"
                name="destinationToSelect"
                value={this.state.selectedOption}
                onChange={this.handleChange}
                clearable={this.state.clearable}
                searchable={this.state.searchable}
                options={options1}
              />

          );
*/


  }
}
