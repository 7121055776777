import React, {Component} from "react";
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import { makeStyles } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import * as firebaseui from "firebaseui"
import {replace, push} from 'react-router-redux';
import {signin} from '../action';
import {connect} from 'react-redux';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {passTravelerId, getProfile} from '../action';
import '../styles/MenuBar.css';
import '../styles/MenuText.css';

export default class PageHeaderData extends Component {

  constructor(props){
    super(props);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.state = {
      topName: null,
      signedIn: false,
      currUser: null,
      anchorEl: null,
      travelerid: this.props.travelerid,
      displayName: this.props.displayName,
    }
    firebase.auth().onAuthStateChanged(
        (user) => {this.setState({signedIn: !!user});
                   this.setState({currUser: user});
                    console.log(user);
                    console.log(this.props.travlerid);
                    if(!this.props.travelerid){
                      fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/checktravelerexists',
                              { method:'POST',
                                headers:{
                                 "Content-Type": "application/json"
                               },
                               body:JSON.stringify({"name": user.displayName
                             })
                            })
                      .then(res => res.json())
                      .then(travelerid => {
                                if(travelerid && travelerid[0]) {
                                  console.log(travelerid[0].id);
                                  this.setState({travelerid: travelerid[0].id,
                                    displayName: travelerid[0].profilename?
                                    travelerid[0].profilename:
                                    travelerid[0].name});
                                  //this.props.travelerid = travelerid[0].id;
                                  const { dispatch } = this.props;
                                  dispatch(passTravelerId(travelerid[0].id,
                                    travelerid[0].profilename))
                                  }
                            })

                    }
                  }
    );
  }

  handleSignIn(event){
    console.log('sign in');
    console.log(this.props);
    const { dispatch } = this.props;
    console.log(dispatch);
    dispatch(push('/signin'));
    dispatch(signin);
  }

  handleSignOut(event){
    console.log('sign out');
    console.log(event);
    firebase.auth().signOut();
  }

  handleClick(event){
    this.setState({anchorEl: event.currentTarget});
  }

  handleClose(event){
    this.setState({anchorEl: null});
  }

  getProfile(event){
    console.log("inside getting profile");
    console.log(event);
    const { dispatch } = this.props;

    dispatch(getProfile(this.state.travelerid));
    dispatch(push('/getprofile'));
  }

  render(){

    console.log(this.props.appendedCriteria);

    return (

             <div className="menubartop">
                <div className="menutexttop1">{this.props.appendedCriteria}</div>
                <div className="menutexttop2">
                {(!this.state.currUser)? <Button color="inherit" onClick={this.handleSignIn}>Signin</Button> :
                        <div>
                          <Button color="inherit" onClick={this.handleClick}>{this.state.currUser.displayName}</Button>
                          <Menu id="simplemenu"
                          anchorEl={this.state.anchorEl}
                          keepMounted
                          open={Boolean(this.state.anchorEl)}
                          onClose={this.handleClose}>
                            <MenuItem onClick={this.handleSignOut}>Sign Out</MenuItem>
                            <MenuItem onClick={this.getProfile}>Profile</MenuItem>
                          </Menu>
                        </div>
                    }
                </div>
             </div>
    );

  }
}
