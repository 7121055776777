import React, {Component} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {searchResult} from '../reducers';
import {connect} from 'react-redux';
import {getSearchResult, createTrip, editTrip} from '../action';
import PageSubMenu2 from './PageSubMenu2';
import PageHeaderData from './PageHeaderData';
import moment from 'moment';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {push} from 'react-router-redux';
import {getSearchResultRefined, getReviews, compareTrips} from '../action';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PageHeader from './PageHeader';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import '../styles/SearchCriteria.css';
import Chip from '@mui/material/Chip';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import BoatIcon from '@mui/icons-material/DirectionsBoat';
import Rating from '@mui/material/Rating';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import HotelIcon from '@mui/icons-material/Hotel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ToysIcon from '@mui/icons-material/Toys';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import BrushIcon from '@mui/icons-material/Brush';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import TagManager from 'react-gtm-module';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function getIconForActivity(activity){
  var icon = null;
  switch(activity){
    case "Snorkel":
      icon = <PoolIcon/>;
      break;
    case "Flight":
      icon = <FlightIcon/>;
      break;
    case "Sightseeing":
      icon= <TravelExploreIcon/>;
      break;
    case "Museum":
      icon = <MuseumIcon/>;
      break;
    case "Walking":
    case "Hiking":
      icon = <WalkIcon/>;
      break;
    case "Driving":
      icon = <DriveIcon/>;
      break;
    case "Bicycling":
    case "Biking":
    case "Hydrobiking":
      icon = <BicycleIcon/>;
      break;
    case "Restaurant":
      icon = <RestaurantIcon/>;
      break;
    case "Boating":
      icon = <BoatIcon/>;
      break;
    case "Swimming":
        icon = <PoolIcon/>;
        break;
    case "Iceskating":
    case "Skiing":
        icon = <AcUnitIcon/>;
        break;
    case "Hotel":
        icon = <HotelIcon/>;
        break;
    case "Concert":
        icon = <MusicNoteIcon/>;
        break;
    case "Thrillrides":
        icon = <ToysIcon/>;
        break;
    case "Golf":
    case "Minigolf":
        icon = <GolfCourseIcon/>;
        break;
    case "Artgalleries":
        icon = <BrushIcon/>;
        break;
    case "Train":
        icon=<DirectionsRailwayIcon/>;
        break;
    default:
        icon = <BeachIcon/> ;
  }
  return icon;
}

const tagManagerArgs = {
    dataLayer: {
        userProject: 'itifind.com',
        page: 'searchresultrefined',
        event: 'pageview'
    },
    dataLayerName: 'PageDataLayer'
}

function adSenseScript(){
  var externalScript   = document.createElement("script");
  externalScript.type  = "text/javascript";
  externalScript.setAttribute('async','async');
  externalScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6345293511201782";
  externalScript.crossorigin = "anonymous"
  document.getElementsByTagName('head')[0].appendChild(externalScript);
}

class SearchResultRefined extends Component {
  constructor(props){
    super(props);
    this.handlelisttextclick = this.handlelisttextclick.bind(this);
    this.camelize = this.camelize.bind(this);
    this.handleAddRatings = this.handleAddRatings.bind(this);
    this.handleCloneTrip = this.handleCloneTrip.bind(this);
    this.handleCloneItemsToTrip =this.handleCloneItemsToTrip.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleCompareCheck = this.handleCompareCheck.bind(this);
    this.handleClose4 = this.handleClose4.bind(this);
    this.handleSaveItemsToTrip = this.handleSaveItemsToTrip.bind(this);
    this.handleItemSelection = this.handleItemSelection.bind(this);
    this.state = {
      searchResultData: [],
      loading: true,
      travelerid: props.searchResult.travelerid,
      fromdestinationid: props.fromdestinationid?props.fromdestinationid:8,
      searchCriteria: props.searchResult,
      appendedCriteria: moment(props.searchResult.startDate).format('YYYY-MM-DD') + "  " +
                        moment(props.searchResult.endDate).format('YYYY-MM-DD') + "  " +
                        props.searchResult.tripTypeId + "  " +
                        props.searchResult.travelerCount,
      ratingValue: 3,
      open4:false,
      checkedActivityItems: [],
      tripList: [],
    };
    console.log(this.state.searchCriteria);

    AWS.config.credentials = new AWS.Credentials
    ({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
    this.state.s3client = new AWS.S3({region: "us-east-2"});

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/tripSearchRefined',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"destinationto": this.state.searchCriteria.destinationto,
                                  "tripactivitytypenames": this.state.searchCriteria.tripactivitytypenames,
                                  "duration": this.state.searchCriteria.duration,
                                  //"destinationfrom": this.state.fromdestinationid
                                  "activitydestinationids": this.state.searchCriteria.activitydestinationids,

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            var tripIdsToRemove = [];
            //for each trip, get trip Activity
            var index = 0;
            var clonedData = [];
            data.map(item => {
              fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/gettripactivitiesfortrip',
                      { method:'POST',
                        headers:{
                         "Content-Type": "application/json"
                       },
                        body:JSON.stringify({"tripId": item.tripid})
                    })
                    .then(res => res.json())
                    .then(data1 => {
                      console.log(data1)
                      index++;
                      //remove trip if all the activities are not present i.e. only a subset are present
                      //check if all activity types are present
                      //check if all destination ids are present
                      var tripActivityTypeNamesTemp = [];
                      this.state.searchCriteria.tripactivitytypenames.forEach(elem => {
                         tripActivityTypeNamesTemp.push(elem)
                      })

                      var tripActivityDestinationIdsTemp = [];
                      this.state.searchCriteria.activitydestinationids.forEach(elem => {
                        tripActivityDestinationIdsTemp.push(elem)
                      })
                      console.log(tripActivityTypeNamesTemp)
                      console.log(tripActivityDestinationIdsTemp)
                      data1.forEach(tripActivity => {
                        let index1 = tripActivityTypeNamesTemp.indexOf(tripActivity.tripactivitytypename)
                        console.log(index1)
                        if(index1 != -1){
                          tripActivityTypeNamesTemp.splice(index1,1)
                        }
                        let index2 = tripActivityDestinationIdsTemp.indexOf(tripActivity.tolocationid);
                        if(index2 != -1){
                          tripActivityDestinationIdsTemp.splice(index2,1)
                        }

                      })
                      console.log(tripActivityTypeNamesTemp)
                      console.log(tripActivityDestinationIdsTemp)
                      console.log(item.tripid)

                      if(tripActivityTypeNamesTemp.length == 0 )
                      {
                        var date1 = null;
                        var dateUnique = null;
                        var dateBasedArray = [];
                        var dayCounter = 0;
                        var dayLabel = null;
                        var finalObj = {};
                        var date2 = null;
                        var imgArray = [];
                        data1.forEach(dataitem1 => {
                          if(dataitem1.endTime){
                            dataitem1.endTime = moment(dataitem1.endTime).format("YYYY-MM-DD hh:mm A");
                          }


                          date1 = dataitem1.endTime.slice(0,10)
                          if (dateUnique == null){
                            dateUnique = date1;
                            date2 = dataitem1.endTime.slice(11, 19)
                            dataitem1.endTime = date2;
                            dateBasedArray.push(dataitem1);
                            dayCounter = 1;
                            dayLabel = "Day "+dayCounter;
                          }
                          else if(dateUnique == date1){
                            date2 = dataitem1.endTime.slice(11, 19)
                            dataitem1.endTime = date2;
                            dateBasedArray.push(dataitem1);
                          }
                          else if (dateUnique != date1){
                            finalObj[dayLabel] = dateBasedArray;
                            dateBasedArray = [];
                            dateUnique = date1;
                            date2 = dataitem1.endTime.slice(11, 19)
                            dataitem1.endTime = date2;
                            dateBasedArray.push(dataitem1);
                            dayCounter = dayCounter + 1;
                            dayLabel = "Day "+dayCounter;
                          }
                          //photos
                          console.log(dataitem1.simage1);
                          console.log(dataitem1.simage2);
                          if(dataitem1.simage1 && dataitem1.simage1 != null){
                            imgArray.push(dataitem1.simage1);

                          }
                          if(dataitem1.simage2 && dataitem1.simage2!= null ){
                            imgArray.push(dataitem1.simage2);

                          }
                          ///end if
                          if(imgArray.length > 0){
                            this.getPhotoKeys(imgArray, dataitem1.id);
                            imgArray = [];
                          }


                        })//end of for each data1
                        finalObj[dayLabel] = dateBasedArray;

                        console.log(finalObj);
                        item.activities = finalObj;
                        clonedData.push(item)
                        this.setState({
                          searchResultData: clonedData,
                          loading: false
                        });
                        console.log(this.state.searchResultData);
                        //console.log(Object.entries(this.state.searchResultData[0].activities));
                        //TagManager.dataLayer(tagManagerArgs);
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                         event: 'pageview',
                         pageName: 'searchResultRefined'
                        })
                      console.log(window.dataLayer);
                    //console.log(TagManager.dataLayer)
                    }//end if all the elements are present
                    else{
                      //remove trip id from data
                      //collect trip ids to remove
                      console.log("Inside else of additional trip exists in refined")

                    }//else of trip ids to remove

                  })//end then
              })//data.map
          })//end then
          .catch(error => {console.log(error); return error});


          //adsense connectivity
          adSenseScript();

  }

 getPhotoKeys(prefixValArr, tripActivityId){
    var keys = [];
    var data2 = null;
    console.log("Inside get photo keys");

    for(var i=0; i<prefixValArr.length; i++){
      if(!prefixValArr[i].includes("_sm") && prefixValArr[i].includes(".jpeg")){
        var tempPrefix = prefixValArr[i].substring(0,prefixValArr[i].indexOf(".jpeg"))
        prefixValArr[i] = tempPrefix+"_sm.jpg";
      }
      if(!prefixValArr[i].includes("_sm") && prefixValArr[i].includes(".jpg")){
        var tempPrefix = prefixValArr[i].substring(0,prefixValArr[i].indexOf(".jpg"))
        prefixValArr[i] = tempPrefix+"_sm.jpg";
      }
    }
    console.log(tripActivityId);

    prefixValArr.forEach((prefixVal) => {
      console.log(prefixVal);
      this.state.s3client.getObject({Bucket: 'itifindphotos',
                                    Key: prefixVal}, (err, data2) => {
        if(data2.Body){
          console.log("there is photo body existing");
          var photoElem = {"key1": prefixVal, "value1": data2.Body};
          keys.push(photoElem);

          var searchResultData = this.state.searchResultData;
          console.log(searchResultData[0].activities)
          Object.entries(searchResultData[0].activities).map(([key, value]) => {
            value.forEach(value1 => {
               if(value1.id == tripActivityId){
                console.log("adding photos to value");
                 value1.photos = keys;
               }
             })
         })//search result data
         console.log(searchResultData)
         this.setState({
           searchResultData: searchResultData
         })
       }//body
     });
   })//for each prefixval


    //console.log(tripActivityArray);

    //return;
  }

  handlelisttextclick(destinationTo, tripTypeId, searchCriteria){
     console.log(`Option selected in search result:`, destinationTo);
     console.log(`Option selected in search result:`, tripTypeId);
     console.log(`Option selected in search result:`, searchCriteria);
     searchCriteria.tripTypeId = tripTypeId;
     searchCriteria.destinationTo = destinationTo;
     const { dispatch } = this.props;
     dispatch(push('/tripswithoutgrouping'));
     dispatch(getSearchResultRefined(searchCriteria));

  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }

  handleCloneTrip(activityItem){
    console.log("Handle clone trip "+activityItem.tripid);
    console.log("traveler id "+this.state.travelerid);
    console.log("destination id "+this.state.fromdestinationid);
    console.log(activityItem);

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/clonetrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
                                   "tripid": activityItem.tripid,
                                   "fromdestinationid": this.state.fromdestinationid
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/createtrip'));
            dispatch(editTrip(data.tripid, data.tripname, this.state.travelerid, this.state.fromdestinationid,
              data.todestinationid, new Date(data.starttime), new Date(data.endtime), activityItem.todestinationname));
          })
          .catch(error => {console.log(error); return error})

  }

  handleCloneItemsToTrip(activityItem){
    console.log("inside clone items to trip");
    console.log(activityItem);
    console.log(this.state.checkedActivityItems);
    this.setState({
      open4: true,
      fromTripId: activityItem.tripid
    })
    //get trip List
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    if(this.state.travelerid){
      fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getmytrips',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
               body:JSON.stringify({"travelerId": this.state.travelerid,
             })
            })
      .then(res => res.json())
      .then(data => {

        this.setState({
          tripList: data
        })
        console.log(this.state.tripList);
        //get latest trip for default load
        if(data && data.length > 0){
          var defaultId = data[0].id;
          console.log("default id"+defaultId);
        }

      })
    }
  }

 handleCheck(activityItem3){
   console.log(activityItem3);
   this.state.checkedActivityItems.push(activityItem3);

 }

 handleCompareCheck(tripId){
   console.log(tripId);
   if(!this.state.compareTrip){
     this.setState({
       compareTrip: tripId
     })
   }else {
     //dispatch to compare screen
     console.log("go to compare trips page");
     const { dispatch } = this.props;
     dispatch(push('/comparetrips'));
     dispatch(compareTrips(this.state.compareTrip, tripId));
     this.setState({
       compareTrip: null
     })
   }
 }

  handleAddRatings(activityItem){
    console.log("Handle ratings "+activityItem.tripid);
    console.log("traveler id "+this.state.travelerid);
    const { dispatch } = this.props;
    dispatch(push('/reviews'));
    dispatch(getReviews(activityItem.tripid, this.state.travelerid,
                        this.state.searchCriteria.tripactivitytypenames,
                        this.state.searchCriteria.destinationto));
  }

  handleClose4(event){
    console.log("handle close 4");
    this.setState({
      open4: false
    })
  }

  handleItemSelection(input){
    console.log(input.target.value)
    this.setState({
      toTripId: input.target.value
    })
  }

  handleSaveItemsToTrip(){
    console.log("Inside save items to new trip user is building");
    console.log(this.state.fromTripId);
    console.log(this.state.toTripId);
    console.log(this.state.checkedActivityItems);

    //clone trip activities from trip id to trip id
    //change date to trip's last date
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/clonetripactivities',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
             body:JSON.stringify({"fromtripid": this.state.fromTripId,
                                  "totripid":this.state.toTripId,
                                  "activities": this.state.checkedActivityItems
           })
          })
    .then(res => res.json())
    .then(data => {
      console.log("success");
      console.log(data);
     })
     this.setState({
       open4: false
     })

  }


  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    if(nextProps.searchResult.travelerid){
      return {travelerid: nextProps.searchResult.travelerid};
    }
    else {
      return null;
    }
  }

  render(){
    //var items = this.state.searchResultData;
    return (
           <div>
             <PageHeader topName="SitripIdToAddTogn In" dispatch={this.props.dispatch}>
             </PageHeader>
             <PageSubMenu2 menuSelectedValue="0"/>
             <div className="searchcriteriamaterialgrid">
             {(this.state.searchResultData)?this.state.searchResultData.map(activityItem =>
               <Card key={activityItem.tripid} variant="outlined" style={{marginBottom: "5px"}}>
                   <CardContent>
                     <Typography variant="body1" component="h2">
                       {activityItem.todestinationname}
                     </Typography>
                     <FormControlLabel style={{paddingBottom: "5px"}}
                     control={<Checkbox size="small" onChange={() =>this.handleCompareCheck(activityItem.tripid)}
                     style={{padding: "0px", marginLeft: "10px", marginRight:"0px", color:"rgba(0,150,166,1.0)"}}/>}
                     label={<span style={{fontSize:"0.8125rem", color:"rgba(0,150,166,1.0)",
                          textTransform: "uppercase", fontWeight: "500", lineHeight: "1.75", letterSpacing: "0.02857em"}}>
                          compare trip
                          </span>}
                     />
                     <Rating name="read-only" size="small" value={activityItem.rating} readOnly
                        style={{padding:"0px", marginLeft: "50px"}}/>
                     {(activityItem.activities)?Object.entries(activityItem.activities).map(([key, value]) =>
                       <div >
                           <Typography key={key} variant="body2" color="textSecondary" style={{backgroundColor: "#A9A9A9"}}>
                            {key}
                           </Typography>
                           {value.map (activityItem3 =>
                             <div>
                               <FormControlLabel style={{display:"table",}}
                                control={<div style={{display: "table-cell",}}>
                                <Checkbox size="small" onChange={() =>this.handleCheck(activityItem3)}
                                style={{padding: "0px", marginLeft: "10px", marginRight:"0px", color:"rgba(0,150,166,1.0)"}}/></div>}
                                label={<Typography key={activityItem3.endTime} variant="caption" color="textSecondary">
                                        <b>{activityItem3.endTime}</b>:
                                        <Chip size="small" label={this.camelize(activityItem3.tripactivitytypename)}
                                        icon={getIconForActivity(this.camelize(activityItem3.tripactivitytypename))}/>
                                        {activityItem3.tolocation}
                                      </Typography>}
                                />
                                {activityItem3.advbooking?
                                  <div style={{display: 'flex', alignItems:'center'}}>
                                  <Avatar style={{backgroundColor: "Orange", width: "15px", height:"15px", marginBottom: "2px"}}><ErrorOutlineIcon
                                    fontSize="small"/></Avatar>
                                  <Typography variant="body2" color="#FFA500" component="p">Advance booking required</Typography>
                                  </div>
                                :""}
                                {activityItem3.photos?
                                  <ImageList cols={2}>
                                    {activityItem3.photos?activityItem3.photos.map((tile) => (
                                      <ImageListItem key={tile.key1}>
                                        <img key={tile.key1} src={tile.value1} />
                                      </ImageListItem>
                                    )):""}
                                  </ImageList>:""}
                            </div>
                          )}
                       </div>
                     ):""}
                   </CardContent>
                 <CardActions style={{paddingTop: "0px", paddingBottom: "1px"}}>
                   <Button style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={() => this.handleCloneTrip(activityItem)} >
                     Clone Trip
                   </Button>
                   <Button style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={() => this.handleCloneItemsToTrip(activityItem)} >
                     Clone Items to Trip
                   </Button>
                   <Button style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={() => this.handleAddRatings(activityItem)} >
                     Reviews
                   </Button>
                 </CardActions>
               </Card>
             ):""}
              </div>
              <Dialog open={this.state.open4} onClose={this.handleClose4} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
                <DialogTitle id="form-dialog-title">Select trip to add item to</DialogTitle>
                <DialogContent>
                 <Select
                    id="selectInDialog"
                    value={this.state.tripId}
                    onChange={this.handleItemSelection}
                    input={<Input/>}
                  >
                      {this.state.tripList.map(option2 => (
                      <MenuItem key={option2.id} value={option2.id}>
                        {option2.name}
                        </MenuItem>
                      ))}
                  </Select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleSaveItemsToTrip} color="primary">
                      Save
                    </Button>
                    <Button onClick={this.handleClose4} color="primary">
                      Cancel
                    </Button>
                </DialogActions>
              </Dialog>
           </div>

         )

  }
}
const mapStateToProps = state => {
  console.log(state);

  return state;

}


const mapDispatchToProps = dispatch => {
  console.log("inside dispatch of search result");
  dispatch(getSearchResult());
}


export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(SearchResultRefined);
