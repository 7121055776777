export const SEARCH_RESULT = "display_search_results";
export const SEARCH_CRITERIA = "search_criteria";
export const SIGN_IN = "sign_in";
export const MY_TRIPS = "my_trips";
export const PASS_TRAVELER_ID = "pass_traveler_id";
export const SEARCH_RESULT_REFINED = "search_results_refined";
export const SEARCH_RESULT_TRIP = "search_results_trip";
export const CREATE_MY_TRIP = "create_my_trip";
export const CREATE_TRIP_DETAIL = "create_trip_detail";
export const EDIT_MY_TRIP = "edit_my_trip";
export const UPLOAD_PHOTOS = "upload_photos";
export const GET_REVIEWS = "get_reviews";
export const POPULAR_TRIPS = "popular_trips";
export const COMPARE_TRIPS = "compare_trips";
export const GET_PROFILE = "get_profile";

export function getSearchResult(searchCriteria){
  console.log("inside get search result action");
  console.log(searchCriteria);
  return {type: SEARCH_RESULT, searchCriteria: searchCriteria};
}

export function signin(travelerid, displayName){
  console.log("inside signin reducer");
  return {type: SIGN_IN, travelerid: travelerid, displayName: displayName};
}

/*export function myTrips(menuSelectedValue, travelerId){
  console.log("inside my trips");
  console.log(menuSelectedValue);
  console.log(travelerId);
  return{type: MY_TRIPS, menuSelectedValue: menuSelectedValue, travelerId: travelerId};
}*/

export function passTravelerId(travelerid, displayName){
  console.log(travelerid);
  console.log(displayName);
  console.log("inside pass travelerid");
  return {type: PASS_TRAVELER_ID, travelerid: travelerid, displayName: displayName};
}

export function getSearchResultRefined(searchCriteria){
  return {type: SEARCH_RESULT_REFINED, searchCriteria: searchCriteria};
}

export function getSearchResultTrip(searchCriteria, destinationto, duration, tripTypeId){
  return {type: SEARCH_RESULT_TRIP, searchCriteria: searchCriteria, destinationto: destinationto,
    duration: duration, tripTypeId: tripTypeId};
}

export function editTrip(tripid, tripname, travelerid, fromdestinationid, todestinationid, fromdate, todate, todestinationname){
  return {type: EDIT_MY_TRIP, tripid: tripid, tripname:tripname, travelerid: travelerid,
    fromdestinationid: fromdestinationid, todestinationid:todestinationid, fromdate: fromdate, todate: todate,
    todestinationname: todestinationname};
}

export function createTrip(){
  return {type: CREATE_MY_TRIP};
}

export function createTripDetail(tripid, travelerid){
  return {type: CREATE_TRIP_DETAIL, tripid: tripid, travelerid: travelerid}
}

export function uploadPhotos(tripId, tripActivityId, travelerid){
  return {type: UPLOAD_PHOTOS, tripid: tripId, tripactivityid: tripActivityId, travelerid: travelerid};
}

export function getReviews(tripId, travelerId, tripactivitytypenames, destinationTo){
  return {type: GET_REVIEWS, tripid: tripId, travelerid: travelerId, tripactivitytypenames: tripactivitytypenames, destinationTo: destinationTo};
}

export function getPopularTrips(travelerId, displayName, activityTypeNames, destinationTo, duration, tripTypeId, profileName){
  return {type: POPULAR_TRIPS, travelerid: travelerId, displayName:displayName, activitytypenames: activityTypeNames,
    destinationto: destinationTo, duration: duration, tripTypeId: tripTypeId, profileName: profileName};
}

export function compareTrips(tripId1, tripId2){
  return {type: COMPARE_TRIPS, tripid1: tripId1, tripid2: tripId2};
}

export function getProfile(travelerId){
  return {type: GET_PROFILE, travelerid: travelerId};
}
