import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PlaneIconImg from '../icons/baseline_airplanemode_active_black_18dp.png';



class PlaneIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={PlaneIconImg} />
          </div>
  }
}

export default connect()(PlaneIcon);
