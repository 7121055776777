import React, { Component } from "react";
import PageHeader from './PageHeader';
import PageMenu from './PageMenu';
import ReactDOM from "react-dom";
import Button from '@mui/material/Button';
import {connect} from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import DateFnsUtils from "@date-io/date-fns";
import SelectDestinationFrom from './SelectDestinationFrom';
import SelectDestinationTo from './SelectDestinationTo';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import {push} from 'react-router-redux';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Typography from '@mui/material/Typography';
import MaterialTable from 'material-table';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';
import { forwardRef } from 'react';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import {createTripDetail} from '../action';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Select from '@mui/material/Select';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import '../styles/DateSize.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/*const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };*/


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
  console.log("load script");
}

const autocompleteService = { current: null };

/*let now = new Date();
const defaultStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultEndDate= moment(defaultStartDate).add(1, "days").subtract(1, "seconds");
const dateRanges = {
  "Today Only": [moment(defaultStartDate), moment(defaultEndDate)]
};
const dateRangeFormat = {
  "format": "DD-MM-YYYY HH:mm"
};*/

class CreateTrip extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeTripName = this.handleChangeTripName.bind(this);
    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleCreateNewTripActivity = this.handleCreateNewTripActivity.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleDestinationSelected = this.handleDestinationSelected.bind(this);
    this.handleFromLocationSelected = this.handleFromLocationSelected.bind(this);
    this.handleToLocationSelected = this.handleToLocationSelected.bind(this);
    this.setActivityDateRange = this.setActivityDateRange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleDateRangeClick = this.handleDateRangeClick.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    var tripactivitytypevalues;
    /*var testvalues = {1: "flight", 2: "hotel", 3: "DRIVING", 4: "activity", 5: "restaurant", 6: "BICYCLING",
          7: "WALKING", 8: "Beach", 9: "Hiking", 10: "Biking", 11: "Golf", 12: "Thrill Rides", 13: "Shopping",
          14: "Spa", 15: "Boating", 16: "Sailing", 17: "Surfing", 18: "Kayak", 19: "Canoe", 20: "Scuba",
          21: "Snorkel", 22: "Water Scooter", 23: "Club", 24: "Concert", 25: "Museum", 26: "Parasailing"};*/



    this.state = {
      travelerid: props.searchResult.travelerid,
      tripid: props.searchResult.tripid,
      fromdestinationid: props.searchResult.fromdestinationid?props.searchResult.fromdestinationid:8,
      todestinationid: props.searchResult.todestinationid,
      fromdate: props.searchResult.fromdate,
      todate: props.searchResult.todate,
      fromdestinationname: props.searchResult.fromdestinationname,
      todestinationname: props.searchResult.todestinationname?props.searchResult.todestinationname:null,
      activities: props.searchResult.activities?props.searchResult.activities:[],
      tripname: props.searchResult.tripname?props.searchResult.tripname:"",
      startDate: props.searchResult.fromdate?new Date(props.searchResult.fromdate):new Date(),
      endDate: props.searchResult.todate? new Date(props.searchResult.todate):new Date(),
      options: [],
      tripactivitytypes: {},
      fromlocationid: "",
      options1: [],
      activityStartDate: "",
      activityEndDate: "",
      activityDateRange: "",
      options2: [],
      inputValue: null,
      inputValue1: null,
      inputValue2: null,
      open: true,
      dateRange: {},
      displayDateRange: "",
      anchorEl: null,
      open2: false
    }
    this.state.dateRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      key: 'selection',
      color: '#00bbd3',
    }
    this.state.displayDateRange = moment(this.state.startDate).format("YYYY-MM-DD")+" - "+moment(this.state.endDate).format("YYYY-MM-DD");
    console.log("traveler id "+this.state.travelerid);
    console.log("trip id "+this.state.tripid);

    console.log(typeof window);
    console.log(!this.state.loaded);
    if (typeof window !== 'undefined' && !this.state.loaded) {
      console.log("Inside window undefined and state not loaded");
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places',
          document.querySelector('head'),
          'google-maps',
        );
      }
      this.state.loaded = true;
    }
    console.log(!autocompleteService.current);
    var thisvar = this;

    const googleScript = document.getElementById('google-maps')
    googleScript.addEventListener('load', () => {
      console.log("google maps loaded");
      console.log(window.google);
      console.log("navigator"+navigator.geolocation)
      var geocoder = null;
      var fromdestinationid = 8;
      console.log(navigator.geolocation);
      if(navigator.geolocation){
        console.log("Inside navigator.geolocation")
        navigator.geolocation.getCurrentPosition(function(position){
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          console.log(" current location"+pos.lat +" "+pos.lng);
          console.log("window google"+window.google);

          if(window.google){
            console.log("inside window google");
            geocoder = new window.google.maps.Geocoder();

            if(geocoder != null) {
              geocoder.geocode({location: pos}, (results, status) => {
                if (status == "OK"){
                  console.log(results);
                  for(let i = 0; i < results.length; i++){
                    if(results[i].types[0] == "locality" && results[i].types[1] == "political"){
                      console.log(results[i].formatted_address)
                      fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
                              { method:'POST',
                                headers:{
                                 "Content-Type": "application/json"
                               },
                                body:JSON.stringify({name: results[i].formatted_address

                                })
                            })
                            .then(res => res.json())
                            .then(data => {
                              console.log(data);
                              fromdestinationid = data;
                              this.setState({
                                fromdestinationid: fromdestinationid
                              })
                              //fromdestinationname= results[i].formatted_address
                            })
                    }//end if for locality and political
                  }//end of for loop

                }//status ok
                else {
                  console.log("Cannot reverse Geo Code current location");
                }
              })//end of geocoder geo code
            }//end of geocoder null check
          }
        }, function(e){
          console.log("Error with maps");
          console.log(e);
        }, {timeout:10000});
      } else {
        console.log("location tracking not allowed");
      }
      console.log(fromdestinationid);
      thisvar.setState({
        fromdestinationid: fromdestinationid,
      })
    })



    //get trip activity DataTypes
    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivitytypes/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             }
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);

            if(data){
              tripactivitytypevalues = data.reduce(function(acc, cur, i) {
                acc[cur.id] = cur.name;
                return acc
              },{});
            }
            console.log(tripactivitytypevalues);
            this.setState({tripactivitytypes: tripactivitytypevalues});
          })

  }



  handleloginsuccess(travelerid){
      this.state.travelerid = travelerid
  }

 handleChangeTripName(event){
   console.log("Handle tripname change");
   console.log(event.target.value);
   this.setState({"tripname": event.target.value,
                  "errorTripName": false,
                  "tripNameErrorText": ""});
 }

 handleChangeDestinationFrom(selectedOption){
    console.log(`Option selected in search criteria:`, selectedOption.value);
    //this.setState({"destinationFrom": selectedOption.value})
 }
 handleChangeDestinationTo(selectedOption){
    console.log(`Option selected in search criteria:`, selectedOption.value);
    //this.setState({"destinationTo": selectedOption.value})
 }

 handleChange(event){
   console.log("inside handle change");
   console.log(event.target.value);
   console.log(window.google);
   if (!autocompleteService.current && window.google) {
     console.log("autocomplete server current being populated");
     autocompleteService.current = new window.google.maps.places.AutocompleteService();
   }
   console.log(autocompleteService.current);
   this.setState({inputValue: event.target.value,
                   errorToDestination: false,
                   toDestinationHelperText: ""});
   autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                     {console.log("inside callback");
                                                      console.log(results);
                                                      this.setState({options: results})})


   }
   handleChange2(event){
     console.log("inside handle change");
     console.log(event.target.value);
     console.log(window.google);
     if (!autocompleteService.current && window.google) {
       console.log("autocomplete server current being populated");
       autocompleteService.current = new window.google.maps.places.AutocompleteService();
     }
     console.log(autocompleteService.current);
     this.setState({inputValue2: event.target.value});
     autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                       {console.log("inside callback");
                                                        console.log(results);
                                                        this.setState({options2: results})})
     //fetch1(event.target.value, (results) => {console.log("inside callback");
     //                                              this.setState({options: results})})

   }
   handleChange1(event){
     console.log("inside handle change");
     console.log(event.target.value);
     console.log(window.google);
     if (!autocompleteService.current && window.google) {
       console.log("autocomplete server current being populated");
       autocompleteService.current = new window.google.maps.places.AutocompleteService();
     }
     console.log(autocompleteService.current);
     this.setState({inputValue1: event.target.value});
     autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                       {console.log("inside callback");
                                                        console.log(results);
                                                        this.setState({options1: results})})
     //fetch1(event.target.value, (results) => {console.log("inside callback");
     //                                              this.setState({options: results})})

   }

 handleDestinationSelected(event, selectedOption2){
   if(selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2.description);
    console.log(`Option selected in automcomplete event:`, event.target);
    this.setState({"destinationTo": selectedOption2.description})
    fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({name: selectedOption2.description

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            this.setState({
              todestinationid: data,
              todestinationname: selectedOption2.description,
              errorToDestination: false,
              toDestinationHelperText: ""
            })
          })
      }
 }

 handleFromLocationSelected(event, selectedOption2){
   if(selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2.description);
    console.log(`Option selected in automcomplete event:`, event.target);

    fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({name: selectedOption2.description

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data[0].id);
            this.setState({
              fromlocationid: data[0].id,
              inputValue1: selectedOption2.description
            })
          })
      }
 }

 handleToLocationSelected(event, selectedOption2){
   if(selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2.description);
    console.log(`Option selected in automcomplete event:`, event.target);

    fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({name: selectedOption2.description

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            this.setState({
              tolocationid: data[0].id,
              inputValue2: selectedOption2.description
            })
          })
      }
 }

 handleChangeDateFrom(input){
   console.log(input)
   this.setState({
     startDate: input,
     errorStartDate: false,
     startDateError: ""
   });
   /*if(input != null) {
     var input1 = new Date(input.getFullYear(), input.getMonth(), input.getDate())
     console.log(input1)
     var a = moment(input1);
     console.log(a.format('YYYY-MM-DD'));
     this.setState({
       startDate: input
     });
   }
   else {
     this.setState({
       startDate: null
     });
   }*/
   //console.log("end date ",input["endDate"])

 }
 handleChangeDateTo(input){
   console.log(input)
   this.setState({
     endDate: input,
     errorEndDate: false,
     endDateErrorText: ""
   });

 }

 setActivityDateRange(selectionVal){
   console.log(selectionVal);
   this.setState({
     dateRange: {
       startDate: selectionVal.selection.startDate,
       endDate: selectionVal.selection.endDate,
       key: "selection",
       color: '#00bbd3'
     },
     displayDateRange: moment(selectionVal.selection.startDate).format("YYYY-MM-DD")+" - "+moment(selectionVal.selection.endDate).format("YYYY-MM-DD"),
     startDate: selectionVal.selection.startDate,
     endDate: selectionVal.selection.endDate
   })
 }

 handleDateRangeClick(event){
   console.log("Inside text click");
   if(this.state.anchorEl){
     this.setState({
       anchorEl: null
     })
   }
   else{
     this.setState({
       anchorEl: event.currentTarget
     })
   }

 }

  handleCreateNewTripActivity(){
   console.log("create new trip activity");
  }

  handleToggle(){
    this.state.open = !this.state.open
  }

 handleSubmit(event){
   console.log("Inside submit");

   event.preventDefault();
   const data = new FormData(event.target);
   console.log("form data"+data);
   console.log("travelerid "+this.state.travelerid);
   console.log("props traveler id "+this.props.searchResult.travelerid );
   console.log("trip id "+this.state.tripid);
   console.log("start date "+this.state.startDate);
   console.log("start date format "+moment(this.state.startDate).format());
   console.log("end date "+this.state.endDate);
   console.log("from destination id"+this.state.fromdestinationid);
   if(!this.props.searchResult.travelerid){
     this.setState({open2: true});
     return;
   }

   if(!this.props.searchResult.travelerid)
   {
     //alert ("Sign in to create Trip");
     return ;
   }
   if(!this.state.tripname){
     //alert ("Enter Trip Name");
    this.setState({tripNameErrorText: "Enter Trip Name",
                    errorTripName: true});
     return;
   }
   if(!this.state.startDate){
     //alert ("Enter Start Date");
     this.setState({dateRangeErrorText: "Enter Start Date",
                     errorDateRange: true});
      return;
   }
   if(!this.state.endDate){
     //alert ("Enter End Date");
     this.setState({dateRangeErrorText: "Enter End Date",
                     errorDateRange: true});
      return;
   }
   if(!this.state.todestinationid)
   {
     //alert ("Enter Destination");
     this.setState({ toDestinationErrorText: "Enter Destination",
                      errorToDestination: true})
     return;
   }
   var jsonActivities = [];

   console.log(jsonActivities);
   if(!this.state.tripid){
     console.log("Inside Create Trip");
     fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/create',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
               body:JSON.stringify({"travelerid": this.props.searchResult.travelerid,
                                    "tripname": this.state.tripname,
                                    "startdate": moment(this.state.startDate).format('YYYY-MM-DD'),
                                    "enddate": moment(this.state.endDate).format('YYYY-MM-DD'),
                                    "fromdestinationid": this.state.fromdestinationid,
                                    "todestinationid": this.state.todestinationid,
                                    "activities": jsonActivities

               })
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             const { dispatch } = this.props;
             dispatch(createTripDetail(data, this.props.searchResult.travelerid));
             dispatch(push('/mytrips'));
           })
           .catch(error => {console.log(error); return error})
   }
   else{
     console.log("Inside Update Trip");
     fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/update',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
               body:JSON.stringify({"travelerid": this.props.searchResult.travelerid,
                                    "tripname": this.state.tripname,
                                    "startdate": this.state.startDate,
                                    "enddate": this.state.endDate,
                                    "fromdestinationid": this.state.fromdestinationid,
                                    "todestinationid": this.state.todestinationid,
                                    "tripid": this.state.tripid

               })
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             const { dispatch } = this.props;
             dispatch(push('/mytrips'));
             dispatch(createTripDetail(this.state.tripid, this.props.searchResult.travelerid))
           })
           .catch(error => {console.log(error); return error})
   }

 }

 static getDerivedStateFromProps(nextProps, prevState){
   console.log("Inside derived state from props");
   console.log(nextProps);
   console.log(prevState);
   console.log(nextProps.searchResult.travelerid);
   if(nextProps.searchResult.tripid && !prevState.tripid){
     console.log("inside if of trip id present")
     return { tripid: nextProps.searchResult.tripid,
              tripname: nextProps.searchResult.tripname,
              todestinationid: nextProps.searchResult.todestinationid,
              startDate: new Date(nextProps.searchResult.fromdate),
              endDate: new Date(nextProps.searchResult.todate),
              todestinationname: nextProps.searchResult.todestinationname,
              dateRange: {
                startDate: new Date(nextProps.searchResult.fromdate),
                endDate: new Date(nextProps.searchResult.todate),
                key: 'selection',
                color: '#00bbd3',
              },
              displayDateRange: moment(new Date(nextProps.searchResult.fromdate)).format("YYYY-MM-DD")
                                +" - "
                                +moment(new Date(nextProps.searchResult.todate)).format("YYYY-MM-DD"),
              };
   }
   else if(nextProps.searchResult.travelerid && !prevState.travelerid){
     return {
       travelerid: nextProps.searchResult.travelerid
     }
   }
   else{
     return null;
   }
 }

 handleClose2() {
   this.setState({open2: false})
 }

  render() {


    return <form onSubmit={this.handleSubmit}>
            <div>
              <PageHeader topName="Sign In" dispatch={this.props.dispatch}>
              </PageHeader>
              <PageMenu dispatch={this.props.dispatch} menuSelectedValue="1">
              </PageMenu>
              <Grid container spacing={4} className="createtrippadding">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <TextField
                    id="tripName"
                    label="Name of Trip"
                    value={this.state.tripname}
                    onChange={this.handleChangeTripName}
                    helperText={this.state.tripNameErrorText}
                    error={this.state.errorTripName}
                    variant="standard"
                  />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <Autocomplete
                    id="google-map-demo"
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={this.state.options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onChange={this.handleDestinationSelected}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        fullWidth
                        onChange={this.handleChange}
                        error={this.state.errorToDestination}
                        helperText={this.state.toDestinationErrorText}
                        variant="standard"
                      />
                    )}
                    value ={this.state.todestinationname}
                    renderOption={(props, option) => {
                      const matches = option.structured_formatting.main_text_matched_substrings || [];
                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                      );
                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                  {part.text}
                                </span>
                              ))}
                              <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                  <TextField
                    id="dateRange"
                    multiple
                    label="From - To Dates"
                    value={this.state.displayDateRange}
                    helperText={this.state.dateRangeErrorText}
                    error={this.state.errorDateRange}
                    onClick={this.handleDateRangeClick}
                    variant="standard"
                  />
                    <Grid item xs={12} sm={6}>
                        <Popper id='transition-popper' open={Boolean(this.state.anchorEl)} anchorEl={this.state.anchorEl} placement="bottom-start" transition>
                        {({ TransitionProps }) => (
                          <Fade {...TransitionProps} timeout={350}>
                            <div className="date_size">
                              <DateRangePicker
                                ranges={[this.state.dateRange]}
                                onChange={this.setActivityDateRange}
                                showDateDisplay={false}
                                className="date_size"
                                staticRanges={[]}
                                inputRanges={[]}
                              />
                              </div>
                          </Fade>
                        )}
                      </Popper>
                    </Grid>
                  </FormControl>
                </Grid>

              </Grid>
              <Dialog open={this.state.open2} onClose={this.handleClose2} aria-labelledby="form-dialog-title">
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Please sign in to progress
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose2} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="searchcriteriamaterialgridnotop">
                <Grid container justify="center">
                  <button className="button">Save</button>
                </Grid>
              </div>
          </div>
          </form>
  }
}

const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);
  return state;
  /*if(state.searchResult.travelerid !== null && state.searchResult.travelerid !== 'undefined' && state.searchResult.travelerid){
    console.log("inside revaeler id");
    if(this && this !== null && this !== 'undefined')
      this.setState( {travelerid: state.searchResult.travelerid});
    else {
      return{travelerid: state.searchResult.travelerid};
    }
  }
  else
    return state;
  */



}

export default connect(mapStateToProps)(CreateTrip);
