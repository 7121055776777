import React, {Component} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {searchResult} from '../reducers';
import {connect} from 'react-redux';
import {getSearchResult, createTrip, editTrip} from '../action';
import PageSubMenu1 from './PageSubMenu1';
import PageHeaderData from './PageHeaderData';
import moment from 'moment';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {push} from 'react-router-redux';
import {getSearchResultRefined} from '../action';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PageHeader from './PageHeader';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import BoatIcon from '@mui/icons-material/DirectionsBoat';
import Rating from '@mui/material/Rating';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HotelIcon from '@mui/icons-material/Hotel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ToysIcon from '@mui/icons-material/Toys';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import BrushIcon from '@mui/icons-material/Brush';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardMedia from '@mui/material/CardMedia';
import default1 from '../img/default.jpg';
import '../styles/selectbelowmenu.css';
import Grid from '@mui/material/Grid';
import RowingIcon from '@mui/icons-material/Rowing';
import TagManager from 'react-gtm-module';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';


function compareActivities(activity1, activity2, destination1, destination2, duration1, duration2){
  if(destination1 && destination2){
    if(destination1 != destination2)
      return false;
  }//end of check for destination
  if(activity1 && activity2){
    if(activity1.length != activity2.length)
      return false;
    for(let i =0; i<activity1.length; i++){
      if (activity1[i] != activity2[i])
        return false;
    }
  }//end of activity compare
  if(duration1 && duration2){
    if(duration1 != duration2){
      return false
    }
  }//end of check for duration
  return true;
}

//convert destination to string to look for img file
function stringToDestination(input){
  var firstPart = input.substr(0, input.indexOf(','));
  console.log(firstPart);
  if(!firstPart){
    input = input.split(' ').join('');
    return input.toLowerCase();
  }
  var secondPart = firstPart.toLowerCase();
  console.log(secondPart);
  var thirdPart = secondPart.split(' ').join('');
  console.log(thirdPart);
  thirdPart = thirdPart.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  thirdPart = thirdPart.split('.').join("");
  return thirdPart;
}

function getIconForActivity(activity){
  var icon = null;
  switch(activity){
    case "Snorkel":
      icon = <PoolIcon/>;
      break;
    case "Flight":
      icon = <FlightIcon/>;
      break;
    case "Museum":
      icon = <MuseumIcon/>;
      break;
    case "Walking":
    case "Hiking":
      icon = <WalkIcon/>;
      break;
    case "Driving":
      icon = <DriveIcon/>;
      break;
    case "Bicycling":
    case "Biking":
    case "Hydrobiking":
      icon = <BicycleIcon/>;
      break;
    case "Restaurant":
      icon = <RestaurantIcon/>;
      break;
    case "Boating":
      icon = <BoatIcon/>;
      break;
    case "Swimming":
        icon = <PoolIcon/>;
        break;
    case "Iceskating":
    case "Skiing":
        icon = <AcUnitIcon/>;
        break;
    case "Hotel":
        icon = <HotelIcon/>;
        break;
    case "Concert":
        icon = <MusicNoteIcon/>;
        break;
    case "Thrillrides":
        icon = <ToysIcon/>;
        break;
    case "Golf":
    case "Minigolf":
        icon = <GolfCourseIcon/>;
        break;
    case "Artgalleries":
        icon = <BrushIcon/>;
        break;
    case "Kayak":
    case "Canoe":
        icon = <RowingIcon/>;
        break;
    default:
        icon = <BeachIcon/> ;
  }
  return icon;
}

const tagManagerArgs = {
    dataLayer: {
        userProject: 'itifind.com',
        page: 'searchresult',
        event: 'pageview'
    },
    dataLayerName: 'PageDataLayer'
}

class SearchResultTrip extends Component {
  constructor(props){
    super(props);
    this.handleCreateNewTrip = this.handleCreateNewTrip.bind(this);
    this.handleSearchDetail = this.handleSearchDetail.bind(this);
    this.handleCloneTrip = this.handleCloneTrip.bind(this);
    this.camelize = this.camelize.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleCompare = this.handleCompare.bind(this);
    this.encodeS3ImgBodyToData = this.encodeS3ImgBodyToData.bind(this);
    this.addImagesToList = this.addImagesToList.bind(this);
    this.state = {
      searchResultData: [],
      loading: true,
      searchCriteria: props.searchResult,
      searchCriteriaParsed: [],
      activitysteps: 1,
      destinationto: props.destinationto,
      travelerid: props.searchResult.travelerid,
      fromdestinationid: props.fromdestinationid?props.fromdestinationid:8,
      open: false,
      activityTypeNames: props.searchResult.activitytypenames,
      destinationTo: props.searchResult.destinationto,
      displayName: props.searchResult.displayName,
      tripTypeId: props.searchResult.tripTypeId,
      profileName: props.searchResult.profileName,
    };
    console.log("traveler id "+this.state.travelerid);
    console.log(this.state.searchCriteria);
    console.log(this.state.searchCriteria.destinationto);
    console.log(this.state.searchCriteria.searchCriteria);
    console.log(this.state.searchCriteria.duration);
    console.log(this.state.searchCriteria.tripTypeId);
    console.log(this.state.searchCriteria.profileName);
    /*if(this.state.searchCriteria && this.state.searchCriteria.searchCriteria){
      for(let i=0; i<this.state.searchCriteria.searchCriteria.length; i++){
        if(this.state.searchCriteria.searchCriteria[i].includes("/")){
          var parsed = this.state.searchCriteria.searchCriteria[i].split("/");
          for(let j=0; j < parsed.length; j++){
            this.state.searchCriteriaParsed.push(parsed[j].trim());
          }
        }
        else{
          this.state.searchCriteriaParsed.push(this.state.searchCriteria.searchCriteria[i]);
        }
      }
    }
    console.log(this.state.searchCriteriaParsed)*/
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getdistinctdestinations',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripactivitynames": this.state.searchCriteria.searchCriteria,
                                   "destinationto": this.state.searchCriteria.destinationto,
                                   "duration":this.state.searchCriteria.duration,
                                   "triptypeid": this.state.searchCriteria.tripTypeId,
                                   "profileName": this.state.searchCriteria.profileName,
                                   "fromdestinationid": 8,
              })
          })
          .then(res => res.json())
          .then(async data => {
            console.log(data);
            if(!data || data.length == 0){
              this.setState({
                noData: true
              })
              console.log("No Search Result");
              return;
            }

            var datamigrated = [];
            var dataitem = {};
            var newTripId = null;
            var newDestinationId = null;
            var newDestinationName = null;
            var newActivityTypes = [];
            var newDuration = null;
            var minDuration = null;
            var maxDuration = null;
            var startDate = null;

            //get unique trip experiences from search results
            var ijump=1;
            var j = 0;
            var requireAdd = null;
            if(data){
              AWS.config.credentials = new AWS.Credentials
              ({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
              })
              this.setState({
                s3client: new AWS.S3({region: "us-east-2"})
              })
              for (let i =0; i<data.length && j<data.length; i=ijump){
                for (j=i; j<data.length; j++){
                  console.log("value of i"+i);
                  if(i != j){
                    if(newTripId != data[j].tripid){
                      ijump = j;
                      console.log("inside if of finding new tripid"+ijump);
                      console.log(stringToDestination(newDestinationName)+".jpg");

                      dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                "destinationName": newDestinationName,
                                "activities": newActivityTypes,
                                "duration": newDuration,
                                "startDate": startDate,
                                //"img": "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body)
                              };

                      datamigrated.push(dataitem);
                      break;
                    }
                    else{
                      console.log("inside else of trip id being equal"+j);
                      newActivityTypes.push(data[j].tripactivitytypename);
                      if(j==(data.length-1)){
                        console.log("last element");
                        console.log(stringToDestination(newDestinationName)+".jpg");

                        dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                  "destinationName": newDestinationName,
                                  "activities": newActivityTypes,
                                  "duration": newDuration,
                                  "startDate": startDate,
                                  //"img": "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body)
                                };

                        datamigrated.push(dataitem);
                      }
                    }//end else
                  }//end if i != j
                  else {
                    console.log("inside else of i = j"+i);

                    newTripId = data[i].tripid;
                    newDestinationId = data[i].todestinationid;
                    newDestinationName = data[i].todestinationname;
                    newActivityTypes = [];
                    newActivityTypes.push(data[i].tripactivitytypename);
                    newDuration = data[i].duration;
                    startDate = data[i].startdate;
                    if(data.length == 1){
                      dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                "destinationName": newDestinationName,
                                "activities": newActivityTypes,
                                "duration": newDuration,
                                "startDate": startDate,
                              //  "img": "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body)
                              };
                      datamigrated.push(dataitem);
                    }//end if data length == 1
                  }// end else
                }//inner for loop j
              }//for loop i
            }//if data
            console.log(datamigrated);
            var datamigratedFinal = [];
            var indexesToDel = [];
            if(datamigrated.length == 1){
              datamigrated[0].minDuration = datamigrated[0].duration;
              datamigrated[0].maxDuration = datamigrated[0].duration;
            }
            for(let k=0; k<datamigrated.length; k++){
              minDuration = datamigrated[k].duration;
              maxDuration = datamigrated[k].duration;
              for(let l=k+1; l< datamigrated.length; l++){
                if(compareActivities(datamigrated[k].activities, datamigrated[l].activities,
                  datamigrated[k].destinationId, datamigrated[l].destinationId, datamigrated[l].duration, datamigrated[k].duration)){
                  if(!indexesToDel.includes(l)){
                    console.log(" index to delete"+l);
                    if(datamigrated[l].duration > maxDuration)
                      maxDuration = datamigrated[l].duration;
                    if(datamigrated[l].duration < minDuration)
                      minDuration = datamigrated[l].duration;
                    indexesToDel.push(l);
                  }
                }
              }
              datamigrated[k].minDuration = minDuration;
              datamigrated[k].maxDuration = maxDuration;
            }
            console.log(indexesToDel);
            //sorting is required before splicing
            indexesToDel.sort((a,b)=>a-b);
            //splice
            for (var m=indexesToDel.length-1; m>=0; m--){
              console.log("splice");
              datamigrated.splice(indexesToDel[m], 1);
            }

            //sort by latest
            console.log(datamigrated)
            datamigrated.sort((a,b)=>new Date(b.startDate).getTime()-new Date(a.startDate).getTime());
            console.log(datamigrated)

            this.setState({
              searchResultData: datamigrated,
              loading: false

            });
            console.log(this.state.searchResultData);
            //TagManager.dataLayer(tagManagerArgs);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             event: 'pageview'
            });
            this.addImagesToList();
          })
          .catch(error => {console.log(error); return error});

  }

  async addImagesToList(){
    var searchResultData = this.state.searchResultData;
    AWS.config.credentials = new AWS.Credentials
    ({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
    this.setState({
      s3client: new AWS.S3({region: "us-east-2"})
    })
    var imageName;
    var requireAdd;
    searchResultData.forEach(async searchResultDataItem => {

      imageName = stringToDestination(searchResultDataItem.destinationName);
      try{
        requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                    Key: imageName+"_sm.jpg"}).promise();

      }
      catch(ex){
        console.log("inside no image found catch");
        requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                    Key: "default_sm.jpg"}).promise();

      }
      searchResultDataItem.img = "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body);
      this.setState({
        searchResultData: searchResultData,
      });
    }, searchResultData)
    console.log(searchResultData);


  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    if(nextProps.searchResult.travelerid){
      return {travelerid: nextProps.searchResult.travelerid,
              displayName: nextProps.searchResult.displayName};
    }
    else {
      return null;
    }
  }

  handleloginsuccess(travelerid){
      this.state.travelerid = travelerid
  }

  handlelisttextclick(destinationTo, tripTypeId, searchCriteria){
     console.log(`Option selected in search result:`, destinationTo);
     console.log(`Option selected in search result:`, tripTypeId);
     console.log(`Option selected in search result:`, searchCriteria);
     searchCriteria.tripTypeId = tripTypeId;
     searchCriteria.destinationTo = destinationTo;
     const { dispatch } = this.props;
     dispatch(push('/tripswithoutgrouping'));
     dispatch(getSearchResultRefined(searchCriteria));

  }

  handleClick(id, fromdestinationid, todestinationid, activities){
    console.log("Handle click");
    console.log(id);
    console.log(activities);
    console.log(fromdestinationid);
    console.log(todestinationid);
    const { dispatch } = this.props;
    dispatch(push('/createtrip'));
    dispatch(createTrip(id, fromdestinationid, todestinationid, activities));
  }

  handleCreateNewTrip(){
    console.log("New Trip");
    const { dispatch } = this.props;
    dispatch(push('/createtrip'));
    dispatch(createTrip());
  }

  handleSearchDetail(activityItem){
    console.log("Handle Search Detail");
    console.log(`Option selected in search result:`, activityItem);

    var searchCriteria = {};
    searchCriteria.tripactivitytypenames = activityItem.activities;
    searchCriteria.destinationto = activityItem.destinationId;
    searchCriteria.duration = activityItem.duration;

    const { dispatch } = this.props;
    dispatch(push('/tripswithoutgrouping'));
    dispatch(getSearchResultRefined(searchCriteria));
  }

  handleCloneTrip(activityItem){
    console.log("Handle clone trip "+activityItem.tripId);
    console.log("traveler id "+this.state.travelerid);
    console.log("destination id "+this.state.fromdestinationid);
    if(!this.state.travelerid){
      this.setState({open: true});
      return;
    }
    console.log(activityItem);

    //pass trip Id, fromdestination id, traveler id

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/clonetrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
                                   "tripid": activityItem.tripId,
                                   "fromdestinationid": this.state.fromdestinationid
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/createtrip'));
            dispatch(editTrip(data.tripid, data.tripname, this.state.travelerid, this.state.fromdestinationid,
              data.todestinationid, new Date(data.starttime), new Date(data.endtime), activityItem.destinationName));
          })
          .catch(error => {console.log(error); return error})

    /*const data = activityItem;
    console.log("form data"+data);
    console.log("travelerid "+this.state.travelerid);
    console.log("props traveler id "+this.props.searchResult.travelerid );
    var jsonActivities = [];
    for(let i=0; i< this.state.activities.length; i++){
      jsonActivities.push({
        "name": this.state.activities[i].name,
        "tripactivitytypeid": this.state.activities[i].tripactivitytypeid,
        "fromlocationid": this.state.activities[i].fromlocationid,
        "tolocationid": this.state.activities[i].tolocationid,
        "activitystartdate": this.state.activities[i].activityStartDate?moment(this.state.activities[i].activityStartDate).format("YYYY-MM-DDTHH:MM:SS"):"",
        "activityenddate": this.state.activities[i].activityEndDate?moment(this.state.activities[i].activityEndDate).format("YYYY-MM-DDTHH:MM:SS"):""
      })
    }
    console.log(jsonActivities);
    fetch('https://'+process.env.REACT_APP_HOSTNAME+':8080/api/trips/createtripandactivities',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.props.searchResult.travelerid,
                                   "tripname": this.state.tripname,
                                   "startdate": this.state.startDate,
                                   "enddate": this.state.endDate,
                                   "fromdestinationid": this.state.fromdestinationid,
                                   "todestinationid": this.state.todestinationid,
                                   "activities": jsonActivities

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/mytrips'));
          })
          .catch(error => {console.log(error); return error})*/

  }

  handleCompare(activityItem){
    console.log("Handle compare trip "+activityItem.tripId);

    //if this is a second compare, then compare, else store it in state
    if(this.state.itemToCompare.length == 0){
      console.log("inside if of compare")
      this.setState({
        itemToCompare: activityItem
      })
    }//end if (true)
    else {
      console.log("Inside else of compare")
      //dispatch to new screen
    }
  }

  handleClose() {
    this.setState({open: false})
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }

  encodeS3ImgBodyToData(data){
    /*var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');*/
    let buf = Buffer.from(data);
    let base64 = buf.toString('base64');
    return base64
  }

  render(){
    //var items = this.state.searchResultData;
    return (
           <div>
             <PageHeader topName="Sign In" dispatch={this.props.dispatch}
                    travelerid={this.state.travelerid} displayName={this.state.displayName}>
             </PageHeader>
             <PageSubMenu1 menuSelectedValue="1" activitytypenames={this.state.activityTypeNames} destinationto={this.state.destinationTo}
                                                  duration={this.state.duration} tripTypeId={this.state.tripTypeId}/>/>
             <div className="searchcriteriamaterialgrid3">
               <Card variant="outlined">
                   <CardContent className="cardcontent" style={{paddingBottom: "1px", paddingTop: "1px"}}>

                    </CardContent>
                    <CardActions>
                      <Button id ="createNewTripOnResult" variant="outlined" style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={this.handleCreateNewTrip}>Create New Trip</Button>
                    </CardActions>
                </Card>
              </div>
              <div className="searchcriteriamaterialgrid2">
               {(this.state.searchResultData)?this.state.searchResultData.map(activityItem =>
                 <Card key={activityItem.tripId} variant="outlined" style={{marginBottom:"10px", overflow:"auto"}}>
                   <Grid container className="contentdisplay" alignItems="center">
                     <Grid item xs={9}>
                       <CardContent>
                         <Typography variant="body1" component="h2">
                           {activityItem.destinationName}
                         </Typography>
                         <Typography variant="body2" color="textSecondary">
                           {(activityItem.duration ==1)? "1 day" : activityItem.duration +" days"}
                         </Typography>
                         {activityItem.activities.map(activityName =>
                           <Chip key={activityName} size="small" label={this.camelize(activityName)} icon={getIconForActivity(this.camelize(activityName))}/>
                         )}
                       </CardContent>
                     <CardActions style={{paddingTop: "0px", paddingBottom: "1px"}}>
                       <Button id="getRefinedResult" style={{color: "rgba(0,150,166,1.0)"}} size="small"  onClick={() => this.handleSearchDetail(activityItem)}>
                         More..
                       </Button>
                       <Button id="cloneTrip" style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={() => this.handleCloneTrip(activityItem)} >
                         Clone Trip
                       </Button>
                     </CardActions>
                     </Grid>
                     <Grid item xs={3}>
                        <CardMedia src={activityItem.img} component="img" title="test1" style={{width:"128px", height:"128px"}}/>
                     </Grid>
                   </Grid>
                 </Card>
               ):"Sorry, No Results Found"}
              </div>
              <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Please sign in to progress
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="searchcriteriamaterialgrid2">
              {(this.state.noData)?<Typography variant="body1" component="h2" style={{color:"rgba(0,150,166,1.0)"}}>
                Sorry, No Data Found
              </Typography>:""}
              </div>
           </div>
         )

  }
}
const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);


  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
  return state;
}


const mapDispatchToProps = dispatch => {
  console.log("inside dispatch of search result");
  dispatch(getSearchResult());
}


export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(SearchResultTrip);
