import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import BikeIconImg from '../icons/baseline_directions_bike_black_18dp.png';



class BikeIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={BikeIconImg} />
          </div>
  }
}

export default connect()(BikeIcon);
