import React, {Component} from "react";
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import {getPopularTrips, getSearchResultTrip} from '../action';


class PageSubMenu1 extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.state = {

      selectedValue: parseInt(props.menuSelectedValue)
    }
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      destinationTo: props.destinationto,
      travelerId: props.searchResult?props.searchResult.travelerid:null,
      displayName: props.searchResult?props.searchResult.displayName:null,
      searchCriteria: props.searchResult,
      activityTypeNames : props.activitytypenames,
      duration: props.duration,
      tripTypeId: props.tripTypeId,
    }
  }

  handleChange(event, newValue){
    event.preventDefault();
    console.log('handle change page menu');
    console.log(newValue);
    console.log(event);
    console.log(this.state.activityTypeNames);
    console.log(this.state.destinationTo);
    console.log(this.state.duration);
    if(newValue === 0){
      this.setState({
        selectedValue: 0
      })
      const { dispatch } = this.props;
      dispatch(push('/populartrips'));
      dispatch(getPopularTrips(this.props.searchResult.travelerid, this.props.searchResult.displayName,
        this.state.activityTypeNames, this.state.destinationTo, this.state.duration, this.state.tripTypeId));
    }
    else if(newValue === 1){
      this.setState({
        selectedValue: 1
      })
      const { dispatch } = this.props;
      //dispatch(push('/'));
      dispatch(push('/tripsandactivities'));
      dispatch(getSearchResultTrip(this.state.activityTypeNames, this.state.destinationTo,
        this.state.duration, this.state.tripTypeId));
    }

  }
  render(){
    /*return <div className="menubar">
              <div className="menutext1">{this.props.menuItem1}</div>
              <div className="menutext2">{this.props.menuItem2}</div>
              <div className="menutext3">{this.props.menuItem3}</div>
           </div>
*/
      return <div className="menubar">
              <AppBar style={{background: '#00bbd3'}}>
                <Tabs value={this.state.selectedValue?this.state.selectedValue:0} onChange={this.handleChange}>
                  <Tab label="Popular"  />
                  <Tab label="Latest"  />

                </Tabs>
              </AppBar>
            </div>

  }
}

const mapStateToProps = state => {
  console.log(state);
  return state;
}
export default connect(
  mapStateToProps
)(PageSubMenu1)
