import React, {Component} from "react";
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {push} from 'react-router-redux';
import {connect} from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import moment from 'moment';
import Link from '@mui/material/Link';
import {createTripDetail} from '../action';
import Toolbar from '@mui/material/Toolbar';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  //script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
  console.log("load script");
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function camelize(str) {
  if(str){
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }
  else{
    return str;
  }
}

const autocompleteService = { current: null };

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
};

class PageMenu extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleSaveTripActivity = this.handleSaveTripActivity.bind(this);
    this.handleChangeTripSelect = this.handleChangeTripSelect.bind(this);
    this.handleCloseNewTrip = this.handleCloseNewTrip.bind(this);
    this.handleEndTime = this.handleEndTime.bind(this);
    this.handleTripActivityTypeChange = this.handleTripActivityTypeChange.bind(this);
    this.handleToLocationSelected = this.handleToLocationSelected.bind(this);
    this.handleActivityName = this.handleActivityName.bind(this);
    this.state = {

      selectedValue: parseInt(props.menuSelectedValue),
      locationServiceEnabled: true,
      tripactivitytypes: [],
      open: false,
      options2: [],
      travelerid: this.props.searchResult.travelerid,
      signedIn: false,
      tripList: [],
      tripId: null,
      newTrip: false,
      endtime: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
      activitytype: null,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleCloseSignedIn = this.handleCloseSignedIn.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleChange(event, newValue){
    event.preventDefault();
    console.log('handle change page menu');
    console.log(newValue);
    this.setState({
      selectedValue: newValue
    })
    if(newValue === 2){
      const { dispatch } = this.props;
      dispatch(push('/mytrips'));
    //  dispatch(myTrips("1", ))
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
       event: 'pageview',
       pageName: 'my trips'
      });
    }
    if(newValue === 1){
      const { dispatch } = this.props;
      dispatch(push('/createtrip'));
    //  dispatch(myTrips("1", ))
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
       event: 'pageview',
       pageName: 'Create New Trip'
      });
    }
    if(newValue === 0){
      const { dispatch } = this.props;
      dispatch(push('/'));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
       event: 'pageview',
       pageName: 'home'
      });
    }
    if(newValue === 3){
      const { dispatch } = this.props;
      dispatch(push('/memories'));
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
       event: 'pageview',
       pageName: 'memories'
      });
    }
    if(newValue === 4){
      //if not signed in, open Dialog
      if(!this.state.travelerid){
        this.setState({
          signedIn: true,
          open: false
        })
        console.log("no traveler id")
        return
      }

      //get trip list of applicable trips
      //get all trips with current time as input
      fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getmytripsforcheckin',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
               body:JSON.stringify({"travelerId": this.state.travelerid,
                                    "checkindate": moment(new Date()).format('YYYY-MM-DD')
                                  })
      })
      .then(res => res.json())
      .then(data => {
        if(!data || data.length === 0){
          this.setState({
            open:false,
            newTrip: true
          })
        }
        if(data && data.length > 0){
          this.setState({
            tripList: data
          })
        }
        console.log(data);
        //get latest trip for default load
        if(data && data.length > 0){
           var defaultId = data[0].id;
           console.log("default id"+defaultId);
           this.setState({
             tripId: defaultId
           })
        }
      })

      //get location
      console.log(typeof window);
      if (typeof window !== 'undefined' && !this.state.loaded) {
        console.log("Inside window defined and state not loaded");
        var googlemapscript = document.querySelector('#google-maps')
        if (!document.querySelector('#google-maps')) {
          loadScript(
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places',
            document.querySelector('head'),
            'google-maps',
          );
        }
        this.state.loaded = true;
      }

      //const googleScript = document.getElementById('google-maps')
      //googleScript.addEventListener('load', () => {
      console.log("google maps loaded");
      console.log(window.google);
      console.log("navigator"+navigator.geolocation)
      var geocoder = null;

      if(navigator.geolocation && navigator.permissions && navigator.permissions.query({name: "geolocation"})){

        navigator.permissions.query({name: "geolocation"})
        .then((result) => {
          console.log(result)
          if(result.state === "granted" || result.state === "prompt"){
            console.log("inside navigator geo location present")
            navigator.geolocation.getCurrentPosition(function(position){
              var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              };
              console.log(" current location"+pos.lat +" "+pos.lng);
              console.log("window google"+window.google);

              if(window.google){
                console.log("inside window google");
                geocoder = new window.google.maps.Geocoder();
                if(geocoder != null) {
                  geocoder.geocode({location: pos}, (results, status) => {
                    if (status == "OK"){
                      console.log(results);
                      /*for(let i = 0; i < results.length; i++){
                        if(results[i].types[0] == "locality" && results[i].types[1] == "political"){
                          console.log(results[i].formatted_address)
                          fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
                                  { method:'POST',
                                    headers:{
                                     "Content-Type": "application/json"
                                   },
                                    body:JSON.stringify({name: results[i].formatted_address

                                    })
                                })
                                .then(res => res.json())
                                .then(data => {
                                  console.log(data);
                                  todestinationid = data;

                                  //todestinationname= results[i].formatted_address
                                })
                        }
                      }*/ //end of for loop
                    }//status ok
                  })
                }//geo coder not null
                else {
                  console.log("Cannot reverse Geo Code current location");
                }
              }
            }, function(){
              console.log("Error with maps");
            });
          }//end granted
          else if (result.state === "denied"){
            console.log("location tracking not allowed");
            this.setState({
              locationServiceEnabled: false
            })

          }
        })
      }
      else{
        console.log("Enable location sharing on the device to be able to checkin");
        this.setState({
          locationServiceEnabled: false
        })
      }

      //trip activity types for drop down
      fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivitytypes/findAll',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               }
            })
            .then(res => res.json())
            .then(data => {
              console.log(data);

              this.setState({tripactivitytypes: data});
            })



      this.setState({
              open:true
            })
      //get current time


        //console.log(fromdestinationid);

      //})
      //get point of interest for location
      //get date time
      //get activity type
      //get trips applicable
      //then open modal
      //save
      //on saving modal, go to view screen for the chosen trip id
      //on cancel close dialog
    }//end value 4

  }

  handleChange2(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue2: event.target.value,
                    errorToLocationId: false,
                    toLocationIdErrorText: ""});
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options2: results})})
    //fetch1(event.target.value, (results) => {console.log("inside callback");
    //                                              this.setState({options: results})})

  }

  handleSaveTripActivity(){
    //find trip
    console.log("handle save trip activity");
    console.log(this.state.tripActivityName);
    console.log(this.state.fromlocationid);
    console.log(this.state.tolocationid);
    console.log(this.state.tripId);
    console.log(this.state.activitytype);
    console.log(this.state.edit);
    console.log(this.state.endtime);

    if(!this.state.activitytype){
      this.setState({
        errorActivityType: true,
        activityTypeErrorText: "Enter Trip Activity Type"
      });
      return;
    }
    if(!this.state.tolocationid){
      this.setState({
        errorToLocationId: true,
        toLocationIdErrorText: "Enter To Destination"
      });
      return;
    }
    if(!this.state.endtime){
      this.setState({
        errorEndTime: true,
        endTimeErrorText: "Enter Arrived At"
      });
      return;
    }

    //search for trip qctivity with same location in trip and get trip id to update
    //getupdatetripactivitytocheckin
    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/getupdatetripactivitytocheckin',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({name: this.state.tripActivityName,
                                   tripid: this.state.tripId,
                                   fromlocationid: this.state.fromlocationid,
                                   todestinationid: this.state.tolocationid,
                                   tripactivitytypeid: this.state.activitytype,
                                   endtime: this.state.endtime
              })
    })
    .then(res => res.json())
    .then(data => {
          console.log(data);
          const { dispatch } = this.props;
          dispatch(createTripDetail(data.tripsId, this.props.searchResult.travelerid));
          dispatch(push('/mytrips'));
    })
    .catch(error => {console.log(error); return error})




  }

  handleCloseSignedIn(){
    this.setState({
      signedIn: false
    })
  }

  handleClose() {
    this.setState({open: false})
  }
  handleCloseNewTrip(){
    this.setState({newTrip: false})
  }
  handleChangeTripSelect(event){
    console.log(event.target.value);
    this.setState({
      tripId: event.target.value
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    console.log(prevState.travelerid);
    var retData = [];
    if(nextProps.searchResult.travelerid && !prevState.travelerid){
        return {travelerid: nextProps.searchResult.travelerid,
                };
    }
    else {
      return null;
    }
  }

  handleEndTime(event){
      console.log(event.target.value);
      this.setState({
        endtime: event.target.value,
        errorEndTime: false,
        endTimeErrorText: ""
      })
  }

  handleTripActivityTypeChange(event){
    console.log("trip activity type change"+event.target.value);
    this.setState({
      activitytype: event.target.value,
      errorActivityType: false,
      activityTypeErrorText: ""
    })
  }

  //same as mytrips's handleToLocationSelected
  handleToLocationSelected(event, selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2);
    console.log(`Option selected in automcomplete event:`, event.target);
    if(selectedOption2){
     console.log('get types '+selectedOption2.types);
     console.log(`Option selected in automcomplete value:`, selectedOption2.description);
     console.log(`Option selected in automcomplete event:`, event.target);

     //setting destination types & activity types
     var element;
     var foundActivityType = false;
     var destType = "";
     for(let i = 0; i< selectedOption2.types.length; i++){
       element = selectedOption2.types[i];
       if(element == "country"){
         destType = "Country";
         break;
       }
       if(element == "administrative_area_level_1"){
         destType = "State";
         break;
       }
       if(element == "lodging"){
         //set activity type as Hotel
         this.setState({
           activitytype: 2
         });
         destType="Hotel";
         foundActivityType=true;
         break
       }
       if(element == "airport"){
         this.setState({
           activitytype: 1
         });
         destType="Airport";
         foundActivityType=true;
         break;
       }
       if(element == "natural_feature"){
         this.setState({
           activitytype: 9
         });
         destType="Nature";
         foundActivityType=true;
         break;
       }

       if(element == "amusement_park"){
         this.setState({
           activitytype: 12
         });
         destType="Amusement Park";
         foundActivityType=true;
         break;
       }
       if(element == "museum"){
         this.setState({
           activitytype: 25
         });
         destType="Museum";
         foundActivityType=true;
         break;
       }
       if(element == "night_club"){
         destType="Night Club";
         this.setState({
           activitytype: 23
         });
         break;
       }
       if(element == "restaurant"){
         this.setState({
           activitytype: 5
         });
         destType="Restaurant";
         foundActivityType=true;
         break;
       }
       if(element == "town_square" || element == "neighborhood"){
         destType="Point of Interest";
         break;
       }
       if(element == "sublocality"){
         destType="Point of Interest";
         break;
       }
       if(element == "tourist_attraction" || element == "colloquial_area"){
         destType="Point of Interest";
         break;
       }
       if(element == 'administrative_area_level_1'){
         destType="State";
         break;
       }
       if(element == "locality"){
         destType = "City";
         break;
       }
       if(element == "park"){
         destType = "Park";
         break;
       }
       if(element == "bar"){
         destType = "Bar";
         this.setState({
           activitytype: 52
         });
         break;
       }
       if(element == "travel_agency"){
         destType = "Tour";
         break;
       }
       if(element == "route"){
         destType = "Point of Interest";
         break;
       }
       if(element == "colloquial_area"){
         destType = "Point of Interest";
         break;
       }
       if(element == "bakery"){
         destType = "Bakery";
         break;
       }
       if(element == "store"){
         destType = "Store";
         break;
       }

   }
   if(!foundActivityType){
     if(selectedOption2.description.includes("Beach")){
       this.setState({
         activitytype: 8
       });
       destType="Beach";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Golf")){
       this.setState({
         activitytype: 11
       });
       destType="Golf Course";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Vineyard")){
       this.setState({
         activitytype: 28
       });
       destType="Vineyard";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Winery")){
       this.setState({
         activitytype: 29
       });
       destType="Winery";
       foundActivityType=true;
     }
   }//end of if not found activity type
   if(!foundActivityType){
     this.setState({
       activitytype: 27
     });
     foundActivityType=true;
   } //setting default activity type



   fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
         { method:'POST',
           headers:{
            "Content-Type": "application/json"
          },
          body:JSON.stringify({name: selectedOption2.description,
                               desttype: destType
          })
       })
       .then(res => res.json())
       .then(data => {
         console.log(data);
         this.setState({
           tolocationid: data,
           inputValue2: selectedOption2.description,
           errorToLocationId: false,
           toLocationIdErrorText: ""
         })
       })
    }//selected option 2
  }

  handleActivityName(event) {
    console.log(event.target.value);
    this.setState({tripActivityName: event.target.value});

    console.log(this.state.tripActivityName);

    //this.setState({open: false})
  }

  render(){
    /*return <div className="menubar">
              <div className="menutext1">{this.props.menuItem1}</div>
              <div className="menutext2">{this.props.menuItem2}</div>
              <div className="menutext3">{this.props.menuItem3}</div>
           </div>
*/
      return <div>
              <div className="menubar">
              <AppBar style={{background: '#00bbd3'}}>
                  <Tabs value={this.state.selectedValue} onChange={this.handleChange}
                        variant="fullWidth" centered textColor="white">
                    <Tab id="home" label="Explore" style={{minWidth:'35px', width:'35px', paddingLeft:'23px'}} />
                    <Tab id="createtrip" label="Create" style={{minWidth:'35px', width:'35px'}}/>
                    <Tab id="myTrips" label="View" style={{minWidth:'35px', width:'35px'}} />
                    <Tab id="sharedTrips" label="Shared" style={{minWidth:'35px', width:'35px'}}/>
                    <Tab id="checkin" icon={<CheckCircleOutlineIcon/>} style={{minWidth:'35px', width:'35px'}}/>
                  </Tabs>
              </AppBar>
            </div>
            <Snackbar open={!this.state.locationServiceEnabled}
                      autoHideDuration={6000}
                      onClose={() => this.setState({locationServiceEnabled: true})}
            >
              <Alert severity="error">Enable Location Service sharing to be able to checkin</Alert>
            </Snackbar>
            <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
              <DialogTitle id="form-dialog-title2">Trip Activity</DialogTitle>
              <DialogContent>
                <TextField style={{marginBottom: "10px", marginTop: "10px"}}
                  id="selectbox1"
                  select
                  label="Trip List"
                  value={this.state.tripId? this.state.tripId: ""}
                  onChange={this.handleChangeTripSelect}
                  fullWidth
                  >
                  {this.state.tripList.map(option2 => (
                      <MenuItem key={option2.id} value={option2.id}>
                        {option2.name}
                      </MenuItem>
                    ))}
                </TextField>
                <Autocomplete style={{marginBottom: "10px"}}
                  id="google-map-demo2"
                  autoFocus
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                  filterOptions={(x) => x}
                  options={this.state.options2}
                  autoComplete
                  includeInputInList
                  fullWidth
                  onChange={this.handleToLocationSelected}
                  onInputChange={this.handleInputChange2}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="To Location"
                      fullWidth
                      onChange={this.handleChange2}
                      error={this.state.errorToLocationId}
                      helperText={this.state.toLocationIdErrorText}
                    />
                  )}
                  value={this.state.inputValue2}
                  renderOption={(props, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || [];
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match) => [match.offset, match.offset + match.length]),
                    );
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                <TextField style={{marginBottom: "10px"}}
                  id="datetime-local"
                  label="Arrived At"
                  type="datetime-local"
                  value={this.state.endtime}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.handleEndTime}
                  fullWidth
                  error={this.state.errorEndTime}
                  helperText={this.state.endTimeErrorText}
                />
                <FormControl fullWidth={true} maxWidth={'md'} >
                  <InputLabel id="tripactivitytypes">Activity Type</InputLabel>
                  <Select
                    labelId="tripactivitytypes"
                    id="tripactivitytypes"
                    label="Activity Type"
                    value={this.state.activitytype}
                    onChange={this.handleTripActivityTypeChange}
                  >
                  {this.state.tripactivitytypes.map((name) => (
                    <MenuItem key={name.id} value={name.id} >
                      {camelize(name.name)}
                    </MenuItem>
                  ))}
                  </Select>
                  <FormHelperText>{this.state.activityTypeErrorText}</FormHelperText>
                </FormControl>
                <TextField
                  margin="dense"
                  id="tripActivityName"
                  label="Comments"
                  fullWidth
                  onChange={this.handleActivityName}
                  defaultValue={this.state.name}
                />
              </DialogContent>
              <DialogActions>
                <Button id="cancelsaveTripActivity" onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button id="saveTripActivity" onClick={this.handleSaveTripActivity} color="primary">
                  Save
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.signedIn} onClose={this.handleCloseSignedIn} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
              <DialogTitle id="form-dialog-title2">Not signed in</DialogTitle>
              <DialogContent>
                  Please sign in to check in
              </DialogContent>
              <DialogActions>
                <Button id="noSignIn" onClick={this.handleCloseSignedIn} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.newTrip} onClose={this.handleCloseNewTrip} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
              <DialogTitle id="form-dialog-title2">No active trips</DialogTitle>
              <DialogContent>
                  There are no active trips to check in to. <Link href="/createtrip">Create</Link> new trip.
              </DialogContent>
              <DialogActions>
                <Button id="noNewTrip" onClick={this.handleCloseNewTrip} color="primary">
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            </div>
  }
}
const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);
  return state;
  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
}
export default connect(mapStateToProps)(PageMenu)
