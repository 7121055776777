import React, { Component }  from 'react';
import '../styles/MenuBar.css';
import '../styles/MenuText.css';
import '../styles/selectbelowmenu.css'
import PageHeader from './PageHeader';
import PageMenu from './PageMenu';
import SelectMyTrip from './SelectMyTrip';
import {connect} from 'react-redux';
import {withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer, Marker, Polyline} from 'react-google-maps';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Grid from '@mui/material/Grid';
import DateTimeRangeContainer from 'react-advanced-datetimerange-picker';
import moment from 'moment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DateFnsUtils from "@date-io/date-fns";
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import InputLabel from '@mui/material/InputLabel';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import BoatIcon from '@mui/icons-material/DirectionsBoat';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HotelIcon from '@mui/icons-material/Hotel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ToysIcon from '@mui/icons-material/Toys';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import BrushIcon from '@mui/icons-material/Brush';
import RowingIcon from '@mui/icons-material/Rowing';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import {push} from 'react-router-redux';
import {uploadPhotos} from '../action';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
import Map from './Map';
import { DateRangePicker } from 'react-date-range';
import {FacebookShareButton, FacebookIcon, EmailShareButton, EmailIcon,
  TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from 'react-share';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import PeopleIcon from '@mui/icons-material/People';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Helmet from "react-helmet";
import Head from 'next/head';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormHelperText from '@mui/material/FormHelperText';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
//import sharp from 'sharp';


const { compose, withProps, lifecycle } = require("recompose");

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
  console.log("load script");
}

const autocompleteService = { current: null };

/*let now = new Date();
const defaultStartDate = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));
const defaultEndDate= moment(defaultStartDate).add(1, "days").subtract(1, "seconds");
const dateRanges = {
  "Today Only": [moment(defaultStartDate), moment(defaultEndDate)]
};
const dateRangeFormat = {
  "format": "MM-DD-YYYY HH:mm"
};*/

function getIconForActivity(activity){
  var icon = null;
  switch(activity){
    case "Snorkel":
    case "Swimming":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><PoolIcon/></Avatar>;
      break;
    case "Flight":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><FlightIcon/></Avatar>;
      break;
    case "Museum":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><MuseumIcon/></Avatar>;
      break;
    case "Walking":
    case "Hiking":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><WalkIcon/></Avatar>;
      break;
    case "Driving":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><DriveIcon/></Avatar>;
      break;
    case "Bicycling":
    case "Biking":
    case "Hydrobiking":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><BicycleIcon/></Avatar>;
      break;
    case "Restaurant":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><RestaurantIcon/></Avatar>;
      break;
    case "Boating":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><BoatIcon/></Avatar>;
      break;
    case "Iceskating":
    case "Skiing":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><AcUnitIcon/></Avatar>;
      break;
    case "Hotel":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><HotelIcon/></Avatar>;
      break;
    case "Concert":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><MusicNoteIcon/></Avatar>;
      break;
    case "Thrillrides":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><ToysIcon/></Avatar>;
      break;
    case "Golf":
    case "Minigolf":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><GolfCourseIcon/></Avatar>;
      break;
    case "Artgalleries":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><BrushIcon/></Avatar>;
      break;
    case "Kayak":
    case "Canoe":
      icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><RowingIcon/></Avatar>;
      break;
    case "Sightseeing":
      icon= <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><TravelExploreIcon/></Avatar>;
      break;
    default:
        icon = <Avatar style={{backgroundColor: "Orange", width: "30px", height:"30px", marginBottom: "2px"}}><BeachIcon/></Avatar> ;
  }
  return icon;
}

function camelize(str) {
  if(str){
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }
  else{
    return str;
  }
}

function twoDecimals(inp){
  var str = inp.toString();
  var decInd = str.indexOf(".");
  var outp = str.substring(0, decInd+2);
  console.log(outp);
  return outp;
}

function dataURItoBlob(dataURI) {
    var binary = atob(dataURI.split(',')[1]);
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
}

const MyMapComponent2 = compose(
  withProps({
    googleMapURL:"https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places",
    //googleMapURL:"https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `160px` }} />,
    mapElement: <div style={{ height: `100%` }} />,

  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({

    componentDidUpdate(prevProps, prevState){
      if(prevProps.origin != this.props.origin || prevProps.waypoints != this.props.waypoints){
        this.setState({
          directions: null,
          waypoints: [],
          directionsList: [],
        })
        const DirectionsService = new window.google.maps.DirectionsService();
        console.log(this.props.origin);
        console.log(this.props.destination);
        console.log(this.props.waypoints);
        console.log(this.props.id);
        var delayFactor = 0;
        var origin = this.props.origin;
        var destination = this.props.destination;
        var waypoints = this.props.waypoints;
        var directions = null;
        var polylinecoords = this.props.polylinecoords;
        var id = this.props.id;
        console.log(this.props.polylinecoords);

        DirectionsService.route({
            origin: this.props.origin,
            destination: this.props.destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
            waypoints: this.props.waypoints,
            provideRouteAlternatives: false,
          }, (result, status) => {
            if(status === window.google.maps.DirectionsStatus.OK){
              console.log("success in getting directions update");
              console.log(result.routes.legs);
              result.routes[0].legs.map(legVal => {
                for (let i = 0; i < polylinecoords.length-1; i++){
                  console.log("inside polylinecoords loop");
                  console.log(legVal);
                  console.log(legVal.start_location.lat());
                  console.log(legVal.start_location.lng());
                  console.log(polylinecoords[i].lat);
                  console.log(polylinecoords[i].lng);
                  console.log(typeof legVal.start_location.lat());
                  console.log(typeof polylinecoords[i].lat);
                  if((twoDecimals(legVal.start_location.lat()) == twoDecimals(polylinecoords[i].lat) &&
                      twoDecimals(legVal.start_location.lng()) == twoDecimals(polylinecoords[i].lng)) ||
                      (Math.round((legVal.start_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                      Math.round((legVal.start_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)){
                    console.log("start location match")
                    console.log(legVal.end_location.lat())
                    console.log(polylinecoords[i+1].lat)
                    console.log(legVal.end_location.lng())
                    console.log(polylinecoords[i+1].lng)
                    if((twoDecimals(legVal.end_location.lat()) == twoDecimals(polylinecoords[i+1].lat) &&
                        twoDecimals(legVal.end_location.lng()) == twoDecimals(polylinecoords[i+1].lng)) ||
                        (Math.round((legVal.end_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lat+Number.EPSILON)*100)/100 &&
                        Math.round((legVal.end_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lng+Number.EPSILON)*100)/100)){
                      console.log("inside nullify steps")
                      legVal.steps = [];
                    }//end location
                  }// start location
                }//for loop for polylinecoords
              })//leg loop
              this.setState({
                directions:result,
              });
            } else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
              delayFactor++;
              setTimeout(function () {
                //repeat function
                DirectionsService.route({
                    origin: origin,
                    destination: destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                    waypoints: waypoints,
                    provideRouteAlternatives: false,
                  }, (result, status) => {
                    if(status === window.google.maps.DirectionsStatus.OK){
                      console.log("success in getting directions update");
                      console.log(result);
                      result.routes[0].legs.map(legVal => {
                        for (let i = 0; i < polylinecoords.length-1; i++){
                          console.log("inside polylinecoords loop");
                          console.log(legVal);
                          console.log(legVal.start_location.lat());
                          console.log(legVal.start_location.lng());
                          console.log(polylinecoords[i].lat);
                          console.log(polylinecoords[i].lng);
                          if((twoDecimals(legVal.start_location.lat()) == twoDecimals(polylinecoords[i].lat) &&
                              twoDecimals(legVal.start_location.lng()) == twoDecimals(polylinecoords[i].lng)) ||
                              (Math.round((legVal.start_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                              Math.round((legVal.start_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)){
                            console.log("start location match")
                            console.log(legVal.end_location.lat())
                            console.log(polylinecoords[i+1].lat)
                            console.log(legVal.end_location.lng())
                            console.log(polylinecoords[i+1].lng)
                            if((twoDecimals(legVal.end_location.lat()) == twoDecimals(polylinecoords[i+1].lat) &&
                                twoDecimals(legVal.end_location.lng()) == twoDecimals(polylinecoords[i+1].lng)) ||
                                (Math.round((legVal.end_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lat+Number.EPSILON)*100)/100 &&
                                Math.round((legVal.end_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lng+Number.EPSILON)*100)/100)){
                              console.log("inside nullify steps")
                              legVal.steps = [];
                            }//end location
                          }// start location
                        }//for loop for polylinecoords
                      })//leg loop
                      this.setState({
                        directions: result,
                      });
                      directions = result;
                    }//status ok
                  })}.bind(this), delayFactor * 1000);
              } else {
              console.error("error fetching directions "+result);
            }
          })//directionsService route
          if(directions){
            this.setState({
              directions:directions,
            });
          }
      }//check if update required
    },
    componentDidMount(){
      const DirectionsService = new window.google.maps.DirectionsService();
      console.log(this.props.origin);
      console.log(this.props.destination);
      console.log(this.props.waypoints);
      console.log(this.props.id);
      var delayFactor = 0;
      var origin = this.props.origin;
      var destination = this.props.destination;
      var waypoints = this.props.waypoints;
      var directions = null;
      var polylinecoords = this.props.polylinecoords;
      console.log(this.props.polylinecoords);
      var newOrigin = {};
      var directionsList = null;
      var id = this.props.id;

      DirectionsService.route({
        origin: this.props.origin,
        destination: this.props.destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        waypoints: this.props.waypoints,
        provideRouteAlternatives: false,
      }, (result, status) => {
        if(status === window.google.maps.DirectionsStatus.OK){
          console.log("success in getting directions update");
          console.log(result.routes[0].legs);
          result.routes[0].legs.map(legVal => {
            for (let i = 0; i < polylinecoords.length-1; i++){
              console.log("inside polylinecoords loop");
              console.log(legVal);
              console.log(legVal.start_location.lat());
              console.log(legVal.start_location.lng());
              console.log(polylinecoords[i].lat);
              console.log(polylinecoords[i].lng);
              if((twoDecimals(legVal.start_location.lat()) == twoDecimals(polylinecoords[i].lat) &&
                  twoDecimals(legVal.start_location.lng()) == twoDecimals(polylinecoords[i].lng)) ||
                  (Math.round((legVal.start_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                  Math.round((legVal.start_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)){
                console.log("start location match")
                console.log(legVal.end_location.lat())
                console.log(polylinecoords[i+1].lat)
                console.log(legVal.end_location.lng())
                console.log(polylinecoords[i+1].lng)
                if((twoDecimals(legVal.end_location.lat()) == twoDecimals(polylinecoords[i+1].lat) &&
                    twoDecimals(legVal.end_location.lng()) == twoDecimals(polylinecoords[i+1].lng)) ||
                    (Math.round((legVal.end_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lat+Number.EPSILON)*100)/100 &&
                    Math.round((legVal.end_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lng+Number.EPSILON)*100)/100)){
                  console.log("inside nullify steps")
                  legVal.steps = [];
                }//end location
              }// start location
            }//for loop for polylinecoords
          })//leg loop
          console.log(result.routes)
          this.setState({
            directions: result,
          });
        } else if (status === window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT) {
          delayFactor++;
          setTimeout(function () {
            //repeat function
            DirectionsService.route({
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING,
                waypoints: waypoints,
                provideRouteAlternatives: false,
              }, (result, status) => {
                if(status === window.google.maps.DirectionsStatus.OK){
                  console.log("success in getting directions update");
                  console.log(result);
                  result.routes[0].legs.map(legVal => {
                    for (let i = 0; i < polylinecoords.length-1; i++){
                      console.log("inside polylinecoords loop");
                      console.log(legVal);
                      console.log(legVal.start_location.lat());
                      console.log(legVal.start_location.lng());
                      console.log(polylinecoords[i].lat);
                      console.log(polylinecoords[i].lng);
                      if((twoDecimals(legVal.start_location.lat()) == twoDecimals(polylinecoords[i].lat) &&
                          twoDecimals(legVal.start_location.lng()) == twoDecimals(polylinecoords[i].lng)) ||
                          (Math.round((legVal.start_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                          Math.round((legVal.start_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)){
                        console.log("start location match")
                        console.log(legVal.end_location.lat())
                        console.log(polylinecoords[i+1].lat)
                        console.log(legVal.end_location.lng())
                        console.log(polylinecoords[i+1].lng)
                        if((twoDecimals(legVal.end_location.lat()) == twoDecimals(polylinecoords[i+1].lat) &&
                            twoDecimals(legVal.end_location.lng()) == twoDecimals(polylinecoords[i+1].lng)) ||
                            (Math.round((legVal.end_location.lat()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lat+Number.EPSILON)*100)/100 &&
                            Math.round((legVal.end_location.lng()+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i+1].lng+Number.EPSILON)*100)/100)){
                          console.log("inside nullify steps")
                          legVal.steps = [];
                        }//end location
                      }// start location
                    }//for loop for polylinecoords
                  })//leg loop
                  directions = result;
                  this.setState({
                    directions: result,
                  });
                }//status ok
          })}.bind(this), delayFactor * 1000);
        } else {
          //get directions for the route after removing polylines
          for (let i = 0; i < polylinecoords.length-1; i++){
            console.log("inside polylinecoords loop");
            console.log(origin.lat);
            console.log(origin.lng);
            console.log(polylinecoords[i].lat);
            console.log(polylinecoords[i].lng);
            if((twoDecimals(origin.lat) == twoDecimals(polylinecoords[i].lat) &&
                twoDecimals(origin.lng) == twoDecimals(polylinecoords[i].lng)) ||
                (Math.round((origin.lat+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                Math.round((origin.lng+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)) {
              console.log("rearrange origin for directions")
              if(waypoints && waypoints.length > 0){
                newOrigin = waypoints[0].location;
                console.log(waypoints);
                console.log(waypoints[0]);
                if(newOrigin){
                  console.log(newOrigin.lat);
                  console.log(newOrigin.lng);
                  waypoints.splice(0,1);
                  DirectionsService.route({
                      origin: newOrigin,
                      destination: destination,
                      travelMode: window.google.maps.TravelMode.DRIVING,
                      waypoints: waypoints,
                      provideRouteAlternatives: false,
                    }, (result, status) => {
                      if(status === window.google.maps.DirectionsStatus.OK){
                        console.log("obtained direction");
                        directions = result;
                        this.setState({
                          directions: result,
                        });
                      }
                    })
                  }//if new origin
              }//if waypoints

            }//end if
            else {
              for (let j = 0; j < waypoints.length; j++){
                console.log(waypoints);
                console.log(waypoints[j].location.lat);
                console.log(waypoints[j].location.lng);
                console.log(polylinecoords[i].lat);
                console.log(polylinecoords[i].lng);
                if((twoDecimals(waypoints[j].location.lat) == twoDecimals(polylinecoords[i].lat) &&
                    twoDecimals(waypoints[j].location.lng) == twoDecimals(polylinecoords[i].lng)) ||
                    (Math.round((origin.lat+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lat+Number.EPSILON)*100)/100 &&
                    Math.round((origin.lng+Number.EPSILON)*100)/100 == Math.round((polylinecoords[i].lng+Number.EPSILON)*100)/100)){
                  console.log("rearrange waypoints for directions");
                  if(waypoints.length == 1){
                    DirectionsService.route({
                        origin: origin,
                        destination: waypoints[j].location,
                        travelMode: window.google.maps.TravelMode.DRIVING,
                        provideRouteAlternatives: false,
                      }, (result, status) => {
                        if(status === window.google.maps.DirectionsStatus.OK){
                          console.log("obtained direction");
                          directions = result;
                          this.setState({
                            directions: result,
                          });
                        }
                      })
                      break;
                    }//if length is 1
                    else{
                      DirectionsService.route({
                          origin: origin,
                          destination: waypoints[j].location,
                          travelMode: window.google.maps.TravelMode.DRIVING,
                          provideRouteAlternatives: false,
                        }, (result, status) => {
                          if(status === window.google.maps.DirectionsStatus.OK){
                            console.log("obtained direction");
                            directionsList = []
                            directionsList.push(result);
                            this.setState({
                              directionsList: directionsList,
                            });
                          }
                        })
                        DirectionsService.route({
                            origin: waypoints[j].location,
                            destination: destination,
                            travelMode: window.google.maps.TravelMode.DRIVING,
                            provideRouteAlternatives: false,
                          }, (result, status) => {
                            if(status === window.google.maps.DirectionsStatus.OK){
                              console.log("obtained direction");
                              directionsList.push(result);
                              this.setState({
                                directionsList: directionsList,
                              });
                            }
                          })
                        break;
                    }//end else
              }//end if
            }//end for loop j


          }//else
          console.error("error fetching directions in mytrips "+result);
        }//for loop for polyline coordinates
      }//else
      })//directionsService route
      if(directions){
        console.log("directions"+directions);
        this.setState({
          directions:directions,
        });
      }
      if(directionsList){
        console.log("directionsList "+directionsList);
        this.setState({
          directionsList:directionsList,
        });
      }
    }//componentDidMount
  })//lifecycle
)//compose
(
  (props) => {
      console.log(props);


      return <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        center={props.center}
        id={props.id}
      >

      <Polyline
                path={props.polylinecoords}
                geodesic={true}
                options={{
                    strokeColor: "#00bbd3",
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                }}
            />
        {(props.directions)?


          <DirectionsRenderer directions={props.directions}
                              options={{
                                polylineOptions: {
                                  strokeColor: "#00bbd3"
                                },
                                /*markerOptions: {
                                  label: {
                                    text: "D1-S1",
                                    color: "white"
                                  }
                                }*/
                              }}/>:""}
        {(props.directionsList)?
          props.directionsList.map((directions1) =>

          <DirectionsRenderer directions={directions1}
                              options={{
                                polylineOptions: {
                                  strokeColor: "#00bbd3"
                                },
                                /*markerOptions: {
                                  label: {
                                    text: "D1-S1",
                                    color: "white"
                                  }
                                }*/
                              }}/>):""}

      </GoogleMap>
      }
);



const HeaderTag = (props) => {

  const {
    img = ""
  } = props;
  return (
    <Helmet>
      <meta
        property="og:image"
        content={img}
      />
      <meta property="image" content={img} />
    </Helmet>
  );
};

class Memories extends Component {
  constructor(props){
    super(props);
    console.log(props);
    this.handleChangeTripSelect = this.handleChangeTripSelect.bind(this);
    this.handleBooking = this.handleBooking.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClickOpen2 = this.handleClickOpen2.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleClose2 = this.handleClose2.bind(this);
    this.handleCloseDateRange = this.handleCloseDateRange.bind(this);
    this.handleActivityName = this.handleActivityName.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleFromLocationSelected = this.handleFromLocationSelected.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleToLocationSelected = this.handleToLocationSelected.bind(this);
    this.handleSaveTripActivity = this.handleSaveTripActivity.bind(this);
    this.saveTripActivityHelper = this.saveTripActivityHelper.bind(this);
    this.saveTripActivityRefresher = this.saveTripActivityRefresher.bind(this);
    this.editTripActivity = this.editTripActivity.bind(this);
    this.handleTripActivityTypeChange = this.handleTripActivityTypeChange.bind(this);
    this.deleteTripActivity = this.deleteTripActivity.bind(this);
    this.editTrip = this.editTrip.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleDestinationSelected = this.handleDestinationSelected.bind(this);
    this.editTripSave = this.editTripSave.bind(this);
    this.handleInputChange1 = this.handleInputChange1.bind(this);
    this.handleInputChange2 = this.handleInputChange2.bind(this);
    this.handleTripNameChange = this.handleTripNameChange.bind(this);
    this.handleEndTime = this.handleEndTime.bind(this);
    this.photoUpload = this.photoUpload.bind(this);
    this.setActivityDateRange = this.setActivityDateRange.bind(this);
    this.handleDateRangeClick = this.handleDateRangeClick.bind(this);
    this.handleFileSelected = this.handleFileSelected.bind(this);
    this.handleClose3 = this.handleClose3.bind(this);
    this.handleEmailAddrChange = this.handleEmailAddrChange.bind(this);
    this.handleImageExpandOpen = this.handleImageExpandOpen.bind(this);
    this.handleImageClose = this.handleImageClose.bind(this);
    this.handleDeleteImageConfirmation = this.handleDeleteImageConfirmation.bind(this);
    this.handleImageSharing = this.handleImageSharing.bind(this);
    this.handleDeleteImageClose = this.handleDeleteImageClose.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.deleteTrip = this.deleteTrip.bind(this);
    this.getChildDestinationList = this.getChildDestinationList.bind(this);
    this.encodeS3ImgBodyToData = this.encodeS3ImgBodyToData.bind(this);
    this.handleTripShareImages = this.handleTripShareImages.bind(this);
    this.handleCloseShareTrip = this.handleCloseShareTrip.bind(this);
    this.handleImageGen = this.handleImageGen.bind(this);

    this.state = {
      mytripactivitylist: null,
      travelerid: props.searchResult.travelerid,
      center: {
        lat: 37.55,
        lng: -121.98
      },
      zoom: 8,
      directionslist: null,
      open: false,
      open2: false,
      open3: false,
      openDateRange: false,
      tripSelected: false,
      tripActivityName: "",
      tripId:props.searchResult.tripid,
      inputValue1: null,
      options1: [],
      options2: [],
      options3: [],
      inputValue2: null,
      inputValue3: null,
      tripactivitytypes: [],
      activitytype: "",
      tripToDestinationName: "",
      tripName: "",
      tripStartDate: new Date(),
      tripEndDate: new Date(),
      tripList: [],
      travelerId: props.searchResult.travelerid,
      sharedEmailAddrs: [],
      errorEndTime: false,
      mediaLengthError: "",
      openExpandPhoto: false,
      openDeleteImageConfirmation: false,
      imageToDelete: "",
      shareDialog: false,
      shareImgLst: [],
      statisMapURL:"https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo",
      imageInput: null,
    }

    if(!this.state.tripId){
      var str1 = props.location.search;
      console.log(str1.substring(str1.indexOf("tripid=")+7, str1.indexOf("&")));
      if(str1.indexOf("&") != -1){
        this.state.tripId = str1.substring(str1.indexOf("tripid=")+7, str1.indexOf("&"));
      }
      else {
        this.state.tripId = str1.substring(str1.indexOf("tripid=")+7);
      }
    }
    //loadMeta(document.querySelector('head'), "https://tripytopitineraryimgs.s3.us-east-2.amazonaws.com/"+this.state.tripId+".jpeg")

    /*if (typeof window !== 'undefined' && !this.state.loaded) {
      console.log("Inside window undefined and state not loaded");
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places',
          document.querySelector('head'),
          'google-maps',
        );
      }
      this.state.loaded = true;
    }*/

    //get trip List
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    console.log(props);
    if(this.state.travelerid){
      fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/gettripssharedwithme',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
               body:JSON.stringify({"travelerId": this.state.travelerid,
                                    "tripid": this.state.tripId
             })
            })
      .then(res => res.json())
      .then(data => {

        this.setState({
          tripList: data
        })
        console.log(this.state.tripList);
        //get latest trip for default load
        if(!props.searchResult.tripid){
          if(data && data.length > 0){
            var defaultId = data[0].id;
            console.log("default id"+defaultId);
            this.handleChangeTripSelect(defaultId);
          }
        }
        //get latest trip for create load
        if(props.searchResult.tripid){
          console.log("default id"+props.searchResult.tripid);
          this.setState({
            tripId: props.searchResult.tripId
          })
          this.handleChangeTripSelect(props.searchResult.tripid);
        }
      })
    }



    //trip activity types for drop down
    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivitytypes/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             }
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);

            this.setState({tripactivitytypes: data});
          })


  }

  handleChangeTripSelect(event){
    var inputId = null;
    var toDestinationId = null;
    if(event.target){
      console.log(`Option selected in trip list:`, event.target.value);
      this.setState({"trip": event.target.value,
                     "tripId": event.target.value,
                     "childDestImgLst": []
                   })
       inputId = event.target.value;
    }
    else{
      inputId = event
      this.setState({"trip": event,
                     "tripId": event,
                     "childDestImgLst": []
                   })
    }
    this.state.mapPolyline = [];
    this.state.polylineObj = {};
    console.log(this.state.tripList);
    for(let i=0; i < this.state.tripList.length; i++){
       if (this.state.tripList[i].id === inputId){
         console.log("inside start date update "+this.state.tripList[i].startDate)
         console.log("inside center update "+this.state.tripList[i].tolat+" lng"+this.state.tripList[i].tolng)
         this.setState({
           center: {
             lat: parseFloat(this.state.tripList[i].tolat),
             lng: parseFloat(this.state.tripList[i].tolng)
           },
           tripName: this.state.tripList[i].name,
           tripToDestinationName: this.state.tripList[i].todestinationname,
           tripStartDate: this.state.tripList[i].startDate,
           tripEndDate: this.state.tripList[i].endDate,
           todestinationid: this.state.tripList[i].todestinationid,
           dateRange: {
             startDate: moment(this.state.tripList[i].startDate, 'YYYY-MM-DD').toDate(),
             endDate: moment(this.state.tripList[i].endDate, 'YYYY-MM-DD').toDate(),
             key: 'selection',
             color: '#00bbd3',
           },
           displayDateRange: moment(this.state.tripList[i].startDate).format("YYYY-MM-DD")+" - "+moment(this.state.tripList[i].endDate).format("YYYY-MM-DD"),
           anchorEl: null,
           origin: {
             lat: parseFloat(this.state.tripList[i].fromlat),
             lng: parseFloat(this.state.tripList[i].fromlng)
           },
           destination: {
             lat: parseFloat(this.state.tripList[i].tolat),
             lng: parseFloat(this.state.tripList[i].tolng)
           },
           waypoints: [],
         })
         toDestinationId = this.state.tripList[i].todestinationid
       }//end if
     }//end for loop
     console.log(this.state.tripStartDate);
     console.log(this.state.tripEndDate);

     //get trip activities
     fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/gettripactivitiesfortrip',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
               },
               body:JSON.stringify({"tripId": inputId,
             })
           })
     .then(res => res.json())
     .then(data => {
      console.log(data);

      //photos
      if(data && data.length > 0){
        AWS.config.credentials = new AWS.Credentials
        ({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        })
        for(let i = 0; i < data.length; i++){
          this.state.s3client = new AWS.S3({region: "us-east-2"});
          const prefixVal = 'photos_'+inputId+'/'+data[i].id+'/';
          console.log(prefixVal);
          this.getPhotoKeys(prefixVal, data[i].id);
        }
      }



      //map mapPolyline
      /*if(data && data.length > 0){
        for(let i = 0; i < data.length; i++){
          console.log(data[i].tripactivitytypename)
          if(data[i].tripactivitytypename == "Flight"){
            console.log("Inside if of flight");
            if(data[i-1]){
              this.state.mapPolyline.push({
                lat: parseFloat(data[i-1].tolat),
                lng: parseFloat(data[i-1].tolng)
              })
            }
            else{
              this.state.mapPolyline.push({
                lat: parseFloat(data[i].fromlat),
                lng: parseFloat(data[i].fromlng)
              })
            }
            this.state.mapPolyline.push({
              lat: parseFloat(data[i].tolat),
              lng: parseFloat(data[i].tolng)
            })
          }// flight
        }// iteration
        this.state.polylineObj = this.state.mapPolyline;
      }*/

      //get day based ribbon
      console.log(data)
      var date1 = null;
      var dateUnique = null;
      var dateBasedArray = [];
      var dayCounter = 0;
      var dayLabel = null;
      var finalObj = {};
      var date2 = null;
      var futureFlag = false;
      data.forEach(dataitem1 => {
        if(dataitem1.endTime){
          dataitem1.endTime = moment(dataitem1.endTime).format("YYYY-MM-DD hh:mm A");
          //future flag for background color selection
          if(new Date(dataitem1.endTime) >= new Date())
          {
            console.log("future flag true")
            dataitem1.futureFlag = true;
          }
          else {
            dataitem1.futureFlag = false;
          }
        }
        date1 = dataitem1.endTime.slice(0,10)
        if (dateUnique == null){
          dateUnique = date1;
          date2 = dataitem1.endTime.slice(11, 19)
          dataitem1.endTimeDisplay = date2;
          dateBasedArray.push(dataitem1);
          dayCounter = 1;
          dayLabel = "Day "+dayCounter;
        }
        else if(dateUnique == date1){
          date2 = dataitem1.endTime.slice(11, 19)
          dataitem1.endTimeDisplay = date2;
          dateBasedArray.push(dataitem1);
        }
        else if (dateUnique != date1){
          finalObj[dayLabel] = dateBasedArray;
          dateBasedArray = [];
          dateUnique = date1;
          date2 = dataitem1.endTime.slice(11, 19)
          dataitem1.endTimeDisplay = date2;
          dateBasedArray.push(dataitem1);
          dayCounter = dayCounter + 1;
          dayLabel = "Day "+dayCounter;
        }
      })


      finalObj[dayLabel] = dateBasedArray;
      console.log(finalObj);

      //get map origin, destination and waypoints
      //origin and destination are home
      //add waypoints

      var waypoints1 = [];
      var waypoints2 = [];
      var destination1 =[];
      var arrayOfValues = Object.values(finalObj);
      var valueOfValues = [];
      var origin1 = [];
      var polylinecoords1 = [];
      var polylinecoords2 = [];
      console.log(arrayOfValues);
      if(data && data.length > 0){
        for(let j=0; j<arrayOfValues.length; j++){
          valueOfValues = arrayOfValues[j];
          console.log(valueOfValues);
          waypoints2 = [];
          polylinecoords2 = [];
          if(j === 0){
            origin1[j] = this.state.origin;
            //polylinecoords2.push(this.state.origin);
          }
          else{

            origin1[j] = destination1[j-1];
            console.log(origin1[j]);
            //polylinecoords2.push(destination1[j-1]);
          }
          for(let i=0; i< valueOfValues.length;i++){
            if(i === (valueOfValues.length - 1)){
              console.log("inside last iteration")
              destination1[j] = {
                lat: parseFloat(valueOfValues[i].tolat),
                lng: parseFloat(valueOfValues[i].tolng)
              };
              console.log(destination1[j]);
              waypoints1[j] = waypoints2;
              polylinecoords1[j] = polylinecoords2;
              if(valueOfValues[i].tripactivitytypename == "Flight"){
                console.log("inside flight in if loop")
                if(i == 0)
                {
                  console.log("inside i=0"+origin1[j]);
                  polylinecoords2.push(origin1[j]);
                }
                else{
                  polylinecoords2.push({
                   lat: parseFloat(valueOfValues[i-1].tolat),
                   lng: parseFloat(valueOfValues[i-1].tolng)
                 })
                }
                polylinecoords2.push({
                 lat: parseFloat(valueOfValues[i].tolat),
                 lng: parseFloat(valueOfValues[i].tolng)
               })
             }//flight handling
            }//if
            else {
              console.log("Inside non flight")
              waypoints2.push({location: {
                lat: parseFloat(valueOfValues[i].tolat),
                lng: parseFloat(valueOfValues[i].tolng)
              },
              stopover: true
              })
              if(valueOfValues[i].tripactivitytypename == "Flight"){
                if(i == 0)
                {
                  polylinecoords2.push(origin1[j]);
                }
                else{
                  polylinecoords2.push({
                   lat: parseFloat(valueOfValues[i-1].tolat),
                   lng: parseFloat(valueOfValues[i-1].tolng)
                 })
                }
                polylinecoords2.push({
                 lat: parseFloat(valueOfValues[i].tolat),
                 lng: parseFloat(valueOfValues[i].tolng)
               })
             }//flight handling
          }//else
        }//for loop i
      }//for loop j
      }//data length check

      console.log(origin1);
      console.log(destination1);
      console.log(waypoints1);
      console.log(polylinecoords1);


      //format end time
      /*if(data && data.length > 0){
        for(let i = 0; i < data.length; i++){
          if(data[i].endTime){
            data[i].endTime = moment(data[i].endTime).format("YYYY-MM-DD hh:mm A");
          }
        }
      }*/
      var currentZoom = this.state.zoom
      this.setState({
         mytripactivitylist: finalObj,
         zoom: currentZoom+1,
         tripSelected: true,
         originsList: origin1,
         destinationsList: destination1,
         waypointsList: waypoints1,
         polylinecoordsList: polylinecoords1
       })
       console.log(this.state.mytripactivitylist);

     })//end of get trip activities for trip

     this.getChildDestinationList(toDestinationId);




  }

  async getPhotoKeys(prefixVal, tripActivityId){
    var keys = [];


    var data = await this.state.s3client.listObjects({Bucket: 'itifindphotos', Prefix: prefixVal}).promise();
    console.log(prefixVal);

    if(data.Contents){
      data.Contents.forEach( async (elem) => {
        //console.log(elem.Key);
        if(elem.Key.includes("_sm")){
          console.log("inside get key "+elem.Key);
          var data2 = await this.state.s3client.getObject({Bucket: 'itifindphotos',
                                        Key: elem.Key}).promise();


          if(data2.Body){
            console.log(" inside data body "+elem.Key)
            let imgBuffer = data2.Body;
            if(!data2.Body.toString().startsWith("data:image/jpeg;base64")){
              imgBuffer = "data:image/jpeg;base64," + data2.Body.toString('base64')
            }
            //keys.push(data2.Body);
            var photoElem = {"key1": elem.Key, "value1": imgBuffer};
            keys.push(photoElem);
            var tripActivities = this.state.mytripactivitylist;
            Object.entries(tripActivities).map(([key, value]) => {
              value.map(value1 => {
               if(value1.id == tripActivityId){
                console.log("adding photos to value");
                 value1.photos = keys;
               }
             })//valuemap
           })//tripactivities
            this.setState({
              mytripactivitylist: tripActivities
            })
          }//data.body
        }//_sm check
      });
    console.log(this.state.mytripactivitylist);
    }
    return keys;
  }

  handleFileSelected(event, tripItem){
    console.log ("inside event");
    console.log(event.target.value);
    console.log(event.target.files);
    console.log(event.target.files[0]);
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    console.log(this.state.tripActivityId);
    //console.log(tripItem);
    var tripActivityId = this.state.tripActivityId;
    if(!this.state.travelerid){
      //
      return;
    }
    if(!this.state.tripId){
      return;
    }
    if(!tripActivityId){
      return;
    }
    //check of length of media exceeds 4
    if(event.target.files && event.target.files.length >4){
      this.setState({
        mediaLengthError: "Cannot have more than 4 media items"
      })
      return;
    }
    else{
      this.setState({
        mediaLengthError: ""
      })
    }
    //check if sum of existing media + new media is 4
    var tripActivities = this.state.mytripactivitylist;
    Object.entries(tripActivities).map(([key, value]) => {
      value.map(value1 => {
       if(value1.id == tripActivityId){
         console.log("got tripactivity id");
         if(value1.photos && value1.photos.length + event.target.files.length > 4)
         {
           this.setState({
             mediaLengthError: "Cannot have more than 4 media items"
           })
           return;
         }
       }//if value is matching tripactivityid
     })//valuemap
   })//tripactivities

    Object.entries(event.target.files).map(([key, file1]) => {
    //var file1 = event.target.files[0];
    console.log(file1.name);
    var reader = new FileReader();
    var result = null;
    reader.addEventListener('load', (event1) => {
      console.log("file read");
      result = event1.target.result;

      var buf1 = new Buffer (result, 'binary');
      var buf = new Buffer (result, 'binary');
      //sharp(buf1).resize(200,200)
      //.then(buf => {
        var keys1 = [];

        var tripActivities = this.state.mytripactivitylist;
        Object.entries(tripActivities).map(([key, value]) => {
          value.map(value1 => {
           if(value1.id == tripActivityId){
             console.log("adding photos to value");
             keys1 = value1.photos;
             console.log(keys1);
             if(!keys1 || keys1 == null){
               keys1 = [];
             }
             var photoElem = {"key1": 'photos_'+this.state.tripId+'/'+tripActivityId+'/'+file1.name, "value1": buf}

             keys1.push(photoElem);
             value1.photos = keys1;
           }
         })//valuemap
       })//tripactivities
        this.setState({
          mytripactivitylist: tripActivities
        })



        /*var keys1 = this.state.photoKeys;
        keys1.push(buf);
        this.setState({
          photoKeys: keys1
        })*/

        const keyVal = 'photos_'+this.state.tripId+'/'+tripActivityId+'/'+file1.name;
        console.log(keyVal)
        //console.log(buf);
        const tempParams1 = {Bucket: 'itifindphotos',
                            Key: keyVal,
                            Body: buf};
        this.state.s3client.upload(tempParams1, function(err, data){
          if(err){
            console.log(err);
          }
          else{
            console.log("Success");
            fetch(process.env.REACT_APP_HOSTNAME+'/api/image/process',
                    { method:'POST',
                      headers:{
                       "Content-Type": "application/json"
                     },
                      body:JSON.stringify({Bucket: 'itifindphotos',
                                          Key: keyVal,
                                          tripactivityid: tripActivityId,


                      })
                  })
                  .then(res => {
                    console.log("image processed")
                  })
          }
        })//upload
       //})//buf1
      })//event listener

      reader.readAsDataURL(file1);
    })//object entries of target files






    //this.state.s3client.upload()
  }

  handleBooking(activity){
    console.log(activity);

    if(activity.tripactivitytypename === "restaurant"){
      var url = 'https://api.yelp.com/v3/businesses/search?term='+encodeURIComponent(activity.tolocation)+
                '&latitude='+encodeURIComponent(activity.tolat)+'&longitude='+encodeURIComponent(activity.tolng);

      /*var url = 'https://api.yelp.com/v3/businesses/search?term='+encodeURIComponent(activity.tolocation)+
                '&location='+encodeURIComponent('Monterey, Ca');
      */
      var req = new Request(url,{
                  method: 'GET',
                  headers: new Headers({
                    'Authorization': `Bearer `+'b9rIpSk_U0NP7T4qTnU8cfPMPVgJTjqXYJGoFZdm3I71i_M3st9Zu2K047ASABslZQ4dcTp3S2f1CQyFZTv7iIebirfZIp5xiSfCvHhWRtNQjOeACVykWQP9jT2gXnYx',
                    'ContentType': 'application/json'
                  }),
      });
      fetch(req)
      .then(res => res.json())
      .then(data => {
        console.log(data);
      })
      .catch(error => console.log(error))
    }
  }

  handleClickOpen(key, value)  {
    console.log(key);
    console.log(value);
    var endTimeForDialog = null;
    if(value && value.length > 0){
      endTimeForDialog = value[value.length-1].endTime;
    }
    console.log(endTimeForDialog)
    console.log(moment(endTimeForDialog?endTimeForDialog:this.state.tripStartDate))
    this.setState({open: true, edit:false, name:"", activitytype: "", inputValue2: null, tripActivityName:"",
          endtime: moment(endTimeForDialog?endTimeForDialog:this.state.tripStartDate,"YYYY-MM-DD hh:mm A")});
    console.log(this.state.endtime)
  };

  handleClickOpen2(tile){
    console.log(tile);
    //auto populate destination
    let destinationId = tile.key2.substring(0, tile.key2.indexOf(".jpg"));
    var foundActivityType = false;
    var activityType = 27;
    if(tile.destType == "Hotel"){
      activityType = 2
    }
    else if (tile.destType == "Airport"){
      activityType = 1
    }
    else if (tile.destType == "Nature"){
      activityType = 9
    }
    else if (tile.destType == "Amusement Park"){
      activityType = 12
    }
    else if (tile.destType == "Museum"){
      activityType = 25
    }
    else if (tile.destType == "Restaurant"){
      activityType = 5
    }
    else if (tile.destType == "Night Club"){
      activityType = 23
    }
    else if (tile.destType == "Bar"){
      activityType = 52
    }
    else if (tile.destType == "Beach"){
      activityType = 8
    }
    else if (tile.destType == "Golf Course"){
      activityType = 11
    }
    else if (tile.destType == "Vineyard"){
      activityType = 28
    }
    else if (tile.destType == "Winery"){
      activityType = 29
    }

    this.setState({open: true, edit:false, name:"", activitytype: activityType, inputValue2: tile.dest,
          tolocationid: destinationId, endtime: moment(this.state.tripStartDate,"YYYY-MM-DD hh:mm A")});
  }

  handleClose() {
    this.setState({open: false})
  }

  handleClose2(){
    this.setState({open2: false})
  }

  handleCloseDateRange(){
    this.setState({openDateRange: false})
  }

  handleActivityName(event) {
    console.log(event.target.value);
    this.setState({tripActivityName: event.target.value});

    console.log(this.state.tripActivityName);

    //this.setState({open: false})
  }

  setActivityDateRange(selectionVal){
    console.log(selectionVal);
    this.setState({
      dateRange: {
        startDate: selectionVal.selection.startDate,
        endDate: selectionVal.selection.endDate,
        key: "selection",
        color: '#00bbd3'
      },
      displayDateRange: moment(selectionVal.selection.startDate).format("YYYY-MM-DD")+" - "+moment(selectionVal.selection.endDate).format("YYYY-MM-DD"),
      tripStartDate: selectionVal.selection.startDate,
      tripEndDate: selectionVal.selection.endDate
    })
  }

  handleDateRangeClick(event){
    console.log("Inside text click");
    this.setState({
      openDateRange: true
    });
    if(this.state.anchorEl){
      this.setState({
        anchorEl: null
      })
    }
    else{
      this.setState({
        anchorEl: event.currentTarget
      })
    }

  }

  handleSaveTripActivity(){

    console.log(this.state.tripActivityName);
    console.log(this.state.fromlocationid);
    console.log(this.state.tolocationid);
    console.log(this.state.tripId);
    console.log(this.state.activitytype);
    console.log(this.state.edit);
    console.log(this.state.endtime);

    if(!this.state.activitytype){
      this.setState({
        errorActivityType: true,
        activityTypeErrorText: "Enter Trip Activity Type"
      });
      return;
    }
    if(!this.state.tolocationid){
      this.setState({
        errorToLocationId: true,
        toLocationIdErrorText: "Enter To Destination"
      });
      return;
    }
    if(!this.state.endtime){
      this.setState({
        errorEndTime: true,
        endTimeErrorText: "Enter Arrived At"
      });
      return;
    }

    //var momentEndTime = moment(this.state.endtime)
    var endTimeFormatted = moment(this.state.endtime).format("YYYY-MM-DDTHH:mm");
    console.log("end time formatted"+endTimeFormatted);

    if(!this.state.edit){
      fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/create',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
                body:JSON.stringify({name: this.state.tripActivityName,
                                     tripid: this.state.tripId,
                                     fromlocationid: this.state.fromlocationid,
                                     tolocationid: this.state.tolocationid,
                                     activitytypeid: this.state.activitytype,
                                     endtime: endTimeFormatted

                })
            })
            .then(res => res.json())
            .then(data => {
                  console.log(data);

                  this.saveTripActivityRefresher(data);
                  this.saveTripActivityHelper(data);


                 })
      }
      else{
        fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/update',
                { method:'POST',
                  headers:{
                   "Content-Type": "application/json"
                 },
                  body:JSON.stringify({ tripactivityid:this.state.tripactivityid,
                                        name: this.state.tripActivityName,
                                       tripid: this.state.tripId,
                                       fromlocationid: this.state.fromlocationid,
                                       tolocationid: this.state.tolocationid,
                                       activitytypeid: this.state.activitytype,
                                       endtime: endTimeFormatted

                  })
              })
              .then(res => res.json())
              .then(data => {
                  console.log(data);


                  this.saveTripActivityRefresher(data);
                  this.saveTripActivityHelper(data);


              })
      }

  }//end handle save trip activity

  saveTripActivityHelper(data){

    //automate creation of return flight
    console.log("inside save trip activity helper");
    console.log(data);
    var flightCount = 0;
    var flightFromLocationId;
    var flightToLocationId;
    var lastDate;
    var hotelCount = 0;
    var hotelDateStart;
    var hotelToLocationId;
    if(data && data.length > 0){
      lastDate = data[0].lastdate;
      console.log("last date "+lastDate);
      var parts =lastDate.split('-');
      //lastDate = new Date(parts[0], parts[1] - 1, parts[2]);
      //lastDate.setHours(21);
      var lastDateFl = lastDate+"T17:00:00"
      console.log("last date after formatting "+lastDateFl);

      for(let i = 0; i < data.length ; i++) {
        var dataitem1 = data[i];
        if (dataitem1.tripactivitytypename == 'Flight'){
          flightCount++;
          //we need to get the data item before flight to get origin else origin
          if (i == 0){
            flightFromLocationId = dataitem1.tripfromdestinationid
          }
          else{
            flightFromLocationId = data[i-1].tolocationid
          }
        }
        if(dataitem1.tripactivitytypename == 'Hotel'){
          console.log("hotel count ");
          hotelCount++;
          hotelToLocationId = dataitem1.tolocationid;
          hotelDateStart = dataitem1.endTime;
        }
        console.log("hotel count total "+hotelCount);
      }
    }
    //check for flight count
    if (flightCount == 1){
      //create trip activity for return flight on the last day of trip
      fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/create',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
                body:JSON.stringify({name: 'Flight Back',
                                     tripid: this.state.tripId,
                                     fromlocationid: null,
                                     tolocationid: flightFromLocationId,
                                     activitytypeid: 1,
                                     endtime: lastDateFl

                })
            })
            .then(res => res.json())
            .then(data1 => {
                  console.log(data1);
                  data = data1;
                  this.saveTripActivityRefresher(data);
                  })
    }
    if(hotelCount == 1){
      //create trip activity for hotel stay each day of the trip
      var dateIter = new Date();
      var hotelDateStartLst = hotelDateStart.split(" ");
      var parts =hotelDateStartLst[0].split('-');

      console.log("Parts "+parts);
      hotelDateStart = new Date(parts[0], parts[1] - 1, parts[2]);
      console.log("hotel start date "+hotelDateStart);
      hotelDateStart.setHours(22);
      //dateIter.setDate(hotelDateStart.getDate());
      dateIter = new Date(hotelDateStart.getTime());
      console.log("first date "+dateIter);
      lastDate = new Date(lastDate);
      var daysDifference = Math.floor((lastDate.getTime()-hotelDateStart.getTime())/(1000*60*60*24));
      console.log("days difference "+daysDifference);
      if(daysDifference >= 2){
        lastDate = new Date(lastDate.valueOf());

        console.log("last date "+lastDate);
        var autoCount = 0;
        var stringDate = "";
        while(dateIter.getTime() < lastDate.getTime() && autoCount <= 10){
          autoCount++;
          console.log("Inside date iterator "+dateIter);
          console.log("inside date iterator value of "+dateIter.valueOf())
          dateIter = new Date(dateIter.valueOf())
          dateIter.setDate(dateIter.getDate() + 1);
          var mth1 = dateIter.getMonth()+1
          if (mth1 < 10){
             mth1 = "0"+mth1
          }
          var dt1 = dateIter.getDate()
          if(dt1 <10){
            dt1 = "0"+dt1
          }
          //month starts at 0. so one has to be added to month when doing string concatenation
          stringDate = dateIter.getFullYear()+"-"+mth1+"-"+dt1+"T22:00:00"
          console.log("string date "+stringDate);

          fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/create',
              { method:'POST',
                headers:{
                 "Content-Type": "application/json"
               },
                body:JSON.stringify({name: 'Stay',
                                     tripid: this.state.tripId,
                                     fromlocationid: null,
                                     tolocationid: hotelToLocationId,
                                     activitytypeid: 2,
                                     endtime: stringDate

                })
            })
            .then(res => res.json())
            .then(data1 => {

                  console.log(data1);
                  data = data1;
                  this.saveTripActivityRefresher(data);
                  })
                }//end while
        }//if days difference
    }//if hotel count 1



  }

  saveTripActivityRefresher(data){
    //photos
    if(data && data.length > 0){
      for(let i = 0; i < data.length; i++){

        AWS.config.credentials = new AWS.Credentials
        ({
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
        })
        this.state.s3client = new AWS.S3({region: "us-east-2"});
        const prefixVal = 'photos_'+this.state.tripId+'/'+data[i].id+'/';
        console.log(prefixVal);
        this.getPhotoKeys(prefixVal, data[i].id);
      }
    }

    //get day based ribbon
    console.log(data)
    var date1 = null;
    var dateUnique = null;
    var dateBasedArray = [];
    var dayCounter = 0;
    var dayLabel = null;
    var finalObj = {};
    var date2 = null;


    data.forEach(dataitem1 => {
      if(dataitem1.endTime){
        dataitem1.endTime = moment(dataitem1.endTime).format("YYYY-MM-DD hh:mm A");
        //future flag for background color selection
        if(new Date(dataitem1.endTime) > new Date())
        {
          dataitem1.futureFlag = true;
        }
        else {
          dataitem1.futureFlag = false;
        }
      }
      date1 = dataitem1.endTime.slice(0,10);


      if (dateUnique == null){
        dateUnique = date1;
        date2 = dataitem1.endTime.slice(11, 19)
        dataitem1.endTimeDisplay = date2;
        dateBasedArray.push(dataitem1);
        dayCounter = 1;
        dayLabel = "Day "+dayCounter;
      }
      else if(dateUnique == date1){
        date2 = dataitem1.endTime.slice(11, 19)
        dataitem1.endTimeDisplay = date2;
        dateBasedArray.push(dataitem1);
      }
      else if (dateUnique != date1){
        finalObj[dayLabel] = dateBasedArray;
        dateBasedArray = [];
        dateUnique = date1;
        date2 = dataitem1.endTime.slice(11, 19)
        dataitem1.endTimeDisplay = date2;
        dateBasedArray.push(dataitem1);
        dayCounter = dayCounter + 1;
        dayLabel = "Day "+dayCounter;
      }
    })//end for each


    finalObj[dayLabel] = dateBasedArray;
    console.log(finalObj);

    var waypoints1 = [];
    var waypoints2 = [];
    var destination1 =[];
    var arrayOfValues = Object.values(finalObj);
    var valueOfValues = [];
    var origin1 = [];
    var polylinecoords1 = [];
    var polylinecoords2 = [];
    console.log(arrayOfValues);
    if(data && data.length > 0){
      for(let j=0; j<arrayOfValues.length; j++){
        valueOfValues = arrayOfValues[j];
        console.log(valueOfValues);
        waypoints2 = [];
        polylinecoords2 = [];
        if(j === 0){
          origin1[j] = this.state.origin;
          //polylinecoords2.push(this.state.origin);
        }
        else{
          origin1[j] = destination1[j-1]
          //polylinecoords2.push(destination1[j-1]);
        }
        for(let i=0; i< valueOfValues.length;i++){
          if(i === (valueOfValues.length - 1)){
            console.log("inside last iteration")
            destination1[j] = {
              lat: parseFloat(valueOfValues[i].tolat),
              lng: parseFloat(valueOfValues[i].tolng)
            };
            waypoints1[j] = waypoints2;
            polylinecoords1[j] = polylinecoords2;
          }//if
          else {
            waypoints2.push({location: {
              lat: parseFloat(valueOfValues[i].tolat),
              lng: parseFloat(valueOfValues[i].tolng)
            },
            stopover: true
            })
            if(valueOfValues[i].tripactivitytypename == "Flight"){
              if(i == 0)
              {
                polylinecoords2.push(this.state.origin)
              }
              else{
                polylinecoords2.push({
                 lat: parseFloat(valueOfValues[i-1].tolat),
                 lng: parseFloat(valueOfValues[i-1].tolng)
               })
              }
              polylinecoords2.push({
               lat: parseFloat(valueOfValues[i].tolat),
               lng: parseFloat(valueOfValues[i].tolng)
             })
           }//flight handling
        }//else
      }//for loop i
    }//for loop j
    }//data length check

    console.log(origin1);
    console.log(destination1);
    console.log(waypoints1);
    console.log(polylinecoords1);

    //format end time
    /*if(data && data.length > 0){
      for(let i = 0; i < data.length; i++){
        if(data[i].endTime){
          data[i].endTime = moment(data[i].endTime).format("YYYY-MM-DD hh:mm A");
        }
      }
    }*/
     this.setState({
       mytripactivitylist: finalObj,
       zoom: 11,
       tripSelected: true,
       open: false,
       originsList: origin1,
       destinationsList: destination1,
       waypointsList: waypoints1,
       polylinecoordsList: polylinecoords1
     })
     console.log(this.state.mytripactivitylist);
  }

  savePicturesToShare(tripid){
    console.log("inside save picture to share")
    //get the card with day label
    //create an image and save to s3 Bucket
    var data = this.state.mytripactivitylist;
    console.log(data)
    if(data){
       Object.entries(data).map(([key, value]) => {
         console.log(key)
         const input = document.getElementById(key);
         console.log(input);
         html2canvas(input, {
           useCors: true,
           optimized: false,
           allowTaint: false,
         })
         .then(canvas => {
             var imgWidth = 208;
             var imgHeight = 1050;

             var dataUrl = canvas.toDataURL("image/jpeg");
             var blobData = dataURItoBlob(dataUrl);
             console.log("blob generated"+blobData);
             const keyVal = tripid+'_'+key+'.jpeg';
             const tempParams1 = {Bucket: 'tripytopitineraryimgs',
                                 Key: keyVal,
                                 Body: blobData};
             this.state.s3client.upload(tempParams1, function(err, data){
               if(err){
                 console.log(err);
               }
               else{
                 console.log("Success");
               }
             })
         })
       })
    }
    /*const input = document.getElementById('tripheader');

    console.log("create an image")
    html2canvas(input)
    .then(canvas => {
        var imgWidth = 208;
        var imgHeight = 150;

        var dataUrl = canvas.toDataURL("image/jpeg");
        var blobData = dataURItoBlob(dataUrl);
        console.log("blob generated"+blobData);
        const keyVal = trip[0].id+'.jpeg';
        const tempParams1 = {Bucket: 'tripytopitineraryimgs',
                            Key: keyVal,
                            Body: blobData};
        this.state.s3client.upload(tempParams1, function(err, data){
          if(err){
            console.log(err);
          }
          else{
            console.log("Success");
          }
        })
    })*/
  }

  handleChange1(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue1: event.target.value});
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options1: results})})
    //fetch1(event.target.value, (results) => {console.log("inside callback");
    //                                              this.setState({options: results})})

  }
  handleChange2(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue2: event.target.value,
                    errorToLocationId: false,
                    toLocationIdErrorText: ""});
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options2: results})})
    //fetch1(event.target.value, (results) => {console.log("inside callback");
    //                                              this.setState({options: results})})

  }
  handleFromLocationSelected(event, selectedOption2){
    if(selectedOption2){
     console.log(`Option selected in automcomplete value:`, selectedOption2.description);
     console.log(`Option selected in automcomplete event:`, event.target);

     fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
               body:JSON.stringify({name: selectedOption2.description

               })
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             this.setState({
               fromlocationid: data,
               inputValue1: selectedOption2.description
             })
           })
       }
  }
  handleToLocationSelected(event, selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2);
    console.log(`Option selected in automcomplete event:`, event.target);
    if(selectedOption2){
     console.log('get types '+selectedOption2.types);
     console.log(`Option selected in automcomplete value:`, selectedOption2.description);
     console.log(`Option selected in automcomplete event:`, event.target);

     //setting destination types & activity types
     var element;
     var foundActivityType = false;
     var destType = "";
     for(let i = 0; i< selectedOption2.types.length; i++){
       element = selectedOption2.types[i];
       if(element == "country"){
         destType = "Country";
         break;
       }
       if(element == "administrative_area_level_1"){
         destType = "State";
         break;
       }
       if(element == "lodging"){
         //set activity type as Hotel
         this.setState({
           activitytype: 2
         });
         destType="Hotel";
         foundActivityType=true;
         break
       }
       if(element == "airport"){
         this.setState({
           activitytype: 1
         });
         destType="Airport";
         foundActivityType=true;
         break;
       }
       if(element == "natural_feature"){
         this.setState({
           activitytype: 9
         });
         destType="Nature";
         foundActivityType=true;
         break;
       }

       if(element == "amusement_park"){
         this.setState({
           activitytype: 12
         });
         destType="Amusement Park";
         foundActivityType=true;
         break;
       }
       if(element == "museum"){
         this.setState({
           activitytype: 25
         });
         destType="Museum";
         foundActivityType=true;
         break;
       }
       if(element == "night_club"){
         destType="Night Club";
         this.setState({
           activitytype: 23
         });
         break;
       }
       if(element == "restaurant"){
         this.setState({
           activitytype: 5
         });
         destType="Restaurant";
         foundActivityType=true;
         break;
       }
       if(element == "town_square" || element == "neighborhood"){
         destType="Point of Interest";
         break;
       }
       if(element == "sublocality"){
         destType="Point of Interest";
         break;
       }
       if(element == "tourist_attraction" || element == "colloquial_area"){
         destType="Point of Interest";
         break;
       }
       if(element == 'administrative_area_level_1'){
         destType="State";
         break;
       }
       if(element == "locality"){
         destType = "City";
         break;
       }
       if(element == "park"){
         destType = "Park";
         break;
       }
       if(element == "bar"){
         destType = "Bar";
         this.setState({
           activitytype: 52
         });
         break;
       }
       if(element == "travel_agency"){
         destType = "Tour";
         break;
       }
       if(element == "route"){
         destType = "Point of Interest";
         break;
       }
       if(element == "colloquial_area"){
         destType = "Point of Interest";
         break;
       }
       if(element == "bakery"){
         destType = "Bakery";
         break;
       }
       if(element == "store"){
         destType = "Store";
         break;
       }

   }
   if(!foundActivityType){
     if(selectedOption2.description.includes("Beach")){
       this.setState({
         activitytype: 8
       });
       destType="Beach";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Golf")){
       this.setState({
         activitytype: 11
       });
       destType="Golf Course";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Vineyard")){
       this.setState({
         activitytype: 28
       });
       destType="Vineyard";
       foundActivityType=true;
     }
     else if(selectedOption2.description.includes("Winery")){
       this.setState({
         activitytype: 29
       });
       destType="Winery";
       foundActivityType=true;
     }
   }//end of if not found activity type
   if(!foundActivityType){
     this.setState({
       activitytype: 27
     });
     foundActivityType=true;
   } //setting default activity type



   fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
         { method:'POST',
           headers:{
            "Content-Type": "application/json"
          },
          body:JSON.stringify({name: selectedOption2.description,
                               desttype: destType
          })
       })
       .then(res => res.json())
       .then(data => {
         console.log(data);
         this.setState({
           tolocationid: data,
           inputValue2: selectedOption2.description,
           errorToLocationId: false,
           toLocationIdErrorText: ""
         })
       })
    }//selected option 2
  }


  editTripActivity(activityItem){
    console.log("Inside Edit Trip Activity"+activityItem.endTime);
    this.setState({
      open: true,
      name: activityItem.name,
      tripActivityName: activityItem.name,
      inputValue1: activityItem.fromlocation,
      fromlocationid: activityItem.fromlocationid,
      inputValue2: activityItem.tolocation,
      tolocationid: activityItem.tolocationid,
      activitytype: activityItem.tripactivitytypeid,
      edit: true,
      tripactivityid: activityItem.id,
      endtime: moment(activityItem.endTime, "YYYY-MM-DD hh:mm A")
    })
    console.log("formating"+moment(activityItem.endTime, "YYYY-MM-DD hh:mm A"));
    console.log("Inside Edit Trip Activity"+this.state.endtime);
  }

  handleTripActivityTypeChange(event){
    console.log("trip activity type change"+event.target.value);
    this.setState({
      activitytype: event.target.value,
      errorActivityType: false,
      activityTypeErrorText: ""
    })
  }

  deleteTripActivity(activityItem){
    console.log("Inside delete Trip Activity"+activityItem.id);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/delete',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({ tripactivityid:activityItem.id
              })
          })
          .then(data => {
            console.log(data);
            var activitylist = this.state.mytripactivitylist;
            console.log(activitylist);

            Object.entries(activitylist).map(([key, value]) => {
               activitylist[key] = value.filter(value1 => {
                  return value1.id != activityItem.id
               })
            })

            //console.log(res);
            console.log(activitylist);
            //activitylist[activitylist.indexOf(data[0].id)] = data;
            this.setState({
              mytripactivitylist: activitylist
            })
            console.log(this.state.mytripactivitylist);
          })

  }

  editTrip()  {
    this.setState({open2: true,

    })
  };

  handleDestinationSelected(event, selectedOption2){
    if(selectedOption2){
     console.log(`Option selected in automcomplete value:`, selectedOption2.description);
     console.log(`Option selected in automcomplete event:`, event.target);
     this.setState({"destinationTo": selectedOption2.description})
     fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
               body:JSON.stringify({name: selectedOption2.description

               })
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             this.setState({
               todestinationid: data,
               tripToDestinationName: selectedOption2.description,
               errorToDestinationId: false,
               toDestinationIdErrorText: ""
             })
           })
       }
  }
  handleChange3(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue3: event.target.value,
            errorToDestinationId: false,
            toDestinationIdErrorText: ""
          });
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options3: results})})


  }

  handleChangeDateFrom(input){
    console.log(input)
    this.setState({
      tripStartDate: moment(input).format("MM/DD/YYYY"),
      errorTripStartDate: false,
      tripStartDateErrorText: ""
    });
    /*if(input != null) {
      var input1 = new Date(input.getFullYear(), input.getMonth(), input.getDate())
      console.log(input1)
      var a = moment(input1);
      console.log(a.format('YYYY-MM-DD'));
      this.setState({
        tripStartDate: a.format('YYYY-MM-DD')
      });
    }
    else {
      this.setState({
        tripStartDate: null
      });
    }*/
    //console.log("end date ",input["endDate"])

  }
  handleChangeDateTo(input){
    console.log(input)
    this.setState({
      tripEndDate: moment(input).format("MM/DD/YYYY"),
      errorTripEndDate: false,
      tripEndDateErrorText: ""
    });
    /*if(input != null) {
      var a = moment(input);
      console.log(a.format('YYYY-MM-DD'));
      this.setState({
        tripEndDate: a.format('YYYY-MM-DD')
      });
    }
    else {
      this.setState({
        tripEndDate: null
      });
    }*/
  }

  editTripSave(){
    console.log(this.state.tripName);
    console.log(this.state.todestinationid);

    console.log(this.state.travelerid);
    console.log(this.state.tripId);

    this.setState({
      tripStartDate: this.state.dateRange.startDate,
      tripEndDate: this.state.dateRange.endDate
    })

    console.log(this.state.tripStartDate);
    console.log(this.state.tripEndDate);

    if(!this.state.tripName){
      this.setState({
        errorTripName: true,
        tripNameErrorText: "Enter TripName"
      })
      return;
    }
    if(!this.state.tripStartDate){
      this.setState({
        errorDateRange: true,
        dateRangeErrorText: "Enter Trip Start Date"
      })
      return;
    }
    if(!this.state.tripEndDate){
      this.setState({
        errorDateRange: true,
        dateRangeErrorText: "Enter Trip End Date"
      })
      return;
    }
    if(!this.state.todestinationid){
      this.setState({
        errorToDestinationId: true,
        toDestinationIdErrorText: "Enter Destination"
      })
      return;
    }

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/update',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
                                   "tripname": this.state.tripName,
                                   "startdate": this.state.tripStartDate,
                                   "enddate": this.state.tripEndDate,
                                   "todestinationid": this.state.todestinationid,
                                   "tripid": this.state.tripId

              })
          })
          .then(res => res.json())
          .then(trip =>{
            console.log(trip[0]);
            this.setState({open2: false})

            var tripListTemp = this.state.tripList;
            var res = tripListTemp.map(obj =>  (trip[0].id === obj.id)?trip[0]:obj);
            //activitylist[activitylist.indexOf(data[0].id)] = data;
            console.log(res);
            this.setState({
              tripList: res
            })
            this.setState({open: false});
            console.log(this.state.tripList);


            /*if (triplist[i].id === selectedOption.value){
              console.log("inside center update "+triplist[i].startDate)
              this.setState({
                center: {
                  lat: parseFloat(triplist[i].tolat),
                  lng: parseFloat(triplist[i].tolng)
                },
                tripName: triplist[i].name,
                tripToDestinationName: triplist[i].todestinationname,
                tripStartDate: triplist[i].startDate,
                tripEndDate: triplist[i].endDate
              })
            }*/

          })
          .catch(error => {console.log(error); return error})

  }

  deleteTrip(){
    console.log(this.state.tripId);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/delete',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
              },
              body:JSON.stringify({"tripid": this.state.tripId

              })
          })
          .then(trip =>{
            fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getmytrips',
                   { method:'POST',
                     headers:{
                      "Content-Type": "application/json"
                    },
                    body:JSON.stringify({"travelerId": this.state.travelerid,
                  })
                 })
           .then(res => res.json())
           .then(data => {
             if(data && data.length > 0){
               this.setState({
                 tripList: data
               })
             }
             console.log(data);
             //get latest trip for default load
             if(data && data.length > 0){
                var defaultId = data[0].id;
                console.log("default id"+defaultId);
                this.handleChangeTripSelect(defaultId);
             }
           })
          })
  }

  handleInputChange1(event){
    if(event != null){
      console.log(event.target.value);
      if(!event.target.value){
        this.setState({
          fromlocationid: "",
          inputValue1: null
        })
      }
    }
    console.log("Inside input change 1");
  }

  handleInputChange2(event){
    if(event != null){
      console.log(event.target.value);
      if(!event.target.value){
        this.setState({
          tolocationid: "",
          inputValue2: null
        })
      }
    }
    console.log("Inside input change 2");
  }

  handleTripNameChange(event){
    console.log(event.target.value);
    this.setState({
      tripName: event.target.value,
      errorTripName: false,
      tripNameErrorText: ""
    })
  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    console.log(prevState.travelerid);
    var retData = [];
    if(nextProps.searchResult.travelerid && !prevState.travelerid){
        return {travelerid: nextProps.searchResult.travelerid,
                tripid: nextProps.searchResult.tripid
                };

    }
    else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState){
    console.log(this.state.travelerid);
    console.log(this.state.tripId);
    console.log(this.state.editEnabled)
    if(this.state.travelerid && this.state.tripId && !this.state.editEnabled){
      fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/checktripforsharededit',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
              body:JSON.stringify({"travelerId": this.state.travelerid,
                                  "tripid": this.state.tripId
            })
           })
     .then(res => res.json())
     .then(data => {
       if(data && data.length > 0){
         this.setState({
           tripList: data,
           editEnabled: true,
         })
       }
       console.log(data);
       //get latest trip for default load
       if(!this.state.tripId){
         if(data && data.length > 0){
           var defaultId = data[0].id;
           console.log("default id"+defaultId);
           this.handleChangeTripSelect(defaultId);
         }
       }
     })
    }
  }

  handleEndTime(newValue){
      //console.log(event.target.value);
      console.log(newValue);

      this.setState({
        endtime: newValue,
        errorEndTime: false,
        endTimeErrorText: ""
      })
  }

  photoUpload(activityItem){
    console.log("Inside photo upload");
    console.log(activityItem);
    console.log(this.state.tripId);
    console.log(this.state.travelerid);
    const { dispatch } = this.props;
    dispatch(push('/photoupload'));
    dispatch(uploadPhotos(this.state.tripId, activityItem.id, this.state.travelerid));

  }


  handleClose3(){
    this.setState({
      open3: false
    })
  }

  handleEmailAddrChange(event){
    console.log(event.target.value);
    this.setState({
      sharedEmailAddrsStr: event.target.value
    })
  }



  handleImageExpandOpen(value2){
    console.log("inside expand image open")

    this.setState({
      openExpandPhoto: true,
      imageToExpand: value2
    })
  }

  handleImageClose(event){
    this.setState({
      openExpandPhoto: false
    })
  }

  handleDeleteImageConfirmation(photoElem, activityItemId){
     console.log("inside delete image confirmation");
     console.log(activityItemId);
     //console.log(value1)
     this.setState({
       openDeleteImageConfirmation: true,
       imageToExpand: photoElem.value1,
       imageToDelete: photoElem.key1,
       imageToDeleteActivityItemId: activityItemId
     })
  }

  handleImageSharing(photoElem, activityItem){
    console.log(photoElem);
    console.log(activityItem);
    let err = false;

    //toggle simage. limit to two images

    if(activityItem.simage1 == photoElem){
      activityItem.simage1 = null;
    }
    else if (activityItem.simage2 == photoElem){
      activityItem.simage2 = null;
    }else{
      if(activityItem.simage1 == null || !activityItem.simage1){
        activityItem.simage1 = photoElem;
      }
      else if (activityItem.simage2 == null || !activityItem.simage2){
        activityItem.simage2 = photoElem;
      }
      else{
        err = true;
        this.setState({imgShrErr: "Only two images can be shared"})
        console.log("only two images can be shared")
      }
    }


    //save to database
    if(!err){
      console.log("inside saving to database");
      fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivity/updateshareableimages',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripactivityid": activityItem.id,
                                   "simage1": activityItem.simage1,
                                   "simage2": activityItem.simage2


              })
          })
          .then((result) => {
            console.log("success in saving shareable images");
          })
    }
    //if image sharing off, turn it on, if on, turn it off
    /*if(this.state.publicPhotoSharing){
      this.setState({
        publicPhotoSharing: false
      })
    }
    else{
      this.setState({
        publicPhotoSharing: true
      })
    }*/

  }

  handleDeleteImageClose(event){
    console.log(event);
    this.setState({
      openDeleteImageConfirmation: false,
      openExpandPhoto: false
    })
  }

  handleDeleteImage(event){
    console.log(event);
    console.log(this.state.imageToDelete);
    console.log(this.state.imageToDeleteActivityItemId);
    var tripActivities = this.state.mytripactivitylist;
    Object.entries(tripActivities).map(([key, value]) => {
      value.map(value4 => {
       if(value4.id == this.state.imageToDeleteActivityItemId){
        console.log("match the id");
        Object.entries(value4.photos).map(([key5, value5]) => {
          console.log(key5)
          console.log(value5)
          console.log(value5.key1)
          console.log(this.state.imageToDelete)
          if(value5.key1 == this.state.imageToDelete){
            console.log("match");
            value4.photos.splice(key5,1)
          }//match key to the image to delete key
        })//iterating photos
       }//if value id equals tripactivityid
      })//valuemap
    })//go over trip activities
    console.log(tripActivities)

    var data3 = this.state.s3client.deleteObject({Bucket: 'itifindphotos',
                                      Key: this.state.imageToDelete}, function(err, data){
                                        if(err){
                                          console.log(err);
                                        }
                                        else{
                                          console.log("Success delete photo");

                                        }
                                      });

    console.log(data3)

    this.setState({
      mytripactivitylist: tripActivities,
      openDeleteImageConfirmation: false,
      openExpandPhoto: false,
      imageToDelete: ""
    })


  }

  getChildDestinationList(parentDestinationId){
      console.log(" parentDestination id "+parentDestinationId);
      console.log("parent destination id from state "+this.state.todestinationid);
      var childDestImgLst = []
      this.setState({
        childDestImgLst: childDestImgLst
      })
      fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/getchilddestinations',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"parentdestinationid": parentDestinationId,
              })
          })
          .then(res => res.json())
          .then(async (data) => {
            console.log(data);
            if(data && data.length > 0){
              AWS.config.credentials = new AWS.Credentials
              ({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
              })
              this.state.s3client = new AWS.S3({region: "us-east-2"});

              for(let i = 0; i < data.length; i++){
                try{
                  var data2 = await this.state.s3client.getObject({Bucket: 'tripytop',
                                                Key: data[i].childDestinationId+"_sm.jpg"}).promise();
                  var photoElem = {key2: data[i].childDestinationId+"_sm.jpg", value2: "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(data2.Body),
                                    dest: data[i].name, destType: data[i].desttype, count: data[i].count}
                  console.log(data2);
                  childDestImgLst.push(photoElem);
                  //check if it is still the same trip id before loading children
                  if(parentDestinationId == this.state.todestinationid){
                    this.setState({
                      childDestImgLst: childDestImgLst
                    })
                  }
                }
                catch(err){
                  console.log(err);
                }
              }
              console.log(childDestImgLst);
              console.log("setting state");
              this.setState({
                childDestImgLst: childDestImgLst
              })
            }

          })
  }
  encodeS3ImgBodyToData(data){
    /*var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');*/
    let buf = Buffer.from(data);
    let base64 = buf.toString('base64');
    return base64
  }

  handleTripShareImages(){
    console.log("inside save picture to share")
    //check if image already exists
    const keyVal = this.state.tripId+'.jpeg';
    console.log(keyVal);
    var tripId = this.state.tripId;
    const tempParams10 = {Bucket: 'tripytopitineraryimgs',
                        Key: keyVal}
    this.state.s3client.headObject(tempParams10, function(err, metadata){
      console.log(metadata);
      console.log(err);
      //console.log(metadata.LastModified);


      fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getlastupdatetime',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripid": tripId,
              })
          })
          .then(res => res.json())
          .then(async (data) =>  {
            console.log(data)
            var dtcompare1 = new Date(data)
            if(metadata == null || err){
              metadata = {LastModified: new Date('1850-01-01')}
            }


            console.log(typeof metadata.LastModified)
            var dtcompare2 = metadata.LastModified
            if(dtcompare1.getTime() > dtcompare2.getTime())
            {
              //create an image and save to s3 Bucket of header
              const input = document.getElementById('tripheader');
              console.log("create an image")
              var shareImgLst = [];
              this.setState({
                shareImgLst: [],
              })
              html2canvas(input, {
                useCors: true,
                optimized: false,
                allowTaint: false,

              })
              .then(canvas => {
                  var dataUrl = canvas.toDataURL("image/jpeg");
                  var blobData = dataURItoBlob(dataUrl);
                  shareImgLst.push({img: URL.createObjectURL(blobData), title: "Trip"})
                  console.log("blob generated"+blobData);

                  this.setState({
                    shareDialog: true,
                    shareImgLst: shareImgLst,
                  })
                  const keyVal = this.state.tripId+'_head.jpeg';
                  const tempParams1 = {Bucket: 'tripytopitineraryimgs',
                                      Key: keyVal,
                                      Body: blobData};
                  /*this.state.s3client.upload(tempParams1, function(err, data){
                    if(err){
                      console.log(err);
                    }
                    else{
                      console.log("Success");

                    }
                  })*/

              })//then canvas

              //get the card with day label
              //create an image and save to s3 Bucket
              var data = this.state.mytripactivitylist;
              console.log(data)

              //images to upload to imglist

              this.convertToCanvas(data)
            }//if date of update of trip is more than the date of image created
            else{
              console.log("inside else of image present ")
              this.setState({
                shareImgLst: [],
              })
              console.log("key "+this.state.tripId+".jpeg");
              const tempParams1 = {Bucket: 'tripytopitineraryimgs',
                                  Key: this.state.tripId+".jpeg"}
              var data2 =  await this.state.s3client.getObject(tempParams1).promise();

              var imgBuffer = null;
              var shareImgLst10 = [];
              if(data2.Body){
                console.log("inside data body ")
                imgBuffer = data2.Body;

                if(!data2.Body.toString().startsWith("data:image/jpeg;base64")){
                  imgBuffer = "data:image/jpeg;base64," + data2.Body.toString('base64')
                }
                shareImgLst10.push({img: "https://tripytopitineraryimgs.s3.us-east-2.amazonaws.com/"+this.state.tripId+".jpeg", title: "Trip"})
                this.setState({
                  imgURL2: "https://tripytopitineraryimgs.s3.us-east-2.amazonaws.com/"+this.state.tripId+".jpeg",
                  shareImgLst: shareImgLst10,
                  shareDialog: true,
                })
              }//if data.Body


            }//end else
      })
    }.bind(this))

    //check if date of image is greater than last update date of trip or trip activity







  }

   convertToCanvas(data){

    console.log("inside convert to canvas");
    if(data){
       console.log(data);

       Object.entries(data).map(([key, value]) => {
       console.log(key)

       var isPaused = true
       var input = document.getElementById(key);

       var topLevel1 = input.querySelectorAll('.gm-style');
       var promiseArr = [];
       var promiseArr1 = [];
       var counter1 = 0;
       var numImages = 0;


       topLevel1.forEach(tlElem2 => {
           console.log(tlElem2);
           var imgElem = tlElem2.querySelectorAll("img");
           console.log(imgElem);
           console.log(imgElem.length)
           numImages = imgElem.length;
           counter1 = 0;
           var blob;
           imgElem.forEach(m2 =>{
             console.log(m2.src);
             fetch(m2.src)
             .then((response) => {
               blob =  response.blob()
               .then((blob2) => {
                  m2.src =  URL.createObjectURL(blob2)
                  console.log(m2.src)
                  counter1 = counter1 + 1;
                  console.log(counter1);
                  if(counter1 == numImages){
                    console.log(counter1);
                    html2canvas(input, {
                      useCors: true,
                      optimized: false,
                      allowTaint: false,
                      ignoreElements: (node) => {
                         return node.nodeName === 'IFRAME';
                      },
                    })
                    .then(canvas => {
                        console.log(canvas);

                        var dataUrl = canvas.toDataURL("image/jpeg");
                        var blobData = dataURItoBlob(dataUrl);
                        var shareImgLst = this.state.shareImgLst;
                        console.log(shareImgLst);
                        shareImgLst.push({img: URL.createObjectURL(blobData), title: "Trip"})

                        this.setState({
                          shareImgLst: shareImgLst
                        })
                        var input = document.getElementById("finalImgLst");
                        console.log(input)
                        html2canvas(input, {
                          useCors: true,
                          optimized: false,
                          allowTaint: false,
                          ignoreElements: (node) => {
                             return node.nodeName === 'IFRAME';
                          },
                        })
                        .then(canvas => {
                            console.log(canvas);

                            var dataUrl = canvas.toDataURL("image/jpeg");
                            var blobData = dataURItoBlob(dataUrl);
                            console.log(blobData);
                            var imgURL2 = URL.createObjectURL(blobData);
                            console.log(imgURL2);
                            //save on aws
                            console.log("uploading the image");
                            //const prefixVal = 'tripytopitineraryimgs';
                            const tempParams1 = {Bucket: 'tripytopitineraryimgs',
                                                Key: this.state.tripId+".jpeg",
                                                Body: new File([blobData], this.state.tripId+".jpeg")};
                            this.state.s3client.upload(tempParams1, function(err, data){
                              if(err){
                                console.log(err);
                              }
                              else{
                                console.log("Success");
                              }
                            })

                            //imgURL2 = imgURL2.substring(5)
                            this.setState({
                              imgURL2: imgURL2
                            })

                            console.log("after setting image url")
                        })//end then
                    })//end then
                  }//counter
                })//blob
             })//fetch
           })// for each img elem
         })//.gm-style element
       })//object.entries
    }//if data
  }//end convert to canvas



  handleCloseShareTrip(){
    this.setState({
      shareDialog: false,
    })
  }

  handleCloseNShareFwd(){
    this.setState({
      shareDialog: false,
    })

  }

  handleImageGen(event){
    console.log("Inside handle image gen")
    console.log(event)
    var input = document.getElementById("finalImgLst");
    console.log(input)
    html2canvas(input, {
      useCors: true,
      optimized: false,
      allowTaint: false,
      ignoreElements: (node) => {
         return node.nodeName === 'IFRAME';
      },
    })
    .then(canvas => {
        console.log(canvas);

        var dataUrl = canvas.toDataURL("image/jpeg");
        var blobData = dataURItoBlob(dataUrl);
        console.log(blobData);
        var imgURL2 = URL.createObjectURL(blobData);
        console.log(imgURL2);
        //imgURL2 = imgURL2.substring(5)
        this.setState({
          imgURL2: imgURL2
        })
        console.log("after setting image url")
      })//end then
  }


  render(){
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: 300,
        },
      },
    };
    return <div>

              <PageHeader topName="Sign In" className="menubartop" dispatch={this.props.dispatch}
                    travelerid={this.props.travelerid} displayName={this.props.displayName}>
              </PageHeader>
              <PageMenu dispatch={this.props.dispatch} menuSelectedValue="3">
              </PageMenu>
              <div className="selectplacement">
                {(!this.state.travelerid)? "Sign in to see your trips." :
                  <FormControl fullWidth style = {{marginTop: "26px", paddingBottom: "26px"}}>
                    <InputLabel id="tripTypeLabel">Trip List</InputLabel>
                    <Select
                     id="selectbox1"
                     onChange={this.handleChangeTripSelect}
                     MenuProps={MenuProps}
                     value={this.state.selectedOption}
                     variant="standard"
                     >
                     {this.state.tripList.map(option2 => (
                       <MenuItem key={option2.id} value={option2.id}>
                         {option2.name}
                         </MenuItem>
                       ))}
                    </Select>

                  </FormControl>
                }
              </div>
              {(this.state.tripSelected)?
                <div className="shareposition">
                <Dialog open={this.state.shareDialog} onClose={this.handleCloseShareTrip} aria-labelledby="form-dialog-title"
                        aria-describedby="test1" fullWidth={true}>
                  <HeaderTag img={this.state.imgURL2}/>

                  <DialogTitle id="form-dialog-title1">Share for Edit</DialogTitle>

                  <DialogContent>

                  <Typography variant="h6" color="textSecondary" style={{paddingLeft: "16px", paddingBottom: "2px"}}>
                    share:
                    &nbsp;
                    <EmailShareButton id="emailShare" url={"https://share.roamworth.com/?tripid="+this.state.tripId}
                                      subject="Checkout my trip"
                                      body="Checkout my trip"
                                      imageUrl={this.state.imgURL2}
                                      beforeOnClick={this.handleImageGen}
                                      >
                      <EmailIcon size={18} round={false}/>
                    </EmailShareButton>
                    &nbsp;
                    <FacebookShareButton id="facebookShare" url={"https://share.roamworth.com/?tripid="+this.state.tripId}
                                         quote="Checkout my trip "
                                         className="share"
                                         imageUrl={this.state.imgURL2}>
                      <FacebookIcon size={18} round={false}/>
                    </FacebookShareButton>
                    &nbsp;
                    <TwitterShareButton id="twitterShare" url={"https://share.roamworth.com/?tripid="+this.state.tripId}
                                        title="Checkout my trip"
                                        imageUrl={this.state.imgURL2}>
                      <TwitterIcon size={18} round={false}/>
                    </TwitterShareButton>
                    &nbsp;
                    <WhatsappShareButton id="whatsappShare" url={"https://share.roamworth.com/?tripid="+this.state.tripId}
                                          title="Checkout my trip"
                                          className="share"
                                          imageUrl={this.state.imgURL2}>
                      <WhatsappIcon size={18} round={false}/>
                    </WhatsappShareButton>
                    &nbsp;
                    <IconButton id="share" size="small" style={{color: "rgba(0,150,166,1.0)", paddingBottom: "10px"}} onClick={this.handleClickShare}>
                      <ShareIcon fontSize="small"/>
                    </IconButton>

                  </Typography>

                    <Box>
                      <ImageList id="finalImgLst" variant="masonry" cols={2} gap={6}>
                        {this.state.shareImgLst.map((item) => (
                          <ImageListItem >
                            <img
                              src={item.img}
                              alt={item.title}
                              loading="lazy"
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                      <Button id="cancelEmailShare" onClick={this.handleCloseShareTrip} color="primary">
                        Close
                      </Button>
                  </DialogActions>
                  </Dialog>
                  <Card id="tripheader" variant="outlined" style={{backgroundColor: "#f5f5f5", maxWidth:"1200px"}}>
                    <CardContent style={{paddingBottom: "1px", paddingTop: "1px"}}>
                      <Typography variant="body2">
                        {this.state.tripName} - {this.state.tripToDestinationName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {this.state.displayDateRange}
                      </Typography>
                    </CardContent>
                    <CardActions style={{paddingTop: "0px", paddingBottom: "1px"}}>
                      <Button id="editTrip" size="small" style={{color: "rgba(0,150,166,1.0)"}} onClick={() => this.editTrip()}>
                        Edit
                      </Button>
                      <Button id="deleteTrip" size="small" style={{color: "rgba(0,150,166,1.0)"}} onClick={() => this.deleteTrip()}>
                        Delete
                      </Button>
                    </CardActions>
                  </Card>
              </div>
              : ""

              }
              <Dialog open={this.state.open3} onClose={this.handleClose3} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
                <DialogTitle id="form-dialog-title">Share for Edit</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="emailAddrs3"
                    label="Email Address(comma seperated)"
                    fullWidth
                    defaultValue={this.state.emailAddrs3}
                    onChange={this.handleEmailAddrChange}
                    error={this.state.errorNoEmails}
                    helperText={this.state.emailHelperText}
                  />
                </DialogContent>
                <DialogActions>
                    <Button id="saveEmailShare" onClick={this.handleSaveEmailShare} color="primary">
                      Save
                    </Button>
                    <Button id="cancelEmailShare" onClick={this.handleClose3} color="primary">
                      Cancel
                    </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={this.state.open2} onClose={this.handleClose2} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
                <DialogTitle id="form-dialog-title">Trip</DialogTitle>
                <DialogContent>
                  <TextField style={{marginBottom: "10px"}}
                    autoFocus
                    margin="dense"
                    id="tripName"
                    label="Trip Name"
                    fullWidth
                    defaultValue={this.state.tripName}
                    onChange={this.handleTripNameChange}
                    error={this.state.errorTripName}
                    helperText={this.state.tripNameErrorText}
                  />
                  <Autocomplete style={{marginBottom: "10px"}}
                    id="google-map-demo"
                    getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                    filterOptions={(x) => x}
                    options={this.state.options3}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    onChange={this.handleDestinationSelected}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        fullWidth
                        onChange={this.handleChange3}
                        error={this.state.errorToDestinationId}
                        helperText={this.state.toDestinationIdErrorText}
                      />
                    )}
                    value ={this.state.tripToDestinationName}
                    renderOption={(props, option) => {
                      const matches = option.structured_formatting.main_text_matched_substrings || [];
                      const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match: any) => [match.offset, match.offset + match.length]),
                      );
                      return (
                        <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item xs>
                              {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                  {part.text}
                                </span>
                              ))}

                              <Typography variant="body2" color="textSecondary">
                                {option.structured_formatting.secondary_text}
                              </Typography>
                            </Grid>
                          </Grid>
                        </li>
                      );
                    }}
                  />
                    <TextField style={{marginBottom: "10px"}}
                      id="dateRange"
                      multiple
                      fullWidth
                      label="From - To Dates"
                      value={this.state.displayDateRange}
                      helperText={this.state.dateRangeErrorText}
                      error={this.state.errorDateRange}
                      onClick={this.handleDateRangeClick}
                    />
                    <Dialog open={this.state.openDateRange} onClose={this.handleCloseDateRange} aria-labelledby="alert-dialog-title" fullWidth={true} maxWidth={'md'}>
                      <DialogTitle id="form-dialog-title2">From-To Dates</DialogTitle>
                      <DialogContent>
                      <DateRangePicker
                        ranges={[this.state.dateRange]}
                        onChange={this.setActivityDateRange}
                        showDateDisplay={false}
                        className="date_size"
                        staticRanges={[]}
                        inputRanges={[]}
                      />
                      </DialogContent>
                      <DialogActions>
                        <Button id="closeDateRange" onClick={this.handleCloseDateRange} color="primary">
                          Cancel
                        </Button>
                        <Button id="chooseDateRange" onClick={this.handleCloseDateRange} color="primary">
                          Ok
                        </Button>
                      </DialogActions>
                    </Dialog>
                </DialogContent>
                <DialogActions>
                  <Button id="editTripCancel" onClick={this.handleClose2} color="primary">
                    Cancel
                  </Button>
                  <Button id="editTripSave" onClick={this.editTripSave} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>


              {(this.state.tripSelected)?
                <div className="addcardposition">
                  <ButtonGroup variant="outlined" fullWidth >
                    <Button id="addTripActivities" style={{color: "rgba(0,150,166,1.0)"}} onClick={this.handleClickOpen}>
                      Add Trip Activities
                    </Button>

                  </ButtonGroup>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header" style={{color: "rgba(0,150,166,1.0)", borderTop: "1px solid rgba(0,150,166,1.0)",
                                                    borderBottom: "1px solid rgba(0,150,166,1.0)",
                                                    borderRight: "1px solid rgba(0,150,166,1.0)",
                                                    borderLeft: "1px solid rgba(0,150,166,1.0)",
                                                    fontWeight: "500",
                                                    fontSize: "0.875rem"}} >
                      POPULAR SPOTS
                    </AccordionSummary>
                    <AccordionDetails>
                      <ImageList cols={2} rowHeight={164} sx={{ height: 200, maxWidth:1200 }} >
                        {(this.state.childDestImgLst && this.state.childDestImgLst.length > 0)?this.state.childDestImgLst.map((tile) => (
                          <ImageListItem key={tile.key2}>
                            <img src={tile.value2}/>
                            <ImageListItemBar
                              title={tile.dest}
                              subtitle={tile.destType}
                              actionIcon={
                                <IconButton onClick={() => this.handleClickOpen2(tile)}
                                  style={{color: "white"}}>
                                  <ControlPointIcon/>
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        )):"Popular Trip Activities to choose from will be available at a later time"}
                      </ImageList>
                    </AccordionDetails>
                  </Accordion>
                  <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title" fullWidth={true} maxWidth={'md'}>
                    <DialogTitle id="form-dialog-title2">Trip Activity</DialogTitle>
                    <DialogContent>
                      <Autocomplete style={{marginBottom: "5px", marginTop: "10px"}}
                        id="google-map-demo2"
                        autoFocus
                        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                        filterOptions={(x) => x}
                        options={this.state.options2}
                        autoComplete
                        includeInputInList
                        fullWidth
                        filterSelectedOptions
                        onChange={this.handleToLocationSelected}
                        onInputChange={this.handleInputChange2}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="To Location"
                            fullWidth
                            onChange={this.handleChange2}
                            error={this.state.errorToLocationId}
                            helperText={this.state.toLocationIdErrorText}
                          />
                        )}
                        value={this.state.inputValue2}
                        renderOption={(props, option) => {
                          const matches = option.structured_formatting.main_text_matched_substrings || [];
                          const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [match.offset, match.offset + match.length]),
                          );
                          return (
                            <li {...props}>
                              <Grid container alignItems="center">
                                <Grid item xs>
                                  {parts.map((part, index) => (
                                    <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                      {part.text}
                                    </span>
                                  ))}
                                  <Typography variant="body2" color="textSecondary">
                                    {option.structured_formatting.secondary_text}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />

                      <div style={{marginBottom: "10px"}}>
                      <LocalizationProvider dateAdapter={AdapterMoment} >
                        <DemoContainer components={['DateTimePicker']} >
                          <DateTimePicker
                            id="datetime-local"
                            label="Arrived At"
                            value={this.state.endtime}
                            onChange={(newValue) => this.handleEndTime(newValue)}
                            fullWidth
                            error={this.state.errorEndTime}
                            helperText={this.state.endTimeErrorText}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      </div>
                      <FormControl fullWidth={true} maxWidth={'md'} >
                        <InputLabel id="demo-simple-select-helper-label">Activity Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={this.state.activitytype}
                          label="Activity Type"
                          onChange={this.handleTripActivityTypeChange}
                          error={this.state.errorActivityType}
                        >
                        {this.state.tripactivitytypes.map((name) => (
                          <MenuItem key={name.id} value={name.id} >
                            {camelize(name.name)}
                          </MenuItem>
                        ))}
                        </Select>
                        <FormHelperText>{this.state.activityTypeErrorText}</FormHelperText>
                      </FormControl>

                      <TextField style={{marginBottom: "10px"}}
                        margin="dense"
                        id="tripActivityName"
                        label="Comments"
                        fullWidth
                        onChange={this.handleActivityName}
                        defaultValue={this.state.name}
                      />




                    </DialogContent>
                    <DialogActions>
                      <Button id="cancelsaveTripActivity" onClick={this.handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button id="saveTripActivity" onClick={this.handleSaveTripActivity} color="primary">
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                : ""
              }
               <div className="cardposition">
               <Grid container spacing={1} style={{maxWidth: "1200px"}}>

                {(this.state.mytripactivitylist)?Object.entries(this.state.mytripactivitylist).map(([key, value]) =>
                  <Grid item xs={12} sm={6} >
                  <div id={key} key={key} style={{maxWidth:"1200px", marginTop:"5px"}}>
                    <Typography key={key} variant="body2" color="textSecondary" style={{backgroundColor: "#A9A9A9"}}>
                     {key=='null'?"Add Activities to see markers on map":key}
                    </Typography>
                    <MyMapComponent2
                          zoom={this.state.zoom}
                          center={this.state.center}
                          mytripactivitylist={this.state.mytripactivitylist}
                          polylinecoords={this.state.polylinecoordsList[Number(key.split(" ")[1])-1]}
                          origin={this.state.originsList[Number(key.split(" ")[1])-1]}
                          destination={this.state.destinationsList[Number(key.split(" ")[1])-1]}
                          waypoints={this.state.waypointsList[Number(key.split(" ")[1])-1]} />

                    <Grid container spacing={1}>
                      {value.map (activityItem =>
                         <Grid key={activityItem.id} item xs={12} >
                          <Card key={activityItem.id}  variant="outlined" elevation={3} style={{backgroundColor: activityItem.futureFlag? "": "rgba(66, 97, 3, 0.05)"}}>
                              <CardHeader avatar={getIconForActivity(camelize(activityItem.tripactivitytypename))}
                               title={camelize(activityItem.tripactivitytypename).concat(" - ", activityItem.name)}
                               style={{paddingBottom: "0px"}}
                              />
                              <CardMedia>
                                <ImageList>
                                  {activityItem.photos?activityItem.photos.map((tile) => (
                                    <ImageListItem key={tile.key1} onClick={() => this.handleImageExpandOpen(tile.value1)}>
                                      <img src={tile.value1} />
                                      <ImageListItemBar style={{background: "transparent"}}
                                        actionIcon={
                                          <div>
                                            <IconButton id="deleteImage" style={{color: "white"}} onClick={() => this.handleDeleteImageConfirmation(tile, activityItem.id)}>
                                              <DeleteIcon style={{fontSize: "medium"}}/>
                                            </IconButton>
                                            {(activityItem.simage1 == tile.key1 || activityItem.simage2 == tile.key1)?
                                              <IconButton id="changeImageSharing" style={{color: "white"}} onClick={() => this.handleImageSharing(tile.key1, activityItem)}>
                                                <PeopleIcon style={{fontSize: "medium"}}/>
                                              </IconButton>:
                                              <IconButton id="changeImageSharing" style={{color: "white"}} onClick={() => this.handleImageSharing(tile.key1, activityItem)}>
                                                <VisibilityOffIcon style={{fontSize: "medium"}}/>
                                              </IconButton>
                                            }
                                          </div>

                                        }
                                        actionPosition="right"
                                      />
                                    </ImageListItem>
                                  )):""}
                                </ImageList>
                              </CardMedia>
                              <CardContent style={{paddingTop: "0px"}}>
                                <Typography variant="body2" color="textSecondary" component="p" >
                                  {activityItem.tolocation} <br/>
                                  {activityItem.endTimeDisplay}
                                </Typography>
                                <Typography variant="caption" color="error" component="p">
                                  {this.state.mediaLengthError}
                                </Typography>
                              </CardContent>
                            <CardActions>
                              <Button id="editTripActivity" size="small" onClick={() => this.editTripActivity(activityItem)} style={{color: "rgba(0,150,166,1.0)"}}>
                                Edit
                              </Button>
                              <Button id="deleteTripActivity" size="small" onClick={() => this.deleteTripActivity(activityItem)} style={{color: "rgba(0,150,166,1.0)"}}>
                                Delete
                              </Button>
                              <input
                                 accept="image/*"
                                 style={{ display: 'none' }}
                                 id="raised-button-file"
                                 multiple
                                 type="file"
                                 onChange={(event) => this.handleFileSelected(event)}
                                />
                              <label htmlFor="raised-button-file">
                                <Button id="photos" size="small"  component="span" onClick={() => {this.state.tripActivityId = activityItem.id}} style={{color: "rgba(0,150,166,1.0)"}}>
                                  Photos
                                </Button>
                              </label>
                            </CardActions>
                          </Card>

                          <Dialog open={this.state.openExpandPhoto} onClose={this.handleImageClose} aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'}>

                            <DialogContent>
                              <img src={this.state.imageToExpand} style={{maxWidth:"100%", maxHeight:"100%"}}/>
                            </DialogContent>
                            <DialogActions>
                              <Button id="imageClose" onClick={this.handleImageClose} color="primary">
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                          <Dialog open={this.state.openDeleteImageConfirmation} onClose={this.handleDeleteImageClose} aria-labelledby="responsive-dialog-title" fullWidth={true} maxWidth={'md'}>

                            <DialogContent>
                              <img src={this.state.imageToExpand} style={{maxWidth:"100%", maxHeight:"100%"}}/>
                              Are you sure you want to delete the image?
                            </DialogContent>
                            <DialogActions>
                              <Button id="photoDeleteCancel" onClick={this.handleDeleteImageClose} color="primary">
                                Do Not Delete
                              </Button>
                              <Button id="photoDeleteConfirmation" onClick={this.handleDeleteImage} color="primary">
                                Delete
                              </Button>
                            </DialogActions>
                          </Dialog>
                         </Grid>
                        )}
                      </Grid>
                      <Button id="addTripActivities" variant="outlined" fullWidth style={{color: "rgba(0,150,166,1.0)", paddingLeft: "20px"}} onClick={() => this.handleClickOpen(key, value)}>
                          Add Trip Activities to the Day
                      </Button>

                  </div>
                  </Grid>
                ):""}

              </Grid>
               </div>

           </div>
  }
}

const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);
  return state;
  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
}


export default connect(mapStateToProps)(Memories)
