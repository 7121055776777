import React, {Component} from "react";
import SelectTripType from './SelectTripType';
import SelectDestinationFrom from './SelectDestinationFrom';
import SelectDestinationTo from './SelectDestinationTo';
import '../styles/button.css';
import 'react-dates/initialize';
//import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/SearchCriteria.css';
import moment from 'moment';
/*import SearchResult from './SearchResult';
import {BrowserRouter, Route} from 'react-router';
import {Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';*/
import {replace, push} from 'react-router-redux';
import {getAllSearchCriteria, getSearchResult} from '../action';
import {Field, reduxForm} from 'redux-form';
//import {bindActionCreators} from 'redux';
//import { MuiPickersUtilsProvider, DatePicker } from '@mui/pickers';
//import {Fragment, useState} from "react";
import DateFnsUtils from "@date-io/date-fns";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';


export default class SearchCriteria extends Component {
  constructor(props){
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleFocusChangeDate = this.handleFocusChangeDate.bind(this);
    this.handleChangeDateFrom = this.handleChangeDateFrom.bind(this);
    this.handleChangeDateTo = this.handleChangeDateTo.bind(this);
    this.handleChangeTravelerCount = this.handleChangeTravelerCount.bind(this);
    this.handleChangeBudget = this.handleChangeBudget.bind(this);
    this.handleChangeTripType = this.handleChangeTripType.bind(this);
    this.handleChangeDestinationFrom = this.handleChangeDestinationFrom.bind(this);
    this.handleChangeDestinationTo = this.handleChangeDestinationTo.bind(this);

    //if current date is a weekday, default start date is sat and end date is sunday
    //if current date is a weekend, default start date is next sat and end date is next sunday
    //default destination from should be the current location of the browser
    this.state = {
      name: '',
      startDate: props.initialStartDate,
      endDate: props.initialEndDate,
      travelerCount: 1,
      budget: 2000,
      focusedInput: null,
      tripTypeId: '',
      destinationFrom: '',
      destinationTo: '',
      searchresults: {}
    }

  }

  handleSubmit(event){
    console.log("Inside submit");

    event.preventDefault();
    const data = new FormData(event.target);
    console.log("onsubmit state"+this.state.name);
    console.log("form data"+data);
    const { dispatch } = this.props;
    dispatch(push('/search'));
    dispatch(getSearchResult(this.state));

  }

 handleChangeName(event){
   event.preventDefault();
   console.log('event target value'+event.target.value);
   this.setState({"name": event.target.value});

 }
 handleChangeTravelerCount(event){
   event.preventDefault();
   console.log('event target value'+event.target.value);
   this.setState({"travelerCount": event.target.value});

 }
 handleChangeDate(input){
   console.log("start date ",input["startDate"])
   console.log("end date ",input["endDate"])
   this.setState({
     startDate: input["startDate"]? input["startDate"].format('YYYY-MM-DD'):null ,
     endDate: input["endDate"]? input["endDate"].format('YYYY-MM-DD'):null ,
   });
 }
 handleChangeDateFrom(input){
   console.log(input)
   if(input != null) {
     var input1 = new Date(input.getFullYear(), input.getMonth(), input.getDate())
     console.log(input1)
     var a = moment(input1);
     console.log(a.format('YYYY-MM-DD'));
     this.setState({
       startDate: a
     });
   }
   else {
     this.setState({
       startDate: null
     });
   }
   //console.log("end date ",input["endDate"])

 }
 handleChangeDateTo(input){
   console.log(input)
   if(input != null) {
     var a = moment(input);
     console.log(a.format('YYYY-MM-DD'));
     this.setState({
       endDate: a
     });
   }
   else {
     this.setState({
       endDate: null
     });
   }
 }
 handleChangeBudget(event){
   event.preventDefault();
   console.log('event target value'+event.target.value);
   this.setState({"budget": event.target.value});
 }

 handleFocusChangeDate(focusedInput){
   console.log('focused input '+focusedInput);
   this.setState({ "focusedInput": focusedInput });

 }
 handleChangeTripType(selectedOption){
    console.log(`Option selected in search criteria:`, selectedOption);
    this.setState({"tripTypeId": selectedOption.value})
    //this.setState({"tripTypeName": selectedOption.})
 }
 handleChangeDestinationFrom(selectedOption){
    console.log(`Option selected in search criteria:`, selectedOption.value);
    this.setState({"destinationFrom": selectedOption.value})
 }
 handleChangeDestinationTo(selectedOption){
    console.log(`Option selected in search criteria:`, selectedOption.value);
    this.setState({"destinationTo": selectedOption.value})
 }


  render(){
    //const {getSearchResult} = this.props;

    return <form onSubmit={this.handleSubmit}>
            {/* <div className="searchcriteriagrid"> */}
            <div className="searchcriteriamaterialgrid">
              <Grid container spacing={4}>
                
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SelectDestinationFrom className="searchcriteriatext" onChange={this.handleChangeDestinationFrom}>From</SelectDestinationFrom>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SelectDestinationTo className="searchcriteriatext" onChange={this.handleChangeDestinationTo}>To</SelectDestinationTo>
                    </FormControl>
                  </Grid>
                  {/*
                <div>

                <div className="searchcriteriatext">
                  <input id="travelerCount" type="text" placeholder="Traveler Count" value={this.state.travelerCount} onChange={this.handleChangeTravelerCount}/>
                </div>
                </div>
                */}
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      id="travelerCount"
                      label="Number of Travelers"
                      defaultValue="1"
                      value={this.state.trevelerCount}
                      onChange={this.handleChangeTravelerCount}
                    />
                  </FormControl>
                </Grid>
                {/*<div>
                <hr className="searchcriterialine">
                </hr>
                <div className="searchcriteriatext">
                  <input id="budget" type="text" placeholder="Budget" value={this.state.budget} onChange={this.handleChangeBudget}/>
                </div>
                </div>
                */}
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        id="budget"
                        label="Budget"
                        defaultValue="1000"
                        value={this.state.budget}
                        onChange={this.handleChangeBudget}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <SelectTripType className="searchcriteriatext" onChange={this.handleChangeTripType}>Trip Type</SelectTripType>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                    <TextField
                      id="name"
                      label="Trip Name"
                      value={this.state.name}
                      onChange={this.handleChangeName}
                    />
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
              {/*
              <div className="line1">
                <hr className="searchcriterialine">
                </hr>
                <div className="searchcriteriatext">
                  <input id="name" type="text" placeholder="Trip Name" value={this.state.name} onChange={this.handleChangeName}/>
                </div>
              </div>
              */}
          {/* </div> */}
           <button className="button">Search</button>
          </form>



  }
}

SearchCriteria = reduxForm({
  form: 'SearchCriteria',
  fields: ["name", "startDate", "endDate", "travelerCount", "spend", "tripTypeId", "destinationFrom", "destinationTo"]
})(SearchCriteria);



/*export default connect(

  mapDispatchToProps
)(SearchCriteria);*/
