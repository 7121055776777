import React, { Component } from "react";
import PageHeader from './PageHeader';
import PageMenu from './PageMenu';
import SearchCriteria from './SearchCriteria';
import ReactDOM from "react-dom";
import Button from '@mui/material/Button';
import {connect} from 'react-redux';


class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      travelerid: props.travelerid
    }
  }

  handleloginsuccess(travelerid){
      this.state.travelerid = travelerid
  }

  render() {
    return <div>
              <PageHeader topName="Sign In" dispatch={this.props.dispatch}>
              </PageHeader>
              <PageMenu dispatch={this.props.dispatch} menuSelectedValue="0">
              </PageMenu>
              <SearchCriteria/>
          </div>
  }
}

export default connect()(App);
