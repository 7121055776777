import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

export default function PopularSpotImages(args) {
  console.log(args);
  var inp1 = args.inp;
  console.log(inp1);
  const matches = useMediaQuery('(min-width:600px)');

  console.log(matches);

  return   <ImageList sx={{
                            gridAutoFlow: "column",
                            gridTemplateColumns: "repeat(auto-fill,minmax(160px,1fr)) !important",
                            gridAutoColumns: "minmax(160px, 1fr)"
      }}
     >
    {(inp1 && inp1.length > 0)?inp1.map((tile) => (
      <ImageListItem key={tile.key2}>
        <img src={tile.value2}/>
        <ImageListItemBar
          title={tile.dest}
          subtitle={tile.destType}
          actionIcon={
            <IconButton onClick={() => args.parent.handleClickOpen2(tile)}
              style={{color: "white"}}>
              <ControlPointIcon/>
            </IconButton>
          }
        />
      </ImageListItem>
    )):"Popular Trip Activities to choose from will be available at a later time"}
  </ImageList>;
}
