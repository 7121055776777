import React, {Component, useEffect} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {searchResult} from '../reducers';
import {connect} from 'react-redux';
import {getSearchResult, createTrip, editTrip} from '../action';
import PageSubMenu1 from './PageSubMenu1';
import PageHeaderData from './PageHeaderData';
import moment from 'moment';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {push} from 'react-router-redux';
import {getSearchResultRefined} from '../action';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import PageHeader from './PageHeader';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import BeachIcon from '@mui/icons-material/BeachAccess';
import PoolIcon from '@mui/icons-material/Pool';
import FlightIcon from '@mui/icons-material/Flight';
import MuseumIcon from '@mui/icons-material/Museum';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import WalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveIcon from '@mui/icons-material/DriveEta';
import BicycleIcon from '@mui/icons-material/DirectionsBike';
import BoatIcon from '@mui/icons-material/DirectionsBoat';
import Rating from '@mui/material/Rating';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HotelIcon from '@mui/icons-material/Hotel';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import ToysIcon from '@mui/icons-material/Toys';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';
import BrushIcon from '@mui/icons-material/Brush';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import DirectionsRailwayIcon from '@mui/icons-material/DirectionsRailway';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CardMedia from '@mui/material/CardMedia';
import default1 from '../img/default.jpg';
import '../styles/selectbelowmenu.css';
import Grid from '@mui/material/Grid';
import RowingIcon from '@mui/icons-material/Rowing';
import TagManager from 'react-gtm-module';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
//import InfiniteLoader from "react-window-infinite-loader";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import parse from 'autosuggest-highlight/parse';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControl from '@mui/material/FormControl';
import InfiniteScroll from 'react-infinite-scroll-component';
import LinearProgress from '@mui/material/LinearProgress';
import WineBarIcon from '@mui/icons-material/WineBar';

const autocompleteService = { current: null };

function compareActivities(activity1, activity2, destination1, destination2, duration1, duration2){
  if(destination1 && destination2){
    if(destination1 != destination2)
      return false;
  }
  if(activity1 && activity2){
    if(activity1.length != activity2.length)
      return false;
    for(let i =0; i<activity1.length; i++){
      if (activity1[i] != activity2[i])
        return false;
    }
  }//end of activity compare
  if(duration1 && duration2){
    if(duration1 != duration2){
      return false
    }
  }//end of check for duration
  return true;
}
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
}

function adSenseScript(){
  var externalScript   = document.createElement("script");
  externalScript.type  = "text/javascript";
  externalScript.setAttribute('async','async');
  externalScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6345293511201782";
  externalScript.crossorigin = "anonymous"
  document.getElementsByTagName('head')[0].appendChild(externalScript);
}

//convert destination to string to look for img file
function stringToDestination(input){

  var firstPart = input.substr(0, input.indexOf(','));
  console.log(firstPart);
  if(!firstPart){
    input = input.split(' ').join('');
    return input.toLowerCase();
  }
  var secondPart = firstPart.toLowerCase();
  console.log(secondPart);
  var thirdPart = secondPart.split(' ').join('');
  console.log(thirdPart);
  thirdPart = thirdPart.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  thirdPart = thirdPart.split('.').join("");
  return thirdPart;
}

function getIconForActivity(activity){
  var icon = null;
  switch(activity){
    case "Snorkel":
    case "Swimming":
      icon = <PoolIcon/>;
      break;
    case "Flight":
      icon = <FlightIcon/>;
      break;
    case "Museum":
      icon = <MuseumIcon/>;
      break;
    case "Walking":
    case "Hiking":
      icon = <WalkIcon/>;
      break;
    case "Driving":
      icon = <DriveIcon/>;
      break;
    case "Bicycling":
    case "Biking":
    case "Hydrobiking":
      icon = <BicycleIcon/>;
      break;
    case "Restaurant":
      icon = <RestaurantIcon/>;
      break;
    case "Boating":
      icon = <BoatIcon/>;
      break;
    case "Swimming":
        icon = <PoolIcon/>;
        break;
    case "Iceskating":
    case "Skiing":
        icon = <AcUnitIcon/>;
        break;
    case "Hotel":
        icon = <HotelIcon/>;
        break;
    case "Concert":
        icon = <MusicNoteIcon/>;
        break;
    case "Thrillrides":
        icon = <ToysIcon/>;
        break;
    case "Golf":
    case "Minigolf":
        icon = <GolfCourseIcon/>;
        break;
    case "Artgalleries":
        icon = <BrushIcon/>;
        break;
    case "Kayak":
    case "Canoe":
        icon = <RowingIcon/>;
        break;
    case "Sightseeing":
        icon= <TravelExploreIcon/>;
        break;
    case "Train":
        icon=<DirectionsRailwayIcon/>;
        break;
    case "Winery":
    case "Vineyard":
        icon=<WineBarIcon/>;
        break;
    default:
        icon = <BeachIcon/> ;
  }
  return icon;
}

const tagManagerArgs = {
    dataLayer: {
        userProject: 'itifind.com',
        page: 'searchresultpopular',
        event: 'pageview'
    },
    dataLayerName: 'PageDataLayer'
}
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  //script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
  console.log("load script");
}
function camelizeWSpc(str){
  if(str){
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    });
  }
  else{
    return str;
  }
}

class PopularTrips extends Component {
  _isMounted = false;
  constructor(props){
    super(props);
    this.handleCreateNewTrip = this.handleCreateNewTrip.bind(this);
    this.handleSearchDetail = this.handleSearchDetail.bind(this);
    this.handleCloneTrip = this.handleCloneTrip.bind(this);
    this.camelize = this.camelize.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.encodeS3ImgBodyToData = this.encodeS3ImgBodyToData.bind(this);
    this.addImagesToList = this.addImagesToList.bind(this);
    this.loadMoreItems = this.loadMoreItems.bind(this);
    this.handleDestinationClick = this.handleDestinationClick.bind(this);
    this.handleCloseDestinationDialog = this.handleCloseDestinationDialog.bind(this);
    this.handleDestinationSelected = this.handleDestinationSelected.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDurationClick = this.handleDurationClick.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.handleCloseDurationDialog = this.handleCloseDurationDialog.bind(this);
    this.handleTripTypeClick = this.handleTripTypeClick.bind(this);
    this.handleTripTypeId = this.handleTripTypeId.bind(this);
    this.handleCloseTripTypeDialog = this.handleCloseTripTypeDialog.bind(this);
    this.handleActivityTypeNamesClick = this.handleActivityTypeNamesClick.bind(this);
    this.handleCloseActivityTypeDialog = this.handleCloseActivityTypeDialog.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChipDelete = this.handleChipDelete.bind(this);
    this.handleTravelerProfileClick = this.handleTravelerProfileClick.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.handleCloseTravelerDialog = this.handleCloseTravelerDialog.bind(this);
    this.handleScrollData = this.handleScrollData.bind(this);
    this.state = {
      searchResultData: [],
      loading: true,
      searchCriteria: props.searchResult,
      searchCriteriaParsed: [],
      activitysteps: 1,
      travelerid: props.searchResult.travelerid,
      fromdestinationid: props.fromdestinationid?props.fromdestinationid:8,
      open: false,
      activityTypeNames: props.searchResult.activitytypenames,
      destinationTo: props.searchResult.destinationto,
      displayName: props.searchResult.displayName,
      duration: props.searchResult.duration,
      tripTypeId: props.searchResult.tripTypeId,
      profileName: props.searchResult.profileName,
      data:[],
      isItemLoaded: true,
      hasNext: false,
      searchResultCount: 1,
      tripType:null,
      activityTypeNamesDisplay:"",
      destinationDialogOpen:false,
      options: [],
      durationDialogOpen: false,
      tripTypeDialogOpen: false,
      tripTypes: [],
      activityTypeDialogOpen: false,
      tripactivitytypes: [],
      tripActivityTypeNames: [],
      travelerDialogOpen: false,
      profileNames: [],
      reloadForPagination: false,
    };
    console.log("traveler id "+this.state.travelerid);
    console.log(this.state.searchCriteria);
    console.log(this.state.searchCriteria.destinationto);
    console.log(this.state.destinationTo);
    //console.log(this.state.searchCriteria.searchCriteria);
    console.log(this.state.searchCriteria.duration);
    console.log(this.state.duration);
    console.log(this.state.searchCriteria.tripTypeId);
    console.log(this.state.tripTypeId);
    console.log(this.state.searchCriteria.profileName);
    console.log(this.state.profileName);
    console.log(this.state.searchCriteria.activitytypenames);
    console.log(this.state.activityTypeNames);

    console.log(typeof window);
    console.log(!this.state.loaded);
    if (typeof window !== 'undefined' && !this.state.loaded) {
      console.log("Inside window defined and state not loaded");
      var googlemapscript = document.querySelector('#google-maps')
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places',
          document.querySelector('head'),
          'google-maps',
        );
      }
      this.state.loaded = true;
    }
    console.log(!autocompleteService.current);
    /*if(this.state.searchCriteria && this.state.searchCriteria.searchCriteria){
      for(let i=0; i<this.state.searchCriteria.searchCriteria.length; i++){
        if(this.state.searchCriteria.searchCriteria[i].includes("/")){
          var parsed = this.state.searchCriteria.searchCriteria[i].split("/");
          for(let j=0; j < parsed.length; j++){
            this.state.searchCriteriaParsed.push(parsed[j].trim());
          }
        }
        else{
          this.state.searchCriteriaParsed.push(this.state.searchCriteria.searchCriteria[i]);
        }
      }
    }
    console.log(this.state.searchCriteriaParsed)*/
    this.loadMoreItems();
    //adsense connectivity
    adSenseScript();

  }

  /*componentDidMount(){


  }
  componentWillUnmount(){

  }*/

  async addImagesToList(){
    var searchResultData = this.state.searchResultData;
    AWS.config.credentials = new AWS.Credentials
    ({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
    })
    this.setState({
      s3client: new AWS.S3({region: "us-east-2"})
    })
    var imageName;
    var requireAdd;
    searchResultData.forEach(async searchResultDataItem => {

      imageName = stringToDestination(searchResultDataItem.destinationName);
      try{
        requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                    Key: imageName+"_sm.jpg"}).promise();

      }
      catch(ex){
        console.log("inside no image found catch");
        requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                    Key: "default_sm.jpg"}).promise();

      }
      searchResultDataItem.img = "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body);
      this.setState({
        searchResultData: searchResultData,
      });
    }, searchResultData)
    console.log(searchResultData);


  }

  static getDerivedStateFromProps(nextProps, prevState){
    console.log("Inside derived state from props");
    console.log(nextProps);
    console.log(nextProps.searchResult.travelerid);
    if(nextProps.searchResult.travelerid){
      return {travelerid: nextProps.searchResult.travelerid,
              displayName: nextProps.searchResult.displayName};
    }
    else {
      return null;
    }
  }

  loadMoreItems(){
    /*if(this.state.isItemLoaded){
      console.log("return");
      return;
    }*/

    /*fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getdistinctdestinationscount',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripactivitynames": this.state.activityTypeNames,
                                   "destinationto": this.state.destinationTo,
                                   "duration":this.state.duration,
                                   "triptypeid": this.state.tripTypeId,
                                   "profileName": this.state.profileName,
                                   "fromdestinationid": 8,
                                   "pagetripid": this.state.data.length==0?"":this.state.data[this.state.data.length-1].tripid,
              })
          })
          .then(res => res.json())
          .then(data => {

            console.log(data);
            if(data){
              this.setState({
                searchResultCount: data[0]?data[0].count:0
              })
              console.log(this.state.searchResultCount)
            }
          })*/
    console.log("before fetch");
    console.log(this.state.data);
    console.log(this.state.data.length);
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getdistinctdestinations',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"tripactivitynames": this.state.activityTypeNames,
                                   "destinationto": this.state.destinationTo,
                                   "duration":this.state.duration,
                                   "triptypeid": this.state.tripTypeId,
                                   "profileName": this.state.profileName,
                                   "fromdestinationid": 8,
                                   "pagetripid": this.state.data.length==0?"":this.state.data[this.state.data.length-1].tripid,
              })
          })
          .then(res => res.json())
          .then(async data => {
            console.log(data);
            this.setState({
              loading: true,
              isItemLoaded: true,
            });
            this.setState({
              data: data
            })
            if(!data || data.length == 0){
              this.setState({
                noData: true,
                hasNext: false
              })
              console.log("No Search Result");
              if(!this.state.reloadForPagination){
                this.setState({
                  searchResultData: [],
                });
              }
              return;
            }
            if(data.length >= 150){
              this.setState({
                hasNext: true
              })
            }
            else{
              this.setState({
                hasNext: false
              })
            }

            var datamigrated = [];
            var dataitem = {};
            var newTripId = null;
            var newDestinationId = null;
            var newDestinationName = null;
            var newActivityTypes = [];
            var newActivityDestinationIds = [];
            var newDuration = null;
            var minDuration = null;
            var maxDuration = null;
            var startDate = null;


            //get unique trip experiences from search results
            var ijump=1;
            var j = 0;
            var requireAdd = null;
            if(data){
              /*AWS.config.credentials = new AWS.Credentials
              ({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
              })
              this.setState({
                s3client: new AWS.S3({region: "us-east-2"})
              })*/
              for (let i =0; i<data.length && j<data.length; i=ijump){
                for (j=i; j<data.length; j++){
                  console.log("value of i"+i);
                  if(i != j){
                    if(newTripId != data[j].tripid){
                      ijump = j;
                      console.log("inside if of finding new tripid"+ijump);
                      console.log(stringToDestination(newDestinationName)+".jpg");
                      /*var imageName = stringToDestination(newDestinationName);
                      try{
                        var requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                                    Key: imageName+".jpg"}).promise();
                      }
                      catch(ex){
                        console.log("inside no image found catch");
                        var requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                                    Key: "default.jpg"}).promise();
                      }*/
                      dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                "destinationName": newDestinationName,
                                "activities": newActivityTypes,
                                "duration": newDuration,
                                "startDate": startDate,
                                "activitydestinationids": newActivityDestinationIds,
                              //  "img": "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body)
                              };

                      datamigrated.push(dataitem);
                      break;
                    }
                    else{
                      console.log("inside else of trip id being equal"+j);
                      /*console.log("../img/"+stringToDestination(newDestinationName)+".jpg");
                      try{
                        requireAdd = require("../img/"+stringToDestination(newDestinationName)+".jpg")
                      }
                      catch(ex){
                        console.log("inside no image found catch");
                        requireAdd = require("../img/default.jpg");
                      }*/
                      var str2=null;
                      if(data[j].tripactivitytypename != "DRIVING" && data[j].tripactivitytypename != "Flight" &&
                        data[j].tripactivitytypename != "Hotel" && data[j].tripactivitytypename != "Train" &&
                        data[j].tripactivitytypename != "Restaurant"){
                          var tempStr = data[j].activitydestination.substring(0,data[j].activitydestination.indexOf(','));
                          tempStr = tempStr.split(" ").join("");
                          str2 = data[j].tripactivitytypename+"-"+tempStr;
                        }
                      else{
                        str2 = data[j].tripactivitytypename;
                      }
                      if(!newActivityTypes.includes(str2)){
                        newActivityTypes.push(str2)
                      }
                      if(!newActivityDestinationIds.includes(data[j].activitydestinationid)){
                        newActivityDestinationIds.push(data[j].activitydestinationid)
                      }

                      if(j==(data.length-1)){
                        console.log("last element");
                        dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                  "destinationName": newDestinationName,
                                  "activities": newActivityTypes,
                                  "duration": newDuration,
                                  "startDate": startDate,
                                  "activitydestinationids": newActivityDestinationIds,
                                  //"img": requireAdd
                                };

                        datamigrated.push(dataitem);
                      }
                    }//end else
                  }//end if i != j
                  else {
                    console.log("inside else of i = j"+i);
                    newTripId = data[i].tripid;
                    newDestinationId = data[i].todestinationid;
                    newDestinationName = data[i].todestinationname;
                    newActivityTypes = [];
                    newActivityDestinationIds = [];

                    var str3=null;
                    if(data[i].tripactivitytypename != "DRIVING" && data[i].tripactivitytypename != "Flight" &&
                      data[i].tripactivitytypename != "Hotel" && data[i].tripactivitytypename != "Train" &&
                      data[i].tripactivitytypename != "Restaurant"){
                        var tempStr = data[i].activitydestination.substring(0,data[i].activitydestination.indexOf(','));
                        tempStr = tempStr.split(" ").join("");
                        str3 = data[i].tripactivitytypename+"-"+tempStr;
                      }
                    else{
                       str3 = data[i].tripactivitytypename;
                    }
                    if(!newActivityTypes.includes(str3)){
                      newActivityTypes.push(str3);
                    }
                    if(!newActivityDestinationIds.includes(data[i].activityDestinationId)){
                      newActivityDestinationIds.push(data[i].activityDestinationId);
                    }
                    newDuration = data[i].duration;
                    startDate = data[i].startdate;
                    if(data.length == 1){
                      dataitem = {"tripId": newTripId, "destinationId": newDestinationId,
                                "destinationName": newDestinationName,
                                "activities": newActivityTypes,
                                "duration": newDuration,
                                "startDate": startDate,
                                "activitydestinationids": newActivityDestinationIds,
                              //  "img": "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body)
                              };
                      datamigrated.push(dataitem);
                    }//end if data length == 1
                  }//end else
                }//inner for loop j
              }//for loop i
            }//if data

            console.log(datamigrated);
            var datamigratedFinal = [];
            var indexesToDel = [];
            if(datamigrated.length == 1){
              datamigrated[0].minDuration = datamigrated[0].duration;
              datamigrated[0].maxDuration = datamigrated[0].duration;
            }
            //keep count of l for a k
            for(let k=0; k<datamigrated.length; k++){
              minDuration = datamigrated[k].duration;
              maxDuration = datamigrated[k].duration;
              var lcount = 0;
              for(let l=k+1; l< datamigrated.length; l++){
                if(compareActivities(datamigrated[k].activities, datamigrated[l].activities,
                  datamigrated[k].destinationId, datamigrated[l].destinationId, datamigrated[l].duration, datamigrated[k].duration)){
                  if(!indexesToDel.includes(l)){
                    console.log(" index to delete"+l);
                    lcount++;
                    if(datamigrated[l].duration > maxDuration)
                      maxDuration = datamigrated[l].duration;
                    if(datamigrated[l].duration < minDuration)
                      minDuration = datamigrated[l].duration;
                    indexesToDel.push(l);
                  }
                }
              }// for loop for l
              datamigrated[k].minDuration = minDuration;
              datamigrated[k].maxDuration = maxDuration;
              datamigrated[k].popularitycount = lcount;
            } //for loop for k
            console.log(indexesToDel);
            //sorting is required before splicing
            indexesToDel.sort((a,b)=>a-b);
            //splice
            for (var m=indexesToDel.length-1; m>=0; m--){
              console.log("splice");
              datamigrated.splice(indexesToDel[m], 1);
            }

            //sort by most popular
            console.log(datamigrated)
            //popular by count of records deleted for a particular record
            datamigrated.sort((a,b)=>b.popularitycount-a.popularitycount);

            console.log(datamigrated)
            if(!this.state.reloadForPagination) {
              this.setState({
                searchResultData: datamigrated,
                loading: false,
                isItemLoaded: true,
              });
            }
            else{
              console.log("Inside else");
              console.log(datamigrated)
              console.log(this.state.searchResultData);
              var temp1 = this.state.searchResultData;
              var temp2 = [];
              temp2.push.apply(temp2, this.state.searchResultData);
              var lastElem = temp2[temp2.length-1]
              temp2.splice(-1);

              temp2.push.apply(temp2, datamigrated);

              console.log(temp2);
              this.setState({
                searchResultData: temp2,
                loading: false,
                isItemLoaded: true,
              });
            }
            console.log(this.state.searchResultData);
            //TagManager.dataLayer(tagManagerArgs);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             event: 'pageview',
             pageName: 'popularTrips'
            });
            this.addImagesToList();
          })
          .catch(error => {console.log(error); return error});

          if(this.state.tripTypeId){
            fetch(process.env.REACT_APP_HOSTNAME+'/api/triptype/gettriptypebyid',
                    { method:'POST',
                      headers:{
                       "Content-Type": "application/json"
                      },
                      body:JSON.stringify({
                                           "triptypeid": this.state.tripTypeId,
                      })
                  })
                  .then(res => res.json())
                  .then(data => {

                    this.setState({
                      tripType: data
                    })
                  })
                  .catch(error => {console.log(error)});
          }//end if trip type




  }

  handleloginsuccess(travelerid){
      this.state.travelerid = travelerid
  }

  handlelisttextclick(destinationTo, tripTypeId, searchCriteria){
     console.log(`Option selected in search result:`, destinationTo);
     console.log(`Option selected in search result:`, tripTypeId);
     console.log(`Option selected in search result:`, searchCriteria);
     searchCriteria.tripTypeId = tripTypeId;
     searchCriteria.destinationTo = destinationTo;
     const { dispatch } = this.props;
     dispatch(push('/tripswithoutgrouping'));
     dispatch(getSearchResultRefined(searchCriteria));

  }

  handleClick(id, fromdestinationid, todestinationid, activities){
    console.log("Handle click");
    console.log(id);
    console.log(activities);
    console.log(fromdestinationid);
    console.log(todestinationid);
    const { dispatch } = this.props;
    dispatch(push('/createtrip'));
    dispatch(createTrip(id, fromdestinationid, todestinationid, activities));
  }

  handleCreateNewTrip(){
    console.log("New Trip");
    const { dispatch } = this.props;
    dispatch(push('/createtrip'));
    dispatch(createTrip());
  }

  handleSearchDetail(activityItem){
    console.log("Handle Search Detail");
    console.log(`Option selected in search result:`, activityItem);

    var searchCriteria = {};
    var tempTripActivityTypeName;
    var tempTripActivityName;
    searchCriteria.tripactivitytypenames = [];
    searchCriteria.tripactivitynames = [];
    for(let i = 0; i< activityItem.activities.length; i++){
      if(activityItem.activities[i].includes("-")){
        tempTripActivityTypeName = activityItem.activities[i].substring(0, activityItem.activities[i].indexOf("-"))

      }
      else {
        tempTripActivityTypeName = activityItem.activities[i]

      }

      console.log(tempTripActivityTypeName)

      if (!searchCriteria.tripactivitytypenames.includes(tempTripActivityTypeName)){
        searchCriteria.tripactivitytypenames.push(tempTripActivityTypeName)
      }
    }

    activityItem.activitydestinationids = activityItem.activitydestinationids.filter(function( element ) {
        return element !== undefined;
    });
    searchCriteria.activitydestinationids = activityItem.activitydestinationids;
    //searchCriteria.tripactivitytypenames = activityItem.activities;
    //searchCriteria.tripactivitynames=
    searchCriteria.destinationto = activityItem.destinationId;
    searchCriteria.duration = activityItem.duration;

    console.log(searchCriteria);

    const { dispatch } = this.props;
    dispatch(push('/tripswithoutgrouping'));
    dispatch(getSearchResultRefined(searchCriteria));
  }

  handleCloneTrip(activityItem){
    console.log("Handle clone trip "+activityItem.tripId);
    console.log("traveler id "+this.state.travelerid);
    console.log("destination id "+this.state.fromdestinationid);
    if(!this.state.travelerid){
      this.setState({open: true});
      return;
    }
    console.log(activityItem);

    //pass trip Id, fromdestination id, traveler id

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/clonetrip',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.state.travelerid,
                                   "tripid": activityItem.tripId,
                                   "fromdestinationid": this.state.fromdestinationid
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/createtrip'));
            dispatch(editTrip(data.tripid, data.tripname, this.state.travelerid, this.state.fromdestinationid,
              data.todestinationid, new Date(data.starttime), new Date(data.endtime), activityItem.destinationName));
          })
          .catch(error => {console.log(error); return error})

    /*const data = activityItem;
    console.log("form data"+data);
    console.log("travelerid "+this.state.travelerid);
    console.log("props traveler id "+this.props.searchResult.travelerid );
    var jsonActivities = [];
    for(let i=0; i< this.state.activities.length; i++){
      jsonActivities.push({
        "name": this.state.activities[i].name,
        "tripactivitytypeid": this.state.activities[i].tripactivitytypeid,
        "fromlocationid": this.state.activities[i].fromlocationid,
        "tolocationid": this.state.activities[i].tolocationid,
        "activitystartdate": this.state.activities[i].activityStartDate?moment(this.state.activities[i].activityStartDate).format("YYYY-MM-DDTHH:MM:SS"):"",
        "activityenddate": this.state.activities[i].activityEndDate?moment(this.state.activities[i].activityEndDate).format("YYYY-MM-DDTHH:MM:SS"):""
      })
    }
    console.log(jsonActivities);
    fetch('https://'+process.env.REACT_APP_HOSTNAME+':8080/api/trips/createtripandactivities',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"travelerid": this.props.searchResult.travelerid,
                                   "tripname": this.state.tripname,
                                   "startdate": this.state.startDate,
                                   "enddate": this.state.endDate,
                                   "fromdestinationid": this.state.fromdestinationid,
                                   "todestinationid": this.state.todestinationid,
                                   "activities": jsonActivities

              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            const { dispatch } = this.props;
            dispatch(push('/mytrips'));
          })
          .catch(error => {console.log(error); return error})*/

  }

  handleClose() {
    this.setState({open: false})
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }

  encodeS3ImgBodyToData(data){
    /*var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');*/
    let buf = Buffer.from(data);
    let base64 = buf.toString('base64');
    return base64
  }

  handleDestinationClick(){
    console.log("handle destination click");

    this.setState({
      destinationDialogOpen: true,
    })

  }
  handleChange(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue: event.target.value});
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options: results})})

    }

  handleCloseDestinationDialog(){
      console.log("inside handle close destinatino dialog");
      this.setState({
        destinationDialogOpen: false,
      })
  }
  handleDestinationSelected(event, selectedOption2){
    if(selectedOption2){
     console.log(`Option selected in automcomplete value:`, selectedOption2.description);
     console.log(`Option selected in automcomplete event:`, event.target);
     this.setState({"destinationTo": selectedOption2.description})
     fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              },
               body:JSON.stringify({name: selectedOption2.description

               })
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             this.setState({
               todestinationid: data,
               tripToDestinationName: selectedOption2.description,
               errorToDestinationId: false,
               toDestinationIdErrorText: "",
               destinationTo: selectedOption2.description,
               data:[],
               reloadForPagination: false,
             })
             this.loadMoreItems();
           })
       }//if selectedoption2
       else{
         console.log("Inside else");
         this.setState({
           todestinationid: null,
           tripToDestinationName: null,
           errorToDestinationId: false,
           toDestinationIdErrorText: "",
           destinationTo: "",
           data:[],
           reloadForPagination: false,
         }, () => {console.log(this.state.destinationTo); this.loadMoreItems();})

       }//end of else of selectedoption2
  }
  handleDurationClick(){
    console.log("handle duration click");
    this.setState({
      durationDialogOpen: true,
    })
  }
  handleChangeDuration(event){
    event.preventDefault();
    console.log(event);
    console.log(event.target.value);
    this.setState({
      duration: event.target.value,
      data:[],
      reloadForPagination: false,
    }, () => this.loadMoreItems())

   }
   handleCloseDurationDialog(){
       console.log("inside handle close duration dialog");
       this.setState({
         durationDialogOpen: false,
       })
   }
   handleTripTypeClick(){
     console.log("handle trip type click");
     console.log(this.state.tripTypeValue)
     //trip types
     fetch(process.env.REACT_APP_HOSTNAME+'/api/triptype/findAll',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              }
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             if(data && this.state.tripTypeValue){
               for(let i=0; i<data.length; i++){
                 if(data[i].id == this.state.tripTypeValue.id){
                   this.setState({
                     tripTypeValue: data[i]
                   })
                 }//end if
               }//end for loop
             }
             this.setState({tripTypes: data});
           })

     this.setState({
       tripTypeDialogOpen: true,
     })
   }
   handleTripTypeId(event){
     event.preventDefault();
     console.log(event);
     console.log(event.target.value);
     this.setState({
       tripTypeValue: event.target.value,
       tripTypeId: event.target.value.id,
       data:[],
       reloadForPagination: false,
     }, () => this.loadMoreItems())

    }
   handleCloseTripTypeDialog(){
     console.log("inside handle close trip type dialog");
     this.setState({
       tripTypeDialogOpen: false,
     })
   }
   handleActivityTypeNamesClick(){
     console.log("handle activity type names click");
     //activity types
     fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivitytypes/findAll',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              }
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             var data1 = []
             data.forEach((elem) => {
               console.log(elem);
               elem.name = camelizeWSpc(elem.name);
               data1.push(elem);
             })
             this.setState({tripactivitytypes: data1});
           })
     this.setState({
       activityTypeDialogOpen: true,
     })
   }
   handleCloseActivityTypeDialog(){
     console.log("handle activity type names close dialog");
     this.setState({
       activityTypeDialogOpen: false,
     })
   }
   handleChange1(event){
     event.preventDefault();
     console.log('event target value '+event.target.value.id);
    console.log('event target value '+event.target.value.name);
     const value = this.state.selectedOptions?this.state.selectedOptions:[] ;
     var tripActivityTypeNames1 = [];
     for (let i = 0; i < event.target.value.length; i += 1) {
          value.push(event.target.value[i]);
          tripActivityTypeNames1.push(event.target.value[i].name);
      }
     console.log('value list '+value);
     this.setState({
       selectedOption: value,
       activityTypeNames: tripActivityTypeNames1,
       data:[],
       reloadForPagination: false,
     }, () => this.loadMoreItems());


   }
   handleChipDelete(e, value){

     console.log(value)
     var fullList = this.state.selectedOption;

     var index = 0
     var tripActivityTypeNames1 = [];
     for (let i = 0; i < fullList.length; i += 1) {
          if (fullList[i].id == value.id){
            console.log("inside check")
            index = i
            break;
          }
      }
     console.log(index);
     fullList.splice(index, 1);
     console.log('value list '+fullList);

     fullList.forEach(elem => {
       tripActivityTypeNames1.push(elem.name);
     })
    this.setState({ selectedOption: fullList, activityTypeNames: tripActivityTypeNames1, reloadForPagination: false, },
       () => this.loadMoreItems());
   }
   handleTravelerProfileClick(){
     console.log("handle traveler profile click");
     fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/allprofilenames',
             { method:'POST',
               headers:{
                "Content-Type": "application/json"
              }
           })
           .then(res => res.json())
           .then(data => {
             console.log(data);
             this.setState({profileNames: data});
           })
     this.setState({
       travelerDialogOpen: true,
     })
   }
   handleProfileChange(event, newValue){
     console.log(event.target.value);
     console.log(newValue);
     this.setState({
       //profileName: event.target.value,
       passProfileName: newValue,
       profileName: newValue,
       data: [],
       reloadForPagination: false,
     }, () => this.loadMoreItems())
   }
   handleCloseTravelerDialog(){
     console.log("handle traveler close dialog");
     this.setState({
       travelerDialogOpen: false,
     })
   }
   handleScrollData(){
     console.log("Inside handle scroll data");
     if(this.state.data.length >= 150 && this.state.hasNext){
       console.log("inside load more items")
       this.setState({isItemLoaded: true, reloadForPagination: true});
       this.loadMoreItems();
     }
     else{
       console.log("do not load more");
       this.setState({isItemLoaded: false});
     }
   }



  render(){
    //var items = this.state.searchResultData;
    return (
           <div>
             <PageHeader topName="Sign In" dispatch={this.props.dispatch}
                    travelerid={this.state.travelerid} displayName={this.state.displayName}>
             </PageHeader>
             <PageSubMenu1 menuSelectedValue="0" activitytypenames={this.state.activityTypeNames} destinationto={this.state.destinationTo}
                                                duration={this.state.duration} tripTypeId={this.state.tripTypeId}/>
             <div className="searchcriteriamaterialgrid3">
               <Card variant="outlined" >
                   <CardContent className="cardcontent" style={{paddingBottom: "1px", paddingTop: "1px"}}>

                    </CardContent>
                    <CardActions>
                      <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={this.handleCreateNewTrip}>Create New Trip</Button>
                    </CardActions>
                </Card>
              </div>
              <div className="searchcriteriamaterialgrid2">
              <Card variant="outlined" >
                  <CardContent className="cardcontent">
                    <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)", fontSize: '10px'}} size="small"
                      onClick={this.handleDestinationClick}>Destination{this.state.destinationTo?": "+this.state.destinationTo:""}</Button>

                    <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)", fontSize: '10px'}} size="small"
                     onClick={this.handleDurationClick}>Duration{this.state.duration?": "+this.state.duration+" days":""} </Button>

                    <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)", fontSize: '10px'}} size="small"
                     onClick={this.handleTripTypeClick}>Trip Type{this.state.tripTypeValue?": "+this.state.tripTypeValue.name:""}</Button>

                    <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)", fontSize: '10px'}} size="small"
                     onClick={this.handleActivityTypeNamesClick}>Trip Activities{this.state.activityTypeNames?": "+this.state.activityTypeNames.join(", "):""}</Button>

                    <Button variant="outlined" style={{color: "rgba(0,150,166,1.0)", fontSize: '10px'}} size="small"
                     onClick={this.handleTravelerProfileClick}>Traveler Profile {this.state.profileName}</Button>

                   </CardContent>



               </Card>
              </div>
              <Dialog open={this.state.destinationDialogOpen} onClose={this.handleCloseDestinationDialog} aria-labelledby="form-dialog-title"
                      aria-describedby="test1" fullWidth={true}>


                <DialogTitle id="form-dialog-title1">Destination</DialogTitle>

                <DialogContent>
                <Autocomplete
                  id="google-map-demo"
                  getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                  filterOptions={(x) => x}
                  options={this.state.options}
                  autoComplete
                  includeInputInList
                  filterSelectedOptions
                  onChange={this.handleDestinationSelected}
                  renderInput={(params) => (
                    <TextField sx={{"& label": {paddingLeft: "14px"}}}
                      {...params}
                      label="Destination"
                      fullWidth
                      onChange={this.handleChange}
                      variant="standard"
                    />
                  )}
                  value={this.state.destinationTo}
                  renderOption={(props,option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || [];
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match: any) => [match.offset, match.offset + match.length]),
                    );
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item xs>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                              </span>
                            ))}

                            <Typography variant="body2" color="textSecondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                </DialogContent>
                <DialogActions>

                    <Button id="closeDestination" onClick={this.handleCloseDestinationDialog} color="primary">
                      Close
                    </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={this.state.durationDialogOpen} onClose={this.handleCloseDurationDialog} aria-labelledby="form-dialog-title"
                      aria-describedby="test1" fullWidth={true}>


                <DialogTitle id="form-dialog-title1">Duration</DialogTitle>

                <DialogContent>
                  <FormControl fullWidth>
                    <InputLabel id="durationlabel">Duration</InputLabel>
                    <Select
                     labelId="duration"
                     id="duration"
                     onChange={this.handleChangeDuration}
                     MenuProps={MenuProps}
                     value={this.state.duration}
                     variant="standard"
                     >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={7}>7</MenuItem>
                      <MenuItem value={8}>8</MenuItem>
                      <MenuItem value={9}>9</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={11}>11</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={13}>13</MenuItem>
                      <MenuItem value={14}>14</MenuItem>
                      <MenuItem value={15}>More ..</MenuItem>
                     </Select>
                   </FormControl>
                </DialogContent>
                <DialogActions>

                    <Button id="closeDuration" onClick={this.handleCloseDurationDialog} color="primary">
                      Close
                    </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={this.state.tripTypeDialogOpen} onClose={this.handleCloseTripTypeDialog} aria-labelledby="form-dialog-title"
                      aria-describedby="test1" fullWidth={true}>


                <DialogTitle id="form-dialog-title1">Trip Type</DialogTitle>

                <DialogContent>
                  <FormControl fullWidth>
                    <InputLabel id="tripTypeLabel">Trip Type</InputLabel>
                    <Select
                     labelId="tripTypeId"
                     id="tripTypeId"
                     onChange={this.handleTripTypeId}
                     MenuProps={MenuProps}
                     value={this.state.tripTypeValue}
                     variant="standard"
                     >
                     {this.state.tripTypes.map((name) => (
                       <MenuItem key={name.id} value={name} >
                         {name.name}
                       </MenuItem>
                     ))}
                     </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button id="closeTripType" onClick={this.handleCloseTripTypeDialog} color="primary">
                      Close
                    </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={this.state.activityTypeDialogOpen} onClose={this.handleCloseActivityTypeDialog} aria-labelledby="form-dialog-title"
                      aria-describedby="test1" fullWidth={true}>


                <DialogTitle id="form-dialog-title1">Trip Activities</DialogTitle>

                <DialogContent>
                  <FormControl fullWidth>
                    <InputLabel id="tripactivitytypes">Trip Activities</InputLabel>
                    <Select sx={{"& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {right: "0px"},
                                 "& .css-1636szt": {right: "0px"}}}
                       labelId="tripactivitytypes"
                       id="tripactivitytypes"
                       multiple
                       value={this.state.selectedOption? this.state.selectedOption: []}
                       onChange={this.handleChange1}
                       input={<Input id="multivaluechip"/>}
                       MenuProps={MenuProps}
                       renderValue={(selected) => (
                          <div>
                            {selected.map((value) => (
                              <Chip key={value.id} label={value.name}
                                    clickable
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) => event.stopPropagation()}
                                      />
                                    }
                                    onDelete={(e) => this.handleChipDelete(e, value)}
                                    />
                            ))}
                          </div>
                        )}
                    >
                    {this.state.tripactivitytypes.map((name) => (
                      <MenuItem key={name.id} value={name} >
                        {name.name}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button id="closeActivityType" onClick={this.handleCloseActivityTypeDialog} color="primary">
                      Close
                    </Button>
                </DialogActions>
              </Dialog>
              <Dialog open={this.state.travelerDialogOpen} onClose={this.handleCloseTravelerDialog} aria-labelledby="form-dialog-title"
                      aria-describedby="test1" fullWidth={true}>


                <DialogTitle id="form-dialog-title1">Traveler Profile</DialogTitle>

                <DialogContent>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={this.state.profileNames.map((name) => name.profilename)}
                  onChange={this.handleProfileChange}
                  renderInput={(params) =>
                    <TextField {...params} sx={{"& label": {paddingLeft: "14px"}}}
                    label="Traveler Profile"
                    variant="standard"/>}
                  value={this.state.profileName}
                />
                </DialogContent>
                <DialogActions>
                    <Button id="closeActivityType" onClick={this.handleCloseTravelerDialog} color="primary">
                      Close
                    </Button>
                </DialogActions>
              </Dialog>
              <div className="searchcriteriamaterialgridbef2">
                <InfiniteScroll
                  dataLength={this.state.searchResultData.length}
                  next={this.handleScrollData}
                  hasMore={this.state.hasNext}
                  scrollThreshold={1}
                  style={{ overflow: "unset" }}
                  >

                {(this.state.searchResultData)?this.state.searchResultData.map(activityItem =>

                     <Card key={activityItem.tripId} variant="outlined" style={{marginBottom:"10px", overflow:"auto"}}>
                      <Grid container className="contentdisplay" alignItems="center" xs={12}>
                       <Grid item xs={8}>
                        <CardActionArea onClick={() => this.handleSearchDetail(activityItem)}>
                         <CardContent>
                           <Typography variant="body1" component="h2">
                             {activityItem.destinationName}
                           </Typography>
                           {(activityItem.minDuration == activityItem.maxDuration)?
                             <Typography variant="body2" color="textSecondary">
                               {(activityItem.minDuration ==1)? "1 day" : activityItem.minDuration +" days"}
                             </Typography>:
                             <Typography variant="body2" color="textSecondary">
                               {activityItem.minDuration} - {activityItem.maxDuration} days
                             </Typography>
                            }
                           {activityItem.activities.map(activityName =>
                             (activityName.includes("-")) ?
                              <Chip key={activityName} size="small"
                                label={this.camelize(activityName.substring(0,activityName.indexOf("-")))+
                                        activityName.substring(activityName.indexOf("-"))}
                                icon={getIconForActivity(this.camelize(activityName.substring(0,activityName.indexOf("-"))))}/>
                                :
                                <Chip key={activityName} size="small"
                                  label={this.camelize(activityName)}
                                  icon={getIconForActivity(this.camelize(activityName))}/>
                           )}
                         </CardContent>
                        </CardActionArea>
                       <CardActions style={{paddingTop: "0px", paddingBottom: "1px"}}>
                         <Button id="popularRefined" style={{color: "rgba(0,150,166,1.0)"}} size="small"  onClick={() => this.handleSearchDetail(activityItem)}>
                           More..
                         </Button>
                         <Button id="popularClone" style={{color: "rgba(0,150,166,1.0)"}} size="small" onClick={() => this.handleCloneTrip(activityItem)} >
                           Clone Trip
                         </Button>

                       </CardActions>
                       </Grid>
                       <Grid item xs={4}>
                          <CardMedia src={activityItem.img} component="img" title="test1" style={{width:"128px", height:"128px"}}/>
                       </Grid>
                     </Grid>
                     </Card>

               ):"Sorry, No Results Found"}
               </InfiniteScroll>
              </div>

              <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                  Please sign in to progress
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.handleClose} color="primary">
                    Ok
                  </Button>
                </DialogActions>
              </Dialog>
              <div className="searchcriteriamaterialgrid2">
              {(this.state.noData)?<Typography variant="body1" component="h2" style={{color:"rgba(0,150,166,1.0)"}}>
                Sorry, No Data Found
              </Typography>:""}
              </div>
           </div>
         )

  }
}
const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);

  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
  return state;
}


const mapDispatchToProps = dispatch => {
  console.log("inside dispatch of search result");
  dispatch(getSearchResult());
}


export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(PopularTrips);
