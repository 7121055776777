import React, {Component} from "react";
import '../styles/button.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/SearchCriteria.css';
import {push} from 'react-router-redux';
import {getSearchResultTrip, getPopularTrips, passTravelerId} from '../action';
import {Field, reduxForm} from 'redux-form';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import PageHeader from './PageHeader';
import PageMenu from './PageMenu';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/material/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import ListSubheader from '@mui/material/ListSubheader';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {createTrip} from '../action';
import Divider from '@mui/material/Divider';
import TagManager from 'react-gtm-module';
import {connect} from 'react-redux';
import S3 from 'aws-sdk/clients/s3';
import AWS from 'aws-sdk';
import CancelIcon from '@mui/icons-material/Cancel';
import useMediaQuery from '@mui/material/useMediaQuery';
import SimpleMediaQuery from './SimpleMediaQuery';
//import sharp from 'sharp';


const tagManagerArgs = {
    dataLayer: {
        userProject: 'itifind.com',
        page: 'home',
        event: 'pageview'
    },
    dataLayerName: 'PageDataLayer'
}

function adSenseScript(){
  var externalScript   = document.createElement("script");
  externalScript.type  = "text/javascript";
  externalScript.setAttribute('async','async');
  externalScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6345293511201782";
  externalScript.crossorigin = "anonymous"
  document.getElementsByTagName('head')[0].appendChild(externalScript);
}


function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  //script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
  console.log("load script");
}

const autocompleteService = { current: null };

function stringToDestination(input){
  var firstPart = input.substr(0, input.indexOf(','));
  console.log(firstPart);
  if(!firstPart){
    input = input.split(' ').join('');
    return input.toLowerCase();
  }
  var secondPart = firstPart.toLowerCase();
  console.log(secondPart);
  var thirdPart = secondPart.split(' ').join('');
  console.log(thirdPart);
  thirdPart = thirdPart.normalize("NFD").replace(/\p{Diacritic}/gu, "")
  thirdPart = thirdPart.split('.').join("");
  return thirdPart;
}

function camelize(str) {
  if(str){
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    }).replace(/\s+/g, '');
  }
  else{
    return str;
  }
}

function camelizeWSpc(str){
  if(str){
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toUpperCase() : word.toLowerCase();
    });
  }
  else{
    return str;
  }
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300,
    },
  },
}

class TripTypeBasedSearch extends Component {

  constructor(props){
    super(props);
    console.log(props);
    //const [inputValue, setInputValue] = React.useState('');
    //const [options, setOptions] = React.useState([]);
    //const loaded = React.useRef(false);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleDestinationSelected = this.handleDestinationSelected.bind(this);
    this.handleCreateNewTrip = this.handleCreateNewTrip.bind(this);
    this.handleTileClick = this.handleTileClick.bind(this);
    this.handleChangeDuration = this.handleChangeDuration.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.encodeS3ImgBodyToData = this.encodeS3ImgBodyToData.bind(this);
    this.handleTripTypeId = this.handleTripTypeId.bind(this);
    this.handleProfileChange = this.handleProfileChange.bind(this);
    this.handleChipDelete = this.handleChipDelete.bind(this);
    this.state = {
      selectedOption: [],
      inputValue: '',
      options: [],
      loaded: false,
      popularDestImgs: [],
      tripactivitytypes: [],
      fromdestinationid: 8,
      duration: '',
      travelerid: props.searchResult.travelerid,
      displayName: props.searchResult.displayName,
      tripTypes: [],
      tripTypeId: null,
      profileNames: [],
      profileName: null,
    }

    /*this.props.searchResult.subscribe(() => {
      this.setState({
        travelerid: this.props.searchResult.getState().travelerid,
        displayName: this.props.searchResult.getState().displayName
      })
    })*/
    console.log(this.state.travelerid)
    console.log(this.state.displayName)
    console.log(typeof window);
    console.log(!this.state.loaded);
    if (typeof window !== 'undefined' && !this.state.loaded) {
      console.log("Inside window defined and state not loaded");
      var googlemapscript = document.querySelector('#google-maps')
      if (!document.querySelector('#google-maps')) {
        loadScript(
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAR46WNZLgdnL7Ob6YXbQOof3u8dbkhrdo&libraries=places',
          document.querySelector('head'),
          'google-maps',
        );
      }
      this.state.loaded = true;
    }
    console.log(!autocompleteService.current);

    const googleScript = document.getElementById('google-maps')
    googleScript.addEventListener('load', () => {
      console.log("google maps loaded");
      console.log(window.google);
      console.log("navigator"+navigator.geolocation)
      var fromdestinationid = 110;
      var geocoder = null;

      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(function(position){
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          console.log(" current location"+pos.lat +" "+pos.lng);
          console.log("window google"+window.google);

          if(window.google){
            console.log("inside window google");
            geocoder = new window.google.maps.Geocoder();
            if(geocoder != null) {
              geocoder.geocode({location: pos}, (results, status) => {
                if (status == "OK"){
                  console.log(results);
                  for(let i = 0; i < results.length; i++){
                    if(results[i].types[0] == "locality" && results[i].types[1] == "political"){
                      console.log(results[i].formatted_address)
                      fetch(process.env.REACT_APP_HOSTNAME+'/api/destination/findorcreate',
                              { method:'POST',
                                headers:{
                                 "Content-Type": "application/json"
                               },
                                body:JSON.stringify({name: results[i].formatted_address

                                })
                            })
                            .then(res => res.json())
                            .then(data => {
                              console.log(data);
                              fromdestinationid = data;

                              //fromdestinationname= results[i].formatted_address
                            })
                    }
                  }//end of for loop

                }//status ok
                else {
                  console.log("Cannot reverse Geo Code current location");
                }
              })
            }
          }
        }, function(e){
          console.log("Error with maps");
          console.log(e);
        });
      } else {
        console.log("location tracking not allowed");
      }

      console.log(fromdestinationid);
      this.setState({
        fromdestinationid: fromdestinationid,
      })

    })


    console.log("before call to apis");
    console.log(process.env.REACT_APP_HOSTNAME);
    //activity types
    fetch(process.env.REACT_APP_HOSTNAME+'/api/tripactivitytypes/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             }
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            var data1 = []
            data.forEach((elem) => {
              console.log(elem);
              elem.name = camelizeWSpc(elem.name);
              data1.push(elem);
            })
            this.setState({tripactivitytypes: data1});
          })
    //trip types
    fetch(process.env.REACT_APP_HOSTNAME+'/api/triptype/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             }
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            this.setState({tripTypes: data});
          })
    //traveler profile names
    fetch(process.env.REACT_APP_HOSTNAME+'/api/traveler/allprofilenames',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             }
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);
            this.setState({profileNames: data});
          })
   //adsense connectivity
   adSenseScript();

  }//end constructor

 componentDidMount(){
   //geocoder
   var geocoder = null;
   //var fromdestinationid = 8;
    console.log("inside component did mount")

    //get popular Destinations
    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/getpopulardestinations',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({
                "fromdestinationid": this.state.fromdestinationid?this.state.fromdestinationid:110
              })
          })
          .then(res => res.json())
          .then(async data => {
            console.log(data);
            var imgList = [];
            var imgPath = null;
            var requireAdd = null;
            var showActivityTypes = [];
            if(data){
              AWS.config.credentials = new AWS.Credentials
              ({
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY
              })
              this.setState({
                s3client: new AWS.S3({region: "us-east-2"})
              })


              for(let i = 0; i<data.length; i++){
                  showActivityTypes = [];
                  if(data[i].tripactivitytypes){
                    data[i].tripactivitytypes.forEach(elem =>{
                      showActivityTypes.push(elem.tripactivitytypename)
                    })
                  }
                  console.log(showActivityTypes);
                  var imageName = stringToDestination(data[i].todestinationname);

                  try{
                    var requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                                Key: imageName+"_sm.jpg"}).promise();
                  }
                  catch(ex){
                    var requireAdd = await this.state.s3client.getObject({Bucket: 'tripytopdests',
                                                Key: "default.jpg"}).promise();
                  }

                  imgList.push({img: "data:image/jpeg;base64," + this.encodeS3ImgBodyToData(requireAdd.Body),
                                title: data[i].todestinationname,
                                activityTypes: showActivityTypes.join(', ')});
                  this.setState({
                                  popularDestImgs: imgList
                                })

              }
            }
            this.setState({
              popularDestImgs: imgList
            })
          })

          //TagManager.dataLayer(tagManagerArgs);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
           event: 'pageview',
           pageName: 'home'
          });

  }

  encodeS3ImgBodyToData(data){
    /*var str = data.reduce(function(a,b){ return a+String.fromCharCode(b) },'');
    return btoa(str).replace(/.{76}(?=.)/g,'$&\n');*/
    let buf = Buffer.from(data);
    let base64 = buf.toString('base64');
    return base64
  }

  //submit search
  handleSubmit(event){
    console.log("Inside submit");

    event.preventDefault();
    const data = new FormData(event.target);
    console.log("trip activity types "+this.state.selectedOption);
    console.log("destination to "+this.state.destinationTo);
    console.log("duration"+this.state.duration);
    console.log("trip type "+this.state.tripTypeId);
    console.log(this.props.travelerid);
    console.log(this.props.displayName);
    var tripActivityTypeNames = [];
    this.state.selectedOption.forEach(elem => {
      tripActivityTypeNames.push(elem.name);
    })

    const { dispatch } = this.props;
    dispatch(push('/populartrips'));
    dispatch(getPopularTrips(this.props.searchResult.travelerid, this.props.searchResult.displayName,
      tripActivityTypeNames, this.state.destinationTo, this.state.duration, this.state.tripTypeId,
      this.state.passProfileName));

  }

  //auto complete handler
  handleChange(event){
    console.log("inside handle change");
    console.log(event.target.value);
    console.log(window.google);
    if (!autocompleteService.current && window.google) {
      console.log("autocomplete server current being populated");
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    console.log(autocompleteService.current);
    this.setState({inputValue: event.target.value});
    autocompleteService.current.getPlacePredictions({input: event.target.value}, (results) =>
                                                      {console.log("inside callback");
                                                       console.log(results);
                                                       this.setState({options: results})})

    }




//this is to handle activity type multi chip selection
 handleChange1(event){
   event.preventDefault();
   console.log('event target value '+event.target.value.id);
  console.log('event target value '+event.target.value.name);
   const value = this.state.selectedOptions?this.state.selectedOptions:[] ;
   for (let i = 0; i < event.target.value.length; i += 1) {
        value.push(event.target.value[i]);
    }
   console.log('value list '+value);
   this.setState({ selectedOption: value });
 }

 //once destination is selected from auto complete
 handleDestinationSelected(event, selectedOption2){
    console.log(`Option selected in automcomplete value:`, selectedOption2?selectedOption2.description:"");
    console.log(`Option selected in automcomplete event:`, event.target);
    this.setState({"destinationTo": selectedOption2?selectedOption2.description:""})

 }
 handleCreateNewTrip(){
   console.log("New Trip");
   const { dispatch } = this.props;
   dispatch(push('/createtrip'));
   dispatch(createTrip());
 }

 handleTileClick(tile){
   console.log("Handle Tile Click"+ tile.title);
   const { dispatch } = this.props;
   //dispatch(push('/tripsandactivities'));
   //dispatch(getSearchResultTrip([], tile.title));
   dispatch(push('/populartrips'));
   dispatch(getPopularTrips(this.props.searchResult.travelerid, this.props.searchResult.displayName,
     [], tile.title, this.state.duration, this.state.tripTypeId,
     this.state.passProfileName));
 }

 handleChangeDuration(event){
   event.preventDefault();
   console.log(event);
   console.log(event.target.value);
   this.setState({
     duration: event.target.value
   })
  }

  handleMenuClick(event){
    console.log("inside handle menu click");
    console.log(this.state.travelerid);
    console.log(this.state.displayName1);
    console.log(this.props.travelerid);
    console.log(this.props.displayName);
    const { dispatch } = this.props;
    console.log(dispatch);
    dispatch(push('/'));
    //dispatch(passTravelerId(this.state.travelerid, this.state.displayName1))

  }

  handleTripTypeId(event){
    event.preventDefault();
    console.log(event);
    console.log(event.target.value);
    this.setState({
      tripTypeValue: event.target.value,
      tripTypeId: event.target.value.id
    })
   }

   handleProfileChange(event, newValue){
     console.log(event.target.value);
     console.log(newValue);
     this.setState({
       //profileName: event.target.value,
       passProfileName: newValue,
     })
   }

   handleChipDelete(e, value){

     console.log(value)
     var fullList = this.state.selectedOption;

     var index = 0
     for (let i = 0; i < fullList.length; i += 1) {
          if (fullList[i].id == value.id){
            console.log("inside check")
            index = i
            break;
          }
      }
     console.log(index);
     fullList.splice(index, 1);
     console.log('value list '+fullList);
     this.setState({ selectedOption: fullList });

   }

  render(){



    return <div>
          <PageHeader topName="Sign In" dispatch={this.props.dispatch}
                travelerid={this.state.travelerid} displayName={this.state.displayName}>
           </PageHeader>
           <PageMenu dispatch={this.props.dispatch} menuSelectedValue="0"
                travelerid={this.state.travelerid}>
           </PageMenu>

          <form onSubmit={this.handleSubmit}>
            <div className="searchcriteriamaterialgrid3">
            <Typography variant="subtitle2" component="div">
                SEARCH FOR ITINERARY:
            </Typography>
              <Grid container spacing={2}>

                  <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="google-map-demo"
                      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                      filterOptions={(x) => x}
                      options={this.state.options}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      onChange={this.handleDestinationSelected}
                      renderInput={(params) => (
                        <TextField sx={{"& label": {paddingLeft: "14px"}}}
                          {...params}
                          label="Destination"
                          fullWidth
                          onChange={this.handleChange}
                          variant="standard"
                        />
                      )}
                      renderOption={(props,option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings || [];
                        const parts = parse(
                          option.structured_formatting.main_text,
                          matches.map((match: any) => [match.offset, match.offset + match.length]),
                        );
                        return (
                          <li {...props}>
                            <Grid container alignItems="center">
                              <Grid item xs>
                                {parts.map((part, index) => (
                                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                  </span>
                                ))}

                                <Typography variant="body2" color="textSecondary">
                                  {option.structured_formatting.secondary_text}
                                </Typography>
                              </Grid>
                            </Grid>
                          </li>
                        );
                      }}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="durationlabel">Duration</InputLabel>
                      <Select
                       labelId="duration"
                       id="duration"
                       onChange={this.handleChangeDuration}
                       MenuProps={MenuProps}
                       value={this.state.duration}
                       variant="standard"
                       >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                        <MenuItem value={13}>13</MenuItem>
                        <MenuItem value={14}>14</MenuItem>
                        <MenuItem value={15}>More ..</MenuItem>
                       </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="tripTypeLabel">Trip Type</InputLabel>
                      <Select
                       labelId="tripTypeId"
                       id="tripTypeId"
                       onChange={this.handleTripTypeId}
                       MenuProps={MenuProps}
                       value={this.state.tripTypeValue}
                       variant="standard"
                       >
                       {this.state.tripTypes.map((name) => (
                         <MenuItem key={name.id} value={name} >
                           {name.name}
                         </MenuItem>
                       ))}
                       </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel id="tripactivitytypes">Trip Activities</InputLabel>
                      <Select sx={{"& .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {right: "0px"},
                                   "& .css-1636szt": {right: "0px"}}}
                         labelId="tripactivitytypes"
                         id="tripactivitytypes"
                         multiple
                         value={this.state.selectedOption? this.state.selectedOption: []}
                         onChange={this.handleChange1}
                         input={<Input id="multivaluechip"/>}
                         MenuProps={MenuProps}
                         renderValue={(selected) => (
                            <div>
                              {selected.map((value) => (
                                <Chip key={value.id} label={value.name}
                                      clickable
                                      deleteIcon={
                                        <CancelIcon
                                          onMouseDown={(event) => event.stopPropagation()}
                                        />
                                      }
                                      onDelete={(e) => this.handleChipDelete(e, value)}
                                      />
                              ))}
                            </div>
                          )}
                      >
                      {this.state.tripactivitytypes.map((name) => (
                        <MenuItem key={name.id} value={name} >
                          {name.name}
                        </MenuItem>
                      ))}
                      </Select>
                    </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>

                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={this.state.profileNames.map((name) => name.profilename)}
                          onChange={this.handleProfileChange}
                          renderInput={(params) =>
                            <TextField {...params} sx={{"& label": {paddingLeft: "14px"}}}
                            label="Traveler Profile"
                            variant="standard"/>}
                        />
                      </FormControl>
                      </Grid>

                </Grid>
              </div>

              <Grid container justifyContent="center" style={{maxWidth:"1200px"}}>
                <button id="searchButton" className="button" >Search</button>
              </Grid>

          </form>


          <div className="searchcriteriamaterialgrid" style={{paddingBottom: "1px"}}>
            <Typography variant="subtitle2" component="div">
              POPULAR ITINERARIES:
            </Typography>
          </div>
          <div className="searchcriteriamaterialgrid">
            <SimpleMediaQuery inp={this.state.popularDestImgs} parent={this}/>
          </div>
        </div>


  }
}

TripTypeBasedSearch = reduxForm({
  form: 'SearchCriteriatriptype',
  fields: ["name"]
})(TripTypeBasedSearch);

const mapStateToProps = state => {
  console.log(state);
  console.log(state.searchResult.travelerid);

  //this.props.travelerid = state.travelerid;
  //return {travelerid: state.searchResult.travelerid};
  return state;
}
export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(TripTypeBasedSearch);
