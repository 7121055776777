import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter, Route, Link} from 'react-router-dom';
import {createStore, compose, applyMiddleware} from 'redux';
import tripsApp from './reducers';
import SearchResult from './components/SearchResult';
import SignIn from './components/SignIn';
import {Provider} from 'react-redux';
import PropTypes from 'prop-types';
import {getAllSearchCriteria} from './action';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {ConnectedRouter, routerMiddleware} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import MyTrips from './components/MyTrips';
import SearchResultRefined from './components/SearchResultRefined';
import TripTypeBasedSearch from './components/TripTypeBasedSearch';
import SearchResultTrip from './components/SearchResultTrip';
import CreateTrip from './components/CreateTrip';
import UploadPics from './components/UploadPics';
import Memories from './components/Memories';
import Icons from './components/Icons';
import PlaneIcon from './components/PlaneIcon';
import CarIcon from './components/CarIcon';
import BikeIcon from './components/BikeIcon';
import HotelIcon from './components/HotelIcon';
import MuseumIcon from './components/MuseumIcon';
import PoolIcon from './components/PoolIcon';
import RestaurantIcon from './components/RestaurantIcon';
import SubwayIcon from './components/SubwayIcon';
import WalkIcon from './components/WalkIcon';
import Reviews from './components/Reviews';
import PopularTrips from './components/PopularTrips';
import TagManager from 'react-gtm-module';
import CompareTrips from './components/CompareTrips';
import Profile from './components/Profile';
import ReactGA from 'react-ga4';



export const history = createBrowserHistory();
const loggerMiddleware = createLogger()

const store = createStore(
              tripsApp(history),
              compose(
                  applyMiddleware(
                    routerMiddleware(history),
                    thunkMiddleware,
                    loggerMiddleware
                  )
              ));
const tagManagerArgs = {
                gtmId: 'GTM-P267C3G'

              }
TagManager.initialize(tagManagerArgs);
//ReactGA.initialize("G-2BKBPF6SFV");
//ReactGA.initialize('UA-258578004-1');
//ReactGA.pageview(window.location.pathname + window.location.search);

var externalScript   = document.createElement("script");
externalScript.type  = "text/javascript";
externalScript.setAttribute('async','async');
externalScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6345293511201782";
externalScript.crossorigin = "anonymous"
document.getElementsByTagName('body')[0].appendChild(externalScript);

/*history = syncHistoryWithStore(
  browserHistory,
  store
);*/

//store.dispatch(getAllSearchCriteria);

/*ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById('root')
);*/

/*ReactDOM.render(<App />, document.getElementById('root'));*/
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history = {history}>
      <Route exact path="/" component={TripTypeBasedSearch}/>
      <Route path="/search" component={SearchResult}/>
      <Route path="/signin" component={SignIn}/>
      <Route path="/mytrips" component={MyTrips}/>
      <Route path="/tripswithoutgrouping" component={SearchResultRefined} />
      <Route path="/home2" component={App} />
      <Route path="/tripsandactivities" component={SearchResultTrip} />
      <Route path="/createtrip" component={CreateTrip} />
      <Route path="/photoupload" component={UploadPics} />
      <Route path="/memories" component={Memories} />
      <Route path="/icons" component={Icons} />
      <Route path="/PlaneIcon" component={PlaneIcon} />
      <Route path="/CarIcon" component={CarIcon} />
      <Route path="/BikeIcon" component={BikeIcon} />
      <Route path="/HotelIcon" component={HotelIcon} />
      <Route path="/MuseumIcon" component={MuseumIcon} />
      <Route path="/PoolIcon" component={PoolIcon} />
      <Route path="/RestaurantIcon" component={RestaurantIcon} />
      <Route path="/SubwayIcon" component={SubwayIcon} />
      <Route path="/WalkIcon" component={WalkIcon} />
      <Route path="/reviews" component={Reviews} />
      <Route path="/populartrips"  component={PopularTrips} />
      <Route path="/comparetrips" component={CompareTrips} />
      <Route path="/getprofile" component={Profile} />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
