import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PlaneIcon from '../icons/baseline_airplanemode_active_black_18dp.png';
import CarIcon from '../icons/baseline_directions_car_black_18dp.png';
import BikeIcon from '../icons/baseline_directions_bike_black_18dp.png';
import SubwayIcon from '../icons/baseline_directions_subway_black_18dp.png';
import WalkIcon from '../icons/baseline_directions_walk_black_18dp.png';
import HotelIcon from '../icons/baseline_local_hotel_black_18dp.png';
import MuseumIcon from '../icons/baseline_museum_black_18dp.png';
import PoolIcon from '../icons/baseline_pool_black_18dp.png';
import RestaurantIcon from '../icons/baseline_restaurant_black_18dp.png';


class Icons extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <Link to="PlaneIcon"><img src={PlaneIcon} /></Link>
              <Link to="CarIcon"><img src={CarIcon} /></Link>
              <Link to="BikeIcon"><img src={BikeIcon} /></Link>
              <Link to="SubwayIcon"><img src={SubwayIcon} /></Link>
              <Link to="WalkIcon"><img src={WalkIcon} /></Link>
              <Link to="HotelIcon"><img src={HotelIcon} /></Link>
              <Link to="MuseumIcon"><img src={MuseumIcon} /></Link>
              <Link to="PoolIcon"><img src={PoolIcon} /></Link>
              <Link to="RestaurantIcon"><img src={RestaurantIcon} /></Link>
          </div>
  }
}

export default connect()(Icons);
