import React, { Component } from "react";
import {connect} from 'react-redux';
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    DirectionsRenderer,
    Marker
} from "react-google-maps";


class Map extends Component {
  constructor(props){
    super(props);
    this.state={
      directions: null,
      origin: props.origin,
      destination: props.destination,
      waypoints: props.waypoints,
      travelMode: props.travelMode?props.travelMode: window.google.maps.TravelMode.DRIVING
    }
  }
  componentDidMount(){
    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route({
      origin: this.state.origin,
      destination: this.state.destination,
      travelMode: this.state.travelMode,
      waypoints: this.state.waypoints
    },
    (result, status)=> {
      if(status === window.google.maps.DirectionsService.OK){
        console.log(result)
        this.setState({
          directions: result
        })
      }
      else{
        console.error("error fetching directions");
      }
    });
  }


  render() {
    return withGoogleMap((props) => {
          console.log(props);
          return <GoogleMap
            defaultZoom={props.zoom}
            defaultCenter={props.center}
            center={props.center}
          >
            <DirectionsRenderer directions={this.state.directions} />
            {Object.entries(props.mytripactivitylist).map(([key, value]) => {
              return value.map (dest => {
                console.log("inside the trip activity list"+dest.id);
                return (<Marker key={dest.id}
                  position={{
                    lat: parseFloat(dest.tolat),
                    lng: parseFloat(dest.tolng)
                  }}

                  >
                </Marker>)
              })
            })
            }
          </GoogleMap>
          }

    )
  }
}

const mapStateToProps = state => {
  console.log(state);
  return state;
}

export default connect(mapStateToProps)(Map);
