import React, {Component} from "react";
import Select from "react-select";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

/*const test1 = [
  {value:1, label: 'test1'},
  {value:2, label: 'test2'}

]*/

export default class SelectTripType extends Component {
  constructor(props){
    super(props);
    this.state = {
      selectedOption: [],
      clearable: true,
      searchable: true,
      tripTypes: []
    }
    fetch(process.env.REACT_APP_HOSTNAME+'/api/triptypes/findAll',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
              }
          })
    .then(res => res.json())
    .then(data => {

      this.setState({
        tripTypes: data
      })
      console.log(this.state.tripTypes)
    })

  }


 handleChange = selectedOption => {
   this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption.value);
    this.props.onChange(selectedOption);
 }

handleChangeTrpTyp = selectedOption => {
  this.setState({ selectedOption: selectedOption.target.value });
  console.log(`Option selected:`, selectedOption.target.value);
  this.props.onChange(selectedOption.target);
}

  render(){
    let options1 = this.state.tripTypes.map(function (city) {
      return { value: city.id, label: city.name };
   })
   {/*<Select className="selectbox"
       name="tripTypeSelect"
       value={this.state.selectedOption}
       onChange={this.handleChange}
       clearable={this.state.clearable}
       searchable={this.state.searchable}
       options={options1}
     />
     */}
    return (

              <TextField
                id="selectbox1"
                select
                label="Trip Type"
                value={this.state.selectedOption? this.state.selectedOption: ""}
                onChange={this.handleChangeTrpTyp}
                >
                {this.state.tripTypes.map(option2 => (
                  <MenuItem key={option2.id} value={option2.id}>
                    {option2.name}
                    </MenuItem>
                  ))}

              </TextField>
          );


  }
}
