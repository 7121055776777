import React, {Component} from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListSubheader from '@mui/material/ListSubheader';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {searchResult} from '../reducers';
import {connect} from 'react-redux';
import {getSearchResult} from '../action';
import PageSubMenu1 from './PageSubMenu1';
import PageHeaderData from './PageHeaderData';
import moment from 'moment';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import {push} from 'react-router-redux';
import {getSearchResultRefined} from '../action';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import '../styles/SearchCriteria.css';
import CardContent from '@mui/material/CardContent';


//this is deprecated
class SearchResult extends Component {
  constructor(props){
    super(props);
    this.handlelisttextclick = this.handlelisttextclick.bind(this);
    this.handleCreateNewTrip = this.handleCreateNewTrip.bind(this);
    this.handleCloneTrip = this.handleCloneTrip.bind(this);
    this.state = {
      searchResultData: [],
      loading: true,
      searchCriteria: props.searchResult,
      appendedCriteria: moment(props.searchResult.startDate).format('YYYY-MM-DD') + "  " +
                        moment(props.searchResult.endDate).format('YYYY-MM-DD') + "  " +
                        props.searchResult.tripTypeId + "  " +
                        props.searchResult.travelerCount,
      travelerid: props.searchResult.travelerid
    };
    console.log(props.searchResult.startDate);

    fetch(process.env.REACT_APP_HOSTNAME+'/api/trips/search',
            { method:'POST',
              headers:{
               "Content-Type": "application/json"
             },
              body:JSON.stringify({"name": props.searchResult.name,
                                  "startDate": props.searchResult.startDate,
                                  "endDate":props.searchResult.endDate,
                                  "travelerCount":props.searchResult.travelerCount,
                                  "spend": props.searchResult.budget,
                                  "tripTypeId": props.searchResult.tripTypeId,
                                  "destinationFrom": props.searchResult.destinationFrom,
                                  "destinationTo": props.searchResult.destinationTo
              })
          })
          .then(res => res.json())
          .then(data => {
            console.log(data);


            this.setState({
              searchResultData: data,
              loading: false
            });
            console.log(this.state.searchResultData);
          })
          .catch(error => {console.log(error); return error});


  }

  handlelisttextclick(destinationTo, tripTypeId, searchCriteria){
     console.log(`Option selected in search result:`, destinationTo);
     console.log(`Option selected in search result:`, tripTypeId);
     console.log(`Option selected in search result:`, searchCriteria);
     searchCriteria.tripTypeId = tripTypeId;
     searchCriteria.destinationTo = destinationTo;
     const { dispatch } = this.props;
     dispatch(push('/tripswithoutgrouping'));
     dispatch(getSearchResultRefined(searchCriteria));

  }

  handleCreateNewTrip(travelerId){
    console.log(travelerId);
  }

  handleCloneTrip(){
    console.log("handle clone trip");
  }

  render(){
    var items = this.state.searchResultData;
    return (
           <div>
             <PageHeaderData appendedCriteria={this.state.appendedCriteria} dispatch={this.props.dispatch}
                      travelerid={this.props.travelerid} displayName={this.props.displayName}>
             </PageHeaderData>
             <PageSubMenu1 menuSelectedValue="0"/>
             <Card  className="singlebuttoncard">
               <CardContent className="cardcontent">
                 <Typography color="textSecondary">
                   Create Itinerary Manually or Search,Clone & Add your Special Touch
                 </Typography>
                </CardContent>
                <CardActions>
                  <Button variant="outlined" size="small" onClick={this.handleCreateNewTrip}>Create New Trip</Button>
                </CardActions>
             </Card>
             <div className="searchcriteriamaterialgrid">
               <List>
               {
                  this.state.searchResultData.map(item =>

                    <React.Fragment key={item.id}>
                      <ListItem button onClick={() => this.handlelisttextclick(item.destinationTo,
                                                item.tripTypeId,
                                                this.state.searchCriteria)}
                                                align-text="flex-start" >
                          <ListItemText
                            primary={item.todestinationname + " - "+ item.triptypename}
                            secondary={item.startDate + " - "+item.endDate}>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <Button variant="outlined" size="small" onClick={this.handleCloneTrip}>Clone this Trip</Button>
                          </ListItemSecondaryAction>
                      </ListItem>
                      <Divider component="li" />
                    </React.Fragment>

                )}
                </List>
              </div>
           </div>
         )

  }
}
const mapStateToProps = state => {
  console.log(state);

  return state;

}


const mapDispatchToProps = dispatch => {
  console.log("inside dispatch of search result");
  dispatch(getSearchResult());
}


export default connect(
  mapStateToProps,
  //mapDispatchToProps
)(SearchResult);
