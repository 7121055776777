import React, { Component } from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import SubwayIconImg from '../icons/baseline_directions_subway_black_18dp.png';



class SubwayIcon extends Component {
  constructor(props){
    super(props);

  }



  render() {
    return <div>
              <img src={SubwayIconImg} />
          </div>
  }
}

export default connect()(SubwayIcon);
